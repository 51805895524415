import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material/styles';

import Slider, { SliderThumb } from '@mui/material/Slider';
import './SharePriceSlider.scss';

let finalized;

function ThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other} className={`slider-thumb ${finalized ? 'finalized' : ''}`}>
      <div className={`strike-through-top ${finalized ? 'finalized' : ''}`} />
      {children}
      <div className={`grip-container ${finalized ? 'finalized' : ''}`}>
        <span className={`airbnb-bar ${finalized ? 'finalized' : ''}`} />
        <span className={`airbnb-bar ${finalized ? 'finalized' : ''}`} />
        <span className={`airbnb-bar ${finalized ? 'finalized' : ''}`} />
      </div>
      <div className={`strike-through-btm ${finalized ? 'finalized' : ''}`} />
    </SliderThumb>
  );
}

ThumbComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default function SharePriceSlider({
  sliderMarks,
  setSelectedPrice,
  selectedPrice,
  auditRiskLabel,
  currentOptimizeSelection,
  disablePriceSlider,
  showDataLoadingOverlay,
  perSharePrices,
  sandboxIsFinalized,
  stats,
  singlePrice,
}) {
  finalized = sandboxIsFinalized;

  useEffect(() => {
    if (currentOptimizeSelection === 'Company Optimize') {
      setSelectedPrice(perSharePrices ? perSharePrices.indexOf(stats.companyOptimizedPrice) : 50);
    } else if (currentOptimizeSelection === 'Employee Optimize') {
      setSelectedPrice(perSharePrices ? perSharePrices.indexOf(stats.employeeOptimizedPrice) : 50);
    }
  }, [currentOptimizeSelection]);

  const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          valueLabel: {
            backgroundColor: sandboxIsFinalized ? '#CCCCCC' : /low/ig.test(auditRiskLabel) ? '#42A179' : '#F4C543',
            color: sandboxIsFinalized ? '#FFFFFF' : /low/ig.test(auditRiskLabel) ? '#FFFFFF' : '#49454F',
          },
          track: {
            backgroundColor: sandboxIsFinalized ? '#CCCCCC' : '#303D5C',
            borderRadius: '0px',
            transition: 'width 0.1s ease',
            transitionTimingFunction: 'cubic-bezier(0.1, 0.7, 1.0, 0.1)',
            height: '10px',
            borderRight: 'transparent',
            borderLeft: 'transparent',
          },
        },
      },
    },
  });

  return (
    <>
      {!singlePrice && (
        <>
          <div className="min-price" />
          <div className="max-price" />
        </>
      )}
      <ThemeProvider theme={theme}>
        <Slider
          track={!singlePrice ? 'normal' : false}
          aria-label="Custom marks"
          disabled={disablePriceSlider}
          components={{ Thumb: ThumbComponent }}
          aria-labelledby="slider"
          value={selectedPrice || 0}
          onChange={(_, value) => setSelectedPrice(value)}
          valueLabelFormat={!showDataLoadingOverlay && perSharePrices ? `$${perSharePrices[selectedPrice]}` : ''}
          valueLabelDisplay="on"
          defaultValue={!showDataLoadingOverlay && perSharePrices ? parseFloat(perSharePrices[selectedPrice]) || 0 : 0}
          onDragEnd={(_, value) => setSelectedPrice(value)}
          marks={singlePrice ? false : sliderMarks}
          min={sliderMarks[0].value}
          max={sliderMarks[sliderMarks.length - 1].value}
        />
      </ThemeProvider>
    </>
  );
}

SharePriceSlider.propTypes = {
  sliderMarks: PropTypes.array,
  setSelectedPrice: PropTypes.func,
  selectedPrice: PropTypes.number,
  auditRiskLabel: PropTypes.string,
  currentOptimizeSelection: PropTypes.string,
  disablePriceSlider: PropTypes.bool,
  showDataLoadingOverlay: PropTypes.bool,
  sandboxIsFinalized: PropTypes.bool,
  perSharePrices: PropTypes.array,
  stats: PropTypes.object,
  singlePrice: PropTypes.bool,
};
