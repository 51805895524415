import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import moment from 'moment';

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import StatusChip from './StatusChip';
import RotatingChevron from './RotatingChevron';

import { UserDataContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';
import useS3 from '../../hooks/useS3';

import { copy } from '../../utils';

import './TransactionStatusBlock.scss';

export default function TransactionStatusBlock({
  companyToViewData,
  setCompanyToViewData,
  setTabToView,
  currentAdminName,
  updatePriority,
  setViewAccountInfo,
  setDropdownBlockIsOpen,
  updateAdminProgress,
}) {
  const { userData } = useContext(UserDataContext);

  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [fileDropdownHeight, setFileDropdownHeight] = useState(0);

  const [fileList, setFileList] = useState([]);

  const [noteList, setNoteList] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [editNoteContent, setEditNoteContent] = useState('');

  const [noteMenuAnchorEl, setNoteMenuAnchorEl] = useState(null);
  const [noteMenuIsOpen, setNoteMenuIsOpen] = useState(false);
  const [editNoteIndex, setEditNoteIndex] = useState(-1);

  const [priorityDropdownIsOpen, setPriorityDropdownIsOpen] = useState(false);
  const [priority, setPriority] =
    useState(companyToViewData.transactionData?.adminProgress === 'completed' ? 2 : companyToViewData.companyData.priority || 2);

  const [insideElementIsBeingHovered, setInsideElementIsBeingHovered] = useState(false);
  const [noteIsFocussed, setNoteIsFocussed] = useState(false);

  const [showUnarchiveButton, setShowUnarchiveButton] = useState(false);

  const [, writeNoteRequest] = useFetch();
  const [, deleteNoteRequest] = useFetch();

  const [{ loading: fileListIsLoading }, transactionDataRequest] = useFetch();
  const [, s3FileRequest] = useS3();

  function postNote(isEdit, noteIndex) {
    const noteToSend = {
      companyId: companyToViewData.companyData.companyId,
      note: isEdit ? editNoteContent : noteContent,
      insertUserId: userData.userAttributes.userId,
      noteAuthor: currentAdminName,
      createdDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    writeNoteRequest({
      url: '/transactions/write-note',
      method: 'post',
      body: noteToSend,
      onSuccess: (noteResponse) => {
        const notesCopy = copy(noteList);
        if (isEdit) {
          notesCopy.splice(noteIndex, 1, noteResponse);
          setNoteList(copy(notesCopy));
        } else {
          notesCopy.unshift(noteResponse);
          setNoteList(notesCopy);
        }
      },
    });
  }

  function handleNoteMenuClose() {
    setNoteMenuIsOpen(false);
    setNoteMenuAnchorEl(null);
  }

  function handleNoteMenuOpen(e, noteIndex) {
    setNoteMenuAnchorEl(e.currentTarget);
    setNoteMenuIsOpen(true);
    setEditNoteIndex(noteIndex);
  }

  function deleteNote(noteIndex, andEdit) {
    const deleteNoteData = {
      companyId: noteList[noteIndex].companyId,
      transactionId: noteList[noteIndex].transactionId,
      userId: noteList[noteIndex].insertUserId,
      noteId: noteList[noteIndex].resource,
      createdDatetime: noteList[noteIndex].createdDatetime,
      noteAuthor: noteList[noteIndex].currentAdminName,
    };
    const newNoteList = copy(noteList);
    newNoteList[noteIndex].isBeingEdited = andEdit;
    newNoteList[noteIndex].editNote = false;
    setNoteList(newNoteList);
    deleteNoteRequest({
      url: '/transactions/delete-note',
      urlIds: [deleteNoteData.companyId, deleteNoteData.noteId.split('_')[deleteNoteData.noteId.split('_').length - 1]],
      method: 'post',
      onSuccess: () => {
        if (andEdit) {
          postNote(true, noteIndex);
        } else {
          noteList.splice(noteIndex, 1);
          setNoteList(copy(noteList));
        }
      },
    });
  }

  function switchNoteToEdit(reset) {
    const newNoteArr = [];
    noteList.forEach((note) => {
      newNoteArr.push({
        ...note,
        editNote: false,
      });
    });
    if (!reset) newNoteArr[editNoteIndex].editNote = true;
    setNoteList(newNoteArr);
  }

  function fetchFileList() {
    if (fileList.length === 0) {
      transactionDataRequest({
        url: '/transactions/details',
        urlIds: [companyToViewData.companyData.companyId, companyToViewData.transactionData.transactionId],
        onSuccess: (response) => {
          const DBData = response.Body[0];
          const capTableFiles = (DBData.documents.cap_table_files || []).map((el) => ({ ...el, fileType: 'Cap Table' }));
          const optionLedgerFiles =
            (DBData.documents.option_ledger_files || []).map((el) => ({ ...el, fileType: 'Option Ledger' }));
          const financialFiles =
            (DBData.documents.financial_files || []).map((el) => ({ ...el, fileType: 'Option Ledger' }));
          const articlesOfIncorporation =
            (DBData.documents.articles_of_incorporation || []).map((el) => ({ ...el, fileType: 'Articles of Incorporation' }));
          const secondaryTransactionDocs = (DBData.documents.secondary_transaction || []).map((el) => ({ ...el, fileType: 'Secondary Transaction' }));
          const termsSheets = (DBData.documents.term_sheets || []).map((el) => ({ ...el, fileType: 'Term Sheets' }));
          const safeOrConvertibleNoteAgreement =
            (DBData.documents.safe_or_convertible_note_agreement || []).map((el) => ({ ...el, fileType: 'Safe or Convertible Agreement' }));
          const newFileListArr =
            fileList.concat(capTableFiles, optionLedgerFiles, financialFiles, articlesOfIncorporation, secondaryTransactionDocs, termsSheets, safeOrConvertibleNoteAgreement);
          setFileList(newFileListArr);
        },
      });
    }
  }

  function priorityIconToShow() {
    if (priority === 1) return <NotificationImportantOutlinedIcon className="notif-icon" />;
    if (priority === 3) return <ArrowDownwardIcon />;
    return null;
  }

  useEffect(() => {
    const newNoteArr = [];
    companyToViewData.notes.forEach((note) => {
      newNoteArr.push({
        ...note,
        editNote: false,
      });
    });
    setNoteList(newNoteArr);
  }, []);

  useEffect(() => setDropdownBlockIsOpen(dropdownHeight), [dropdownHeight]);

  const showStatusChip = () => (
    (!companyToViewData.transactionData && companyToViewData.paymentData) ||
    (companyToViewData.transactionData && companyToViewData.transactionData.progress !== 'completed') ||
    companyToViewData.transactionData?.isCalculating ||
    companyToViewData.transactionData?.adminProgress === 'client not started 409a' ||
    companyToViewData.transactionData?.adminProgress === 'client 409a in progress' ||
    companyToViewData.transactionData?.adminProgress === 'work in progress' ||
    companyToViewData.transactionData?.adminProgress === 'calculation failed' ||
    companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
    companyToViewData.transactionData?.adminProgress === 'review sandbox' ||
    companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
    companyToViewData.transactionData?.adminProgress === 'completed'
  );

  return (
    <div className="TransactionStatusBlock">
      <div
        role="button"
        className={'transaction-block-btn ' +
          `${!insideElementIsBeingHovered && !noteIsFocussed && !priorityDropdownIsOpen && !noteMenuIsOpen ? 'can-hover' : ''}`}
        onClick={() => {
          if (!insideElementIsBeingHovered && !noteIsFocussed && !priorityDropdownIsOpen && !noteMenuIsOpen) {
            setCompanyToViewData(companyToViewData);
            if (companyToViewData.transactionData?.adminProgress === 'review report') setTabToView(5);
            else if (companyToViewData.transactionData?.adminProgress === 'review sandbox') setTabToView(4);
            else setTabToView(1);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && (!insideElementIsBeingHovered && !noteIsFocussed && !priorityDropdownIsOpen && !noteMenuIsOpen)) {
            setCompanyToViewData(companyToViewData);
            if (companyToViewData.transactionData?.adminProgress === 'review report') setTabToView(5);
            else if (companyToViewData.transactionData?.adminProgress === 'review sandbox') setTabToView(4);
            else setTabToView(1);
          }
        }}
        onMouseEnter={() => { if (companyToViewData.transactionData?.adminProgress === 'archived') setShowUnarchiveButton(true); }}
        onMouseLeave={() => { if (companyToViewData.transactionData?.adminProgress === 'archived') setShowUnarchiveButton(false); }}
        tabIndex={0}
      >
        <div className="transaction-block-header">
          <div className="transaction-block-info-wrapper">
            <span className="accounting-service-name">
              {companyToViewData.transactionData?.type || '409A'}
            </span>
            <div className="transaction-name-and-info-wrapper">
              <span className="top-transaction-name">
                {companyToViewData.accountData?.companyName}
              </span>
              <span className="bottom-transaction-info">
                {companyToViewData.transactionData?.chosenFunding ? companyToViewData.transactionData?.chosenFunding :
                  !companyToViewData.transactionData && companyToViewData.paymentData ?
                    `Paid ${moment.utc(companyToViewData.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD')}` :
                    companyToViewData.transactionData && companyToViewData.transactionData.progress !== 'completed' ?
                      `Started ${companyToViewData.transactionData.transactionType} ` +
                      `${moment(companyToViewData.transactionData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD')}` :
                      `Signed up ${moment(companyToViewData.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD')}`}
              </span>
            </div>
            <div className="priority-icon-and-or-chevron-btn">
              {!showStatusChip() && priorityIconToShow()}
              <button
                type="button"
                aria-label="Open dropdown"
                className="right-chevron-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setDropdownHeight(dropdownHeight ? 0 : 'auto');
                }}
                onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                onMouseLeave={() => setInsideElementIsBeingHovered(false)}
              >
                <RotatingChevron downwards0Condition={dropdownHeight} />
              </button>
            </div>
          </div>
          <div className="priority-icon-and-or-status-chip">
            {!!showStatusChip() && (
              <>
                {priorityIconToShow()}
                <div
                  onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                  onMouseLeave={() => setInsideElementIsBeingHovered(false)}
                >
                  <StatusChip companyData={companyToViewData} updateAdminProgress={updateAdminProgress} />
                </div>
              </>
            )}
          </div>
            <Button
              className="unarchive-btn"
              style={{ display: showUnarchiveButton ? 'inline-flex' : 'none' }}
              onMouseEnter={() => setInsideElementIsBeingHovered(true)}
              onMouseLeave={() => setInsideElementIsBeingHovered(false)}
              onClick={(e) => {
                e.stopPropagation();
                updateAdminProgress(companyToViewData, 'completed');
              }}
            >
              Unarchive
            </Button>
        </div>
        <AnimateHeight
          duration={500}
          height={dropdownHeight}
        >
          <hr className="above-dropdown-spacer" />
          <div className="dropdown-content">
            {companyToViewData.transactionData?.progress === 'completed' && (
              <div
                className="files-list"
                onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                onMouseLeave={() => setInsideElementIsBeingHovered(false)}
              >
                <div className="files-list-header">
                  <FolderOpenIcon />
                  <span>Client files for this transaction</span>
                  <div className="file-list-chevron-btn">
                    <button
                      type="button"
                      aria-label="Open file list dropdown"
                      className="right-chevron-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFileDropdownHeight(fileDropdownHeight ? 0 : 'auto');
                        fetchFileList();
                      }}
                    >
                      <RotatingChevron downwards0Condition={fileDropdownHeight} />
                    </button>
                  </div>
                </div>
                <AnimateHeight
                  duration={500}
                  height={fileDropdownHeight}
                >
                  <hr className="above-dropdown-spacer" />
                  {
                    fileListIsLoading ? (
                      <div className="loading-file-list">
                        <LoadingSpinner className="custom-loading-spinner" />
                      </div>
                    ) : fileList.map((file) => {
                      return (
                        <div key={file.link} className="file-el">
                          <FileOpenOutlinedIcon />
                          <span>
                            {file.fileType}
                            :
                            {' '}
                            <Button onClick={() => s3FileRequest({
                              requestType: 'get',
                              key: file.s3Key,
                              onSuccess: (url) => window.open(url),
                            })}
                            >
                              {(file.fileName.length >= 30) ? `${file.fileName.slice(0, 30).trim()}...` : file.fileName}
                            </Button>
                          </span>
                        </div>
                      );
                    })
                  }
                </AnimateHeight>
              </div>
            )}
            <div
              className="multiline-input"
              onMouseEnter={() => setInsideElementIsBeingHovered(true)}
              onMouseLeave={() => setInsideElementIsBeingHovered(false)}
            >
              <textarea
                rows={4}
                placeholder="Type note"
                maxLength={500}
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
                onFocus={() => setNoteIsFocussed(true)}
                onBlur={() => setNoteIsFocussed(false)}
              />
              <IconButton
                position="end"
                onClick={(e) => {
                  e.stopPropagation();
                  postNote();
                  setNoteContent('');
                }}
              >
                <SendOutlinedIcon />
              </IconButton>
            </div>
            <div
              onMouseEnter={() => setInsideElementIsBeingHovered(true)}
              onMouseLeave={() => setInsideElementIsBeingHovered(false)}
              className="note-list"
            >
              {noteList.map((note, noteIndex) => {
                if (note.editNote) {
                  return (
                    <React.Fragment key={`${moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('X')}`}>
                      <div className="edit-note">
                        <div
                          className="multiline-input"
                        >
                          <textarea
                            rows={4}
                            placeholder="Type note"
                            maxLength={500}
                            value={editNoteContent}
                            onFocus={() => setNoteIsFocussed(true)}
                            onBlur={() => setNoteIsFocussed(false)}
                            onChange={(e) => setEditNoteContent(e.target.value)}
                          />
                        </div>
                        <div className="edit-note-btns">
                          <Button
                            className="edit-note-cancel-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              switchNoteToEdit(true);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="edit-note-save-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteNote(noteIndex, true);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                      <div className="note-length">
                        {noteContent.length}
                        / 500
                      </div>
                    </React.Fragment>
                  );
                }
                return (
                  <div
                    key={`${moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('x')}`}
                    className={`note${note.isBeingEdited ? ' isEditing' : ''}`}
                  >
                    <div className="loading-note">
                      <LoadingSpinner className="custom-loading-spinner" />
                    </div>
                    <span className="note-date-time">
                      <span className="note-author">{note.noteAuthor}</span>
                      <span className="timestamp">
                        {' '}
                        {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY')}
                        {' '}
                        at
                        {' '}
                        {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}
                      </span>
                    </span>
                    <div className="note-content">
                      <span>
                        {note.note}
                      </span>
                    </div>
                    <IconButton
                      className="note-vert-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNoteMenuOpen(e, noteIndex);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      className="note-menu"
                      anchorEl={noteMenuAnchorEl}
                      open={noteMenuIsOpen}
                      onClose={handleNoteMenuClose}
                      disableScrollLock
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          switchNoteToEdit();
                          setEditNoteContent(noteList[editNoteIndex].note);
                          handleNoteMenuClose();
                        }}
                      >
                        <EditIcon />
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteNote(editNoteIndex);
                          handleNoteMenuClose();
                        }}
                      >
                        <DeleteOutlineIcon />
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                );
              })}
              <div className="bottom-wrapper">
                <div
                  className="submit-info"
                  onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                  onMouseLeave={() => setInsideElementIsBeingHovered(false)}
                >
                  {companyToViewData.transactionData?.progress === 'completed' ?
                    `Client submitted ${moment(
                      companyToViewData.transactionData.submittedDatetime || companyToViewData.transactionData.transactionDate,
                      'YYYY-MM-DD HH:mm:ss',
                    ).format('MM/DD/YY')} at ` +
                    `${moment(companyToViewData.transactionData.submittedDatetime ||
                      companyToViewData.transactionData.transactionDate, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}` :
                    `Client signed up ${moment(
                      companyToViewData.companyData.createdDatetime,
                      'YYYY-MM-DD HH:mm:ss',
                    ).format('MM/DD/YY')} at ` +
                    `${moment(companyToViewData.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}`}
                  <Button
                    className="account-detail"
                    onClick={() => {
                      setViewAccountInfo(true);
                      setCompanyToViewData(companyToViewData);
                      setTabToView(1);
                    }}
                  >
                    {`Go to ${companyToViewData.accountData.companyName} details`}
                  </Button>
                </div>
                {(!companyToViewData.transactionData || companyToViewData.transactionData?.adminProgress !== 'completed') && (
                  <Select
                    className="priority-select"
                    open={priorityDropdownIsOpen}
                    onOpen={() => setPriorityDropdownIsOpen(true)}
                    onClose={() => setPriorityDropdownIsOpen(false)}
                    onChange={(e) => {
                      setPriority(e.target.value);
                      updatePriority(companyToViewData, e.target.value);
                    }}
                    value={priority}
                    renderValue={() => <span className="priority-label">Priority</span>}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{ disableScrollLock: true, disablePortal: true }}
                    onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                    onMouseLeave={() => setInsideElementIsBeingHovered(false)}
                  >
                    <MenuItem value={1}>
                      <NotificationImportantOutlinedIcon className="high-icon" />
                      High
                    </MenuItem>
                    <MenuItem value={2}>
                      <ArrowDownwardIcon sx={{ visibility: 'hidden', marginRight: '18px' }} />
                      Normal
                    </MenuItem>
                    <MenuItem value={3}>
                      <ArrowDownwardIcon className="low-icon" />
                      Low
                    </MenuItem>
                  </Select>
                )}
                <div className="bottom-info">
                  {companyToViewData.paymentData && (companyToViewData.transactionData?.progress !== 'completed' ||
                    (companyToViewData.transactionData.progress === 'completed' &&
                      (!companyToViewData.transactionData.adminProgress || companyToViewData.transactionData.adminProgress === 'not started'))) ?
                    `Paid ${moment.utc(companyToViewData.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YY')} at ` +
                    `${moment.utc(companyToViewData.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss').local().format('hh:mma')}` :
                    companyToViewData.transactionData?.progress !== 'completed' ?
                      'Waiting for client to finalize account' :
                      companyToViewData.transactionData?.lastModified && companyToViewData.transactionData?.lastModifiedBy ?
                        `${!showStatusChip() ? 'Edited' : 'Submitted'} ` +
                        `${moment(companyToViewData.transactionData?.lastModified, 'YYYY-MM-DD HH:mm:ss').fromNow()} by ` +
                        `${companyToViewData.transactionData?.lastModifiedBy || 'unknown'}` : ''}
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
}

TransactionStatusBlock.propTypes = {
  companyToViewData: PropTypes.object,
  setCompanyToViewData: PropTypes.func,
  setTabToView: PropTypes.func,
  currentAdminName: PropTypes.string,
  updatePriority: PropTypes.func,
  setViewAccountInfo: PropTypes.func,
  setDropdownBlockIsOpen: PropTypes.func,
  updateAdminProgress: PropTypes.func,
};
