import React from 'react';
import PropTypes from 'prop-types';

import TransactionStatusBlock from './TransactionStatusBlock';

import './Archive.scss';

export default function Archive({
  archivedCompanies,
  currentAdminName,
  setCompanyToViewData,
  setTabToView,
  updatePriority,
  setViewAccountInfo,
  setDropdownBlockIsOpen,
  updateAdminProgress,
}) {
  return (
    <div className="Archive progress-cols-wrapper">
      <div className="progress-col">
        <h3>Archived transactions</h3>
        <div className="archive-grid">
          {archivedCompanies.map((company) => (
            <TransactionStatusBlock
              key={company.key}
              companyToViewData={company}
              currentAdminName={currentAdminName}
              setCompanyToViewData={setCompanyToViewData}
              setTabToView={setTabToView}
              updatePriority={updatePriority}
              setViewAccountInfo={setViewAccountInfo}
              setDropdownBlockIsOpen={setDropdownBlockIsOpen}
              updateAdminProgress={updateAdminProgress}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

Archive.propTypes = {
  archivedCompanies: PropTypes.array.isRequired,
  currentAdminName: PropTypes.string.isRequired,
  setCompanyToViewData: PropTypes.func.isRequired,
  setTabToView: PropTypes.func.isRequired,
  updatePriority: PropTypes.func.isRequired,
  setViewAccountInfo: PropTypes.func.isRequired,
  setDropdownBlockIsOpen: PropTypes.func.isRequired,
  updateAdminProgress: PropTypes.func.isRequired,
};
