import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  UserDataContext,
  ErrorMessageContext,
  NavWidthContext,
  ShowArchiveContext,
} from '.';

export default function ContextsProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [navWidth, setNavWidth] = useState(null);
  const [showArchive, setShowArchive] = useState(false);

  const userDataProviderValue = useMemo(() => ({ userData, setUserData }), [userData]);
  const showErrorMessageProviderValue = useMemo(() => ({ showErrorMessage, setShowErrorMessage }), [showErrorMessage]);
  const navWidthProviderValue = useMemo(() => ({ navWidth, setNavWidth }), [navWidth]);
  const showArchivesProviderValue = useMemo(() => ({ showArchive, setShowArchive }), [showArchive]);

  return (
      <UserDataContext.Provider value={userDataProviderValue}>
        <ErrorMessageContext.Provider value={showErrorMessageProviderValue}>
          <NavWidthContext.Provider value={navWidthProviderValue}>
            <ShowArchiveContext.Provider value={showArchivesProviderValue}>
              {children}
            </ShowArchiveContext.Provider>
          </NavWidthContext.Provider>
        </ErrorMessageContext.Provider>
      </UserDataContext.Provider>
  );
}

ContextsProvider.propTypes = { children: PropTypes.element.isRequired };
