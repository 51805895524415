import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CalcForm409A from './containers/409aCalcForm';
import NotFound from './components/NotFound';

export default function RouterProvider() {
  return (
    <Routes>
      <Route path="/" element={<CalcForm409A />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
