import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import EditIcon from '@mui/icons-material/Edit';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import ClientInputs from './ClientInputs';

import { copy } from '../../utils';

import useFetch from '../../hooks/useFetch';

import './AccountInfo.scss';

function ReviewTile({
  label,
  data,
  dataName,
  isEditing,
  values,
  setNewValues,
}) {
  if (dataName === 'streetAddress' && values.companyStreetAddress && values.city && values.state && values.zipCode) {
    if (!isEditing) {
      return (
        <div className="account-review-tile">
          <p>{label}</p>
          <p>{`${values.companyStreetAddress}, ${values.city}, ${values.state} ${values.zipCode}`}</p>
        </div>
      );
    }
    return (
      <div className="address-edit">
        <TextField
          label="Address"
          className="address"
          value={values.companyStreetAddress}
          onChange={(e) => {
            const newData = copy(values);
            newData.companyStreetAddress = e.target.value;
            setNewValues(newData);
          }}
        />
        <TextField
          label="City"
          value={values.city}
          onChange={(e) => {
            const newData = copy(values);
            newData.city = e.target.value;
            setNewValues(newData);
          }}
        />
        <TextField
          label="State"
          value={values.state}
          onChange={(e) => {
            const newData = copy(values);
            newData.state = e.target.value;
            setNewValues(newData);
          }}
        />
        <TextField
          label="Zip Code"
          value={values.zipCode}
          onChange={(e) => {
            const newData = copy(values);
            newData.zipCode = e.target.value;
            setNewValues(newData);
          }}
        />
      </div>
    );
  }
  if (!values) {
    return (
      <div className={`account-review-tile ${label === 'Discount code' ? 'smaller' : ''}`}>
        <p>{label}</p>
        <p>{data}</p>
      </div>
    );
  }
  if (values[dataName]) {
    if (!isEditing) {
      return (
        <div className="account-review-tile">
          <p>{label}</p>
          <p>{values[dataName]}</p>
        </div>
      );
    }
    return (
      <TextField
        label={label}
        value={values[dataName]}
        onChange={(e) => {
          const newData = copy(values);
          newData[dataName] = e.target.value;
          setNewValues(newData);
        }}
      />
    );
  }
}

ReviewTile.propTypes = {
  label: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  dataName: PropTypes.string,
  isEditing: PropTypes.bool,
  values: PropTypes.object,
  setNewValues: PropTypes.func,
};


export default function AccountInfo({ companyToViewData, viewAccountInfo }) {
  const [editingAccountInfo, setEditingAccountInfo] = useState(false);
  const [editingAdditionalUser, setEditingAdditionalUser] = useState(0);
  const [editingLegalInfo, setEditingLegalInfo] = useState(false);

  const [accountInfoIsSaving, setAccountInfoIsSaving] = useState(false);
  const [legalInfoIsSaving, setLegalInfoIsSaving] = useState(false);

  const [accountInfo, setNewAccountInfo] = useState({ ...companyToViewData.companyData, ...companyToViewData.accountData, updateAttributes: 1 });
  const [companyInfo, setNewCompanyInfo] = useState({ ...companyToViewData.companyData, ...companyToViewData.accountData, updateAttributes: 1 });

  const [{ success: accountUpdateSuccess }, updateAccountRequest] = useFetch();
  const [{ success: companyUpdateSuccess }, updateCompanyRequest] = useFetch();

  function updateUserData() {
    updateAccountRequest({
      url: '/accounts/update-account',
      urlIds: [accountInfo.companyId],
      body: accountInfo,
    });
    updateCompanyRequest({
      url: '/companies/update-company',
      urlIds: [accountInfo.companyId],
      body: { ...companyInfo, companyName: accountInfo.companyName },
    });
  }

  useEffect(() => {
    if (accountUpdateSuccess || companyUpdateSuccess) {
      setAccountInfoIsSaving(false);
      setLegalInfoIsSaving(false);
      setEditingAccountInfo(false);
      setEditingLegalInfo(false);
    }
  }, [accountUpdateSuccess, companyUpdateSuccess]);

  return (
    <div className="AccountInfo">
      {viewAccountInfo ? (
        <>
          <div className="account-review-block">
            <h5 className="first-title">Sign up information</h5>
            <div className="account-review-tiles">
              <ReviewTile
                label="Sign up date"
                data={moment(companyToViewData.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY')}
              />
              <ReviewTile
                label="Data started 409A"
                data={companyToViewData.transactionData ?
                  moment(companyToViewData.transactionData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY') : 'N/A'}
              />
              <ReviewTile
                label="Date submitted 409A"
                data={companyToViewData.transactionData?.submittedDatetime ?
                  moment(companyToViewData.transactionData.submittedDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY') : 'N/A'}
              />
            </div>
          </div>
          <hr />
          <div className="account-review-block">
            {!editingAccountInfo && !accountInfoIsSaving ? (
              <IconButton
                className="edit-icon-btn"
                onClick={() => setEditingAccountInfo(true)}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <div className="btns-container">
                <Button
                  className="save-btn"
                  onClick={() => {
                    setAccountInfoIsSaving(true);
                    updateUserData();
                  }}
                >
                  {!accountInfoIsSaving ? (
                    <>
                      <SaveOutlinedIcon />
                      Save
                    </>
                  ) : (
                    <>
                      <LoadingSpinner className="loading-spinner" />
                      Saving
                    </>
                  )}
                </Button>
                <Button
                  className="cancel-btn"
                  onClick={() => setEditingAccountInfo(false)}
                >
                  <CancelOutlinedIcon />
                  Cancel
                </Button>
              </div>
            )}
            <h5>Basic account information</h5>
            <div className="account-review-tiles">
              <ReviewTile
                label="Company name"
                dataName="companyName"
                isEditing={editingAccountInfo}
                values={accountInfo}
                setNewValues={setNewAccountInfo}
              />
              <ReviewTile
                label="First name - Primary admin"
                dataName="firstName"
                isEditing={editingAccountInfo}
                values={accountInfo}
                setNewValues={setNewAccountInfo}
              />
              <ReviewTile
                label="Last name - Primary admin"
                dataName="lastName"
                isEditing={editingAccountInfo}
                values={accountInfo}
                setNewValues={setNewAccountInfo}
              />
              <ReviewTile
                label="Email - Primary admin"
                dataName="email"
                isEditing={editingAccountInfo}
                values={accountInfo}
                setNewValues={setNewAccountInfo}
              />
              <ReviewTile
                label="Phone number - Primary admin"
                dataName="phoneNumber"
                isEditing={editingAccountInfo}
                values={accountInfo}
                setNewValues={setNewAccountInfo}
              />
              <ReviewTile
                label="Company street address"
                dataName="streetAddress"
                isEditing={editingAccountInfo}
                values={companyInfo}
                setNewValues={setNewCompanyInfo}
              />
              <ReviewTile
                label="Company website"
                dataName="companyWebsite"
                isEditing={editingAccountInfo}
                values={companyInfo}
                setNewValues={setNewCompanyInfo}
              />
              <ReviewTile
                label="Employer Identification Number (EIN)"
                dataName="ein"
                isEditing={editingAccountInfo}
                values={companyInfo}
                setNewValues={setNewCompanyInfo}
              />
              <ReviewTile
                label="Doing Business As (DBA)"
                dataName="dba"
                isEditing={editingAccountInfo}
                values={companyInfo}
                setNewValues={setNewCompanyInfo}
              />
            </div>
          </div>
          {companyToViewData.accountData.extraUsers?.map((extraUser, index) => (
            <>
              <hr />
              <div className="account-review-block">
                {editingAdditionalUser !== index + 1 ? (
                  <IconButton
                    className="edit-icon-btn"
                    onClick={() => setEditingAdditionalUser(index + 1)}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <div className="btns-container">
                    <Button
                      className="save-btn"
                      onClick={() => updateUserData()}
                    >
                      <SaveOutlinedIcon />
                      Save
                    </Button>
                    <Button
                      className="cancel-btn"
                      onClick={() => setEditingAdditionalUser(0)}
                    >
                      <CancelOutlinedIcon />
                      Cancel
                    </Button>
                  </div>
                )}
                <h5>Additional user</h5>
                <div className="account-review-tiles four-columns">
                  <ReviewTile
                    label="First name"
                    data={`$${extraUser.firstName}`}
                  />
                  <ReviewTile
                    label="Last name"
                    data={`$${extraUser.lastName}`}
                  />
                  <ReviewTile
                    label="Email address"
                    data={`$${extraUser.email}`}
                  />
                  <ReviewTile
                    label="Account permission"
                    data={`$${extraUser.accountPermission}`}
                  />
                </div>
              </div>
            </>
          ))}
          {((companyToViewData.companyData.legalFirmName &&
            companyToViewData.companyData.legalAdvisorName &&
            companyToViewData.companyData.legalAdvisorEmail) ||
            (companyToViewData.companyData.accountingFirmName &&
              companyToViewData.companyData.accountingAdvisorName &&
              companyToViewData.companyData.accountingAdvisorEmail)) &&
            (
              <>
                <hr />
                <div className="account-review-block">
                  {!editingLegalInfo ? (
                    <IconButton
                      className="edit-icon-btn"
                      onClick={() => setEditingLegalInfo(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <div className="btns-container">
                      <Button
                        className="save-btn"
                        onClick={() => {
                          setLegalInfoIsSaving(true);
                          updateUserData();
                        }}
                      >
                        {!legalInfoIsSaving ? (
                          <>
                            <SaveOutlinedIcon />
                            Save
                          </>
                        ) : (
                          <>
                            <LoadingSpinner className="loading-spinner" />
                            Saving
                          </>
                        )}
                      </Button>
                      <Button
                        className="cancel-btn"
                        onClick={() => setEditingLegalInfo(false)}
                      >
                        <CancelOutlinedIcon />
                        Cancel
                      </Button>
                    </div>
                  )}
                  <h5>Your accounting and legal firm</h5>
                  <div className="account-review-tiles">
                    <ReviewTile
                      label="Type of firm"
                      data={(companyToViewData.companyData.legalFirmName &&
                        companyToViewData.companyData.legalAdvisorName &&
                        companyToViewData.companyData.legalAdvisorEmail) ? 'Law firm' : 'Accounting firm'}
                    />
                    <ReviewTile
                      label="Name of firm"
                      data={companyToViewData.companyData.legalFirmName || companyToViewData.companyData.accountingFirmName}
                      dataName={companyToViewData.companyData.legalFirmName ? 'legalFirmName' : 'accountingFirmName'}
                      isEditing={editingLegalInfo}
                      values={companyInfo}
                      setNewValues={setNewCompanyInfo}
                    />
                    <div />
                    <ReviewTile
                      label="Name of advisor"
                      data={companyToViewData.companyData.legalAdvisorName || companyToViewData.companyData.accountingAdvisorName}
                      dataName={companyToViewData.companyData.legalFirmName ? 'legalAdvisorName' : 'accountingAdvisorName'}
                      isEditing={editingLegalInfo}
                      values={companyInfo}
                      setNewValues={setNewCompanyInfo}
                    />
                    <ReviewTile
                      label="Advisor email"
                      data={companyToViewData.companyData.legalAdvisorEmail || companyToViewData.companyData.accountingAdvisorEmail}
                      dataName={companyToViewData.companyData.legalFirmName ? 'legalAdvisorEmail' : 'accountingAdvisorEmail'}
                      isEditing={editingLegalInfo}
                      values={companyInfo}
                      setNewValues={setNewCompanyInfo}
                    />
                    {/* <ReviewTile
                      label="Account permissions"
                      data={companyToViewData.companyData.legalAdvisorPermission || companyToViewData.companyData.accountingAdvisorPermission}
                    /> */}
                  </div>
                </div>
              </>
            )}
          {companyToViewData.paymentData && (
            <>
              <hr />
              <div className="account-review-block">
                <h5>Payment information</h5>
                <div className="account-review-tiles four-columns">
                  <ReviewTile
                    label="Paid date"
                    data={moment.utc(companyToViewData.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YY')}
                  />
                  <ReviewTile
                    label="Paid reason"
                    data={(
                      <>
                        Annual subscription
                        <br />
                        with free 409A
                      </>
                    )}
                  />
                  <ReviewTile
                    label="Paid amount"
                    data={`$${parseInt(companyToViewData.paymentData.amount, 10) / 100}`}
                  />
                  <ReviewTile
                    label="Discount code"
                    data={companyToViewData.paymentData?.metadata?.code?.toUpperCase() || 'N/A'}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="top-tabs-nav client-inputs">
            <button
              type="button"
              className="tab-button active-tab"
            >
              Client Inputs
            </button>
          </div>
          {!companyToViewData.transactionData ? (
            <div className="no-inputs-dialog">
              <p>No client inputs to show.</p>
              <p>
                409A Sandbox will be available after calculation.
                Client inputs will be available after the client starts to fill out their 409A submission form.
              </p>
            </div>
          ) : <ClientInputs companyToViewData={companyToViewData.transactionData} />}
        </>
      )}
    </div>
  );
}

AccountInfo.propTypes = {
  viewAccountInfo: PropTypes.bool,
  companyToViewData: PropTypes.object,
};
