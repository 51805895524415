import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

import { ShowArchiveContext } from '../../contexts';

import './SideNavBar.scss';

export default function SideNavBar() {
  const [navBarIsOpen, setNavBarIsOpen] = useState(false);
  const [currentBodyHeight, setCurrentBodyHeight] = useState(0);

  const { showArchive, setShowArchive } = useContext(ShowArchiveContext);

  const location = useLocation();

  const nav = useNavigate();

  useEffect(() => { if (navBarIsOpen) setCurrentBodyHeight(document.documentElement.scrollHeight - 56); }, [navBarIsOpen, location]);

  return (
    <div className="SideNavBar">
      {navBarIsOpen && (
        <div
          className="overlay"
          aria-label="overlay"
          role="button"
          tabIndex="0"
          onKeyDown={() => setNavBarIsOpen(false)}
          onClick={() => setNavBarIsOpen(false)}
          style={{ minHeight: `${currentBodyHeight}px` }}
        />
      )}
      <Button
        className={`nav-toggle ${navBarIsOpen ? 'is-open' : ''}`}
        onClick={() => setNavBarIsOpen(!navBarIsOpen)}
      >
        {navBarIsOpen ? <MenuOpenIcon /> : <MenuIcon />}
      </Button>
      <div
        className={`nav-bar ${navBarIsOpen ? 'open' : 'closed'}`}
        style={{ minHeight: `${currentBodyHeight}px` }}
      >
        <Button
          className={`nav-btn ${window.location.pathname === '/' && !showArchive ? 'selected' : ''}`}
          onClick={() => {
            nav('/');
            setShowArchive(false);
          }}
        >
          <HomeOutlinedIcon />
          Home
        </Button>
        <hr />
        <span>Transactions</span>
        <Button
          className={`nav-btn ${!showArchive ? 'selected' : ''}`}
          onClick={() => {
            nav('/');
            setShowArchive(false);
          }}
        >
          <InsertChartOutlinedIcon />
          409A valuations
        </Button>
        <Button
          className={`nav-btn ${showArchive ? 'selected' : ''}`}
          onClick={() => setShowArchive(true)}
        >
          <Inventory2OutlinedIcon />
          Archive
        </Button>
      </div>
    </div>
  );
}
