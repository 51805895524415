import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import SideNavBar from './SideNavBar';

import { NavWidthContext } from '../../contexts';

import useSignOut from '../../hooks/useSignOut';

import { ReactComponent as InitioLogo } from '../../images/initio-logo.svg';

import './NavBar.scss';

export default function NavBar() {
  const nav = useNavigate();

  const [signOut] = useSignOut();

  const { navWidth } = useContext(NavWidthContext);

  return (
    <div className="NavBar" style={{ minWidth: navWidth }}>
      <SideNavBar />
      <IconButton
        className="initio-logo"
        onClick={() => nav(window.location.assign(process.env.REACT_APP_ADMIN_HOSTNAME))}
      >
        <InitioLogo />
      </IconButton>
      <div className="right-nav">
        <IconButton
          disableRipple
          onClick={signOut}
          className="no-name-sign-out"
        >
          <LogoutOutlinedIcon className="log-out-icon" />
          <span>Sign out</span>
        </IconButton>
      </div>
    </div>
  );
}
