import cloneDeep from 'lodash.clonedeep';

const copy = (obj) => cloneDeep(obj);

const formatUSCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 2,
});

const formatUSCurrencyWithFourDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

const onlyNums = (str) => str.replace(/[^\d.-]/g, '');

const commaEvery3rdChar = (val) => val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

// limit the number of characters and numerical value of a given string
function maxCharsAndValue(str, charLimit, valLimit) {
  let newStr = str;
  if (parseInt(newStr, 10) > valLimit) {
    newStr = valLimit.toString();
  }
  if (newStr.length > charLimit) {
    newStr.substring(0, charLimit);
  }
  return newStr;
}

// adds a given character to a string (%, x, yrs...)
function concatCharacter(input, char) {
  return input && (!input.toString().includes(char) ? input.toString() + char : input);
}

const removeCommas = (str) => str.replaceAll(',', '');


export {
  copy,
  formatUSCurrency,
  formatUSCurrencyWithFourDecimals,
  onlyNums,
  commaEvery3rdChar,
  maxCharsAndValue,
  concatCharacter,
  removeCommas,
};
