import { useState, useEffect } from 'react';

import { getUrl, uploadData, list, remove } from 'aws-amplify/storage';

import amplifyConfig from '../utils/amplifyConfig';

import useAsync from './useAsync';

export default function useS3(givenRequest) {
  const [request, setRequest] = useState(givenRequest);

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(null);
  const [callEnded, setCallEnded] = useState(false);

  const defaultBucket = process.env.REACT_APP_S3_UPLOADS_BUCKET;

  const [, listRequest] = useAsync();
  const [, getRequest] = useAsync();
  const [, putRequest] = useAsync();
  const [, removeRequest] = useAsync();

  useEffect(() => {
    if (request?.requestType && !loading) {
      setCallEnded(false);
      setSuccess(false);
      setError(null);
      setResponse(null);

      setLoading(true);

      amplifyConfig({
        Storage: {
          S3: {
            bucket: request.bucket || defaultBucket,
            region: process.env.REACT_APP_AWS_REGION,
          },
        },
      });

      const s3Options = {
        ...request.contentDisposition && { contentDisposition: request.contentDisposition },
        ...request.requestType === 'list' && { listAll: true },
        ...request.requestType === 'put' && { progressCallback: setProgress },
      };

      const onError = () => setError(true);

      const onSuccess = (successResponse) => {
        let parsedResponse;
        if (request?.requestType === 'list') parsedResponse = successResponse.items;
        else if (request?.requestType === 'get') parsedResponse = successResponse.url.toString();
        else parsedResponse = successResponse;
        setResponse(parsedResponse);
        setSuccess(true);
        if (request.onSuccess) request.onSuccess(parsedResponse);
      };

      if (request?.requestType === 'list') listRequest({ promise: () => list({ path: '', options: s3Options }), onError, onSuccess });
      if (request?.requestType === 'get') getRequest({ promise: () => getUrl({ path: request.key, options: s3Options }), onError, onSuccess });
      if (request?.requestType === 'put') putRequest({ promise: () => uploadData({ path: request.key, data: request.file, options: s3Options }), onError, onSuccess });
      if (request?.requestType === 'remove') removeRequest({ promise: () => remove({ path: request.key, options: s3Options }), onError, onSuccess });
    }
  }, [request]);

  useEffect(() => { if (error && request?.onError) request.onError(); }, [error]);

  useEffect(() => setCallEnded(success || error), [success, error]);

  useEffect(() => {
    if (callEnded) {
      if (request.onFinally) request.onFinally();
      setRequest({});
      setLoading(false);
    }
  }, [callEnded]);

  return [{ response, loading, success, callEnded, progress }, setRequest];
}
