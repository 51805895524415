import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ReactComponent as GreenCheckmark } from '../../images/icons/green-check-circle.svg';

import './StatusChip.scss';

export default function StatusChip({ companyData, updateAdminProgress }) {
  const [chipLabel, setChipLabel] = useState('');

  const adminStatuses = [
    'Client not started 409A',
    'Client 409A in progress',
    'Work in progress',
    'Review sandbox',
    'Sandbox sent to client',
    'Review report',
    '409A done',
  ];

  function determineChipLabel() {
    const adminProgress = companyData.transactionData?.adminProgress;
    if ((!companyData.transactionData && companyData.paymentData) || adminProgress === 'client not started 409a') return 'Client not started 409A';
    if ((companyData.transactionData && companyData.transactionData.progress !== 'completed') || adminProgress === 'client 409a in progress') return 'Client 409A in progress';
    if (adminProgress === 'work in progress') return 'Work in progress';
    if (companyData.transactionData?.isCalculating) return 'Calculation in progress';
    if (adminProgress === 'calculation failed') return 'Calculation failed';
    if (adminProgress === 'calculation completed' || adminProgress === 'review sandbox') return 'Review sandbox';
    if (adminProgress === 'sandbox sent to client') return 'Sandbox sent to client';
    if (adminProgress === 'review report') return 'Review report';
    return '409A done';
  }

  useEffect(() => setChipLabel(determineChipLabel()), [companyData.transactionData]);

  const chipLabelClassName = () => chipLabel === '409A done' ? 'Completed' : chipLabel.replaceAll(' ', '-');

  return (
    <div className="StatusChipWrapper">
      {adminStatuses.includes(chipLabel) ? (
        <Select
          className={`status-chip ${chipLabelClassName()}`}
          value={chipLabel}
          onChange={(e) => {
            setChipLabel(e.target.value);
            updateAdminProgress(companyData, e.target.value === '409A done' ? 'completed' : e.target.value.toLowerCase());
          }}
          startAdornment={chipLabel === '409A done' && <GreenCheckmark className="check-icon" />}
          onClick={(e) => { e.stopPropagation(); }}
          IconComponent={ExpandMoreIcon}
          MenuProps={{ disableScrollLock: true, classes: { paper: 'select-admin-status-dropdown' } }}
        >
          {adminStatuses.map((name) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
        </Select>
      ) : (
        <div className={`status-chip single ${chipLabelClassName()}`}>{chipLabel}</div>
      )}
    </div>
  );
}

StatusChip.propTypes = {
  companyData: PropTypes.object,
  updateAdminProgress: PropTypes.func,
};
