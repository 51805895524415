import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import './Loader.scss';

export default function loader() {
  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}>
    <div className="loader" />
  </Box>
  )}
