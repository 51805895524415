

function authenticationCheck(cognitoSession) {
  const userGroups = cognitoSession.tokens.accessToken.payload['cognito:groups'] || [];
  if (userGroups.includes('initio-admins')) return true;
  return false;
}

function parseUserAttributes(cognitoAttributes) {
  const parsedAttributes = {};

  Object.keys(cognitoAttributes).forEach((attributeName) => {
    if (attributeName === 'sub') parsedAttributes.userId = cognitoAttributes[attributeName];
    else parsedAttributes[attributeName.replaceAll('custom:', '')] = cognitoAttributes[attributeName];
  });

  return parsedAttributes;
}

export { authenticationCheck, parseUserAttributes };
