import React from 'react';
import PropTypes from 'prop-types';

import {
  Document,
  Font,
} from '@react-pdf/renderer';

import CoverPage from './pages/CoverPage';
import TableOfContents from './pages/TableOfContents';
import Introduction from './pages/Introduction';
import Tables from './pages/Tables';
import SandboxVal from './pages/SandboxVal';
import FinalPages from './pages/FinalPages';

import robotoFont from './assets/fonts/Roboto-Regular.ttf';
import robotoMedFont from './assets/fonts/Roboto-Medium.ttf';
import robotoBoldFont from './assets/fonts/Roboto-Black.ttf';
import robotoItalicFont from './assets/fonts/Roboto-Italic.ttf';
import manropeFont from './assets/fonts/Manrope-Regular.ttf';
import manropeLightFont from './assets/fonts/Manrope-Light.ttf';
import manropeMedFont from './assets/fonts/Manrope-Medium.ttf';
import manropeSemiFont from './assets/fonts/Manrope-SemiBold.ttf';
import manropeBoldFont from './assets/fonts/Manrope-Bold.ttf';

export default function PDFReport({
  companyName,
  latestFundingDate,
  valuationDate,
  sandboxData,
  capTableData,
  breakpoint1TableData,
  breakpoint2TableData,
  earlyOPMTableData,
  lateOPMTableData,
  perShareSummaryTableData,
  marketAdjustmentValue,
  isSingleScenario,
  chosenFunding,
  SAFEValCap,
  companyIndustry,
  SAFEPrincipal,
  GPCAndBacksolve,
  GPCOnly,
}) {
  Font.register({ family: 'Roboto', src: robotoFont });
  Font.register({ family: 'RobotoMed', src: robotoMedFont });
  Font.register({ family: 'RobotoBold', src: robotoBoldFont });
  Font.register({ family: 'RobotoItalic', src: robotoItalicFont });
  Font.register({ family: 'Manrope', src: manropeFont });
  Font.register({ family: 'ManropeLight', src: manropeLightFont });
  Font.register({ family: 'ManropeMed', src: manropeMedFont });
  Font.register({ family: 'ManropeSemi', src: manropeSemiFont });
  Font.register({ family: 'ManropeBold', src: manropeBoldFont });

  Font.registerHyphenationCallback((word) => (
    [word]
  ));

  // To handle pagination
  const capTableNumberOfPages =
    capTableData.hasDividends && capTableData.isComplexData ?
      Math.ceil(capTableData.tableData.length / 21) * 4 :
      Math.ceil(capTableData.tableData.length / 21) * 2;
  const capTablePageTotal = capTableNumberOfPages;
  const breakpoints1NumberOfPages = Math.ceil(breakpoint1TableData.tableData.length / 21) *
    Math.ceil((breakpoint1TableData.columnData.length - 1) / 7) * 2;
  const breakpoints1PageTotal = capTablePageTotal + breakpoints1NumberOfPages;
  const breakpoints2NumberOfPages = Math.ceil(breakpoint2TableData.tableData.length / 21) *
    Math.ceil((breakpoint2TableData.columnData.length - 1) / 7) * 2;
  const breakpoints2PageTotal = breakpoints1PageTotal + breakpoints2NumberOfPages;
  const earlyOPMNumberOfPages = isSingleScenario ? 0 : Math.ceil(earlyOPMTableData.earlyAggregateValues.length / 11) *
    Math.ceil(earlyOPMTableData.columnData.length / 7) * 2;
  const earlyOPMPageTotal = breakpoints2PageTotal + earlyOPMNumberOfPages;
  const lateOPMNumberOfPages = Math.ceil(lateOPMTableData.lateAggregateValues.length / 11) *
    Math.ceil(lateOPMTableData.columnData.length / 7) * 2;
  const lateOPMPageTotal = earlyOPMPageTotal + lateOPMNumberOfPages;
  const perShareSummaryNumberOfPages = Math.ceil(perShareSummaryTableData.tableData.length / 18) * 2;
  const allTablesPageTotal = lateOPMPageTotal + perShareSummaryNumberOfPages;

  const paginationData = {
    capTablePageTotal,
    breakpoints1PageTotal,
    breakpoints2PageTotal,
    earlyOPMPageTotal,
    lateOPMPageTotal,
    allTablesPageTotal,
  };

  return (
    <Document>
      <CoverPage
        companyName={companyName}
        valuationDate={valuationDate}
      />
      <TableOfContents
        valuationDate={valuationDate}
        paginationData={paginationData}
        isSingleScenario={isSingleScenario}
      />
      <Introduction
        companyName={companyName}
        sandboxData={sandboxData}
        valuationDate={valuationDate}
      />
      <SandboxVal
        sandboxData={sandboxData}
      />
      <Tables
        capTableData={capTableData}
        breakpoint1TableData={breakpoint1TableData}
        breakpoint2TableData={breakpoint2TableData}
        earlyOPMTableData={earlyOPMTableData}
        lateOPMTableData={lateOPMTableData}
        perShareSummaryTableData={perShareSummaryTableData}
        paginationData={paginationData}
        isSingleScenario={isSingleScenario}
      />
      <FinalPages
        companyName={companyName}
        latestFundingDate={latestFundingDate}
        valuationDate={valuationDate}
        lastTablePage={allTablesPageTotal}
        marketAdjustmentValue={marketAdjustmentValue}
        chosenFunding={chosenFunding}
        SAFEValCap={SAFEValCap}
        companyIndustry={companyIndustry}
        SAFEPrincipal={SAFEPrincipal}
        GPCAndBacksolve={GPCAndBacksolve}
        GPCOnly={GPCOnly}
      />
    </Document>
  );
}


PDFReport.propTypes = {
  companyName: PropTypes.string,
  latestFundingDate: PropTypes.string,
  valuationDate: PropTypes.string,
  sandboxData: PropTypes.object,
  capTableData: PropTypes.object,
  breakpoint1TableData: PropTypes.object,
  breakpoint2TableData: PropTypes.object,
  earlyOPMTableData: PropTypes.object,
  lateOPMTableData: PropTypes.object,
  perShareSummaryTableData: PropTypes.object,
  marketAdjustmentValue: PropTypes.string,
  isSingleScenario: PropTypes.bool,
  chosenFunding: PropTypes.string,
  SAFEValCap: PropTypes.string,
  companyIndustry: PropTypes.string,
  SAFEPrincipal: PropTypes.bool,
  GPCAndBacksolve: PropTypes.bool,
  GPCOnly: PropTypes.bool,
};
