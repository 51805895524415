import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { Slide, Snackbar } from '@mui/material';

import alertIconFilled from '../../images/icons/alert_icon_filled.svg';

import { assignDefaultValues } from './calcTablesUtils';

import {
  copy,
  onlyNums,
  maxCharsAndValue,
  commaEvery3rdChar,
} from '../../utils';

import './CalcTable.scss';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function CalcTable({
  tableData,
  setTableData,
  tableSchema,
  createTable,
  canEditFirstColumn,
  firstColumnIsDropdown,
  canAddOrDeleteRow,
  isHalfWidth,
  hasTotalRow,
  extraHeadRowCells,
  isWarrantTable,
  isOptionTable,
  preferredData,
  commonData,
  isPreferredTable,
  isSafeTable,
  setCalcTableHasError,
  isCommonTable,
  isOpmTable,
  isCapTable,
  preferredInputsHaveError,
  safeInputsHaveError,
  setSafeInputsHaveError,
  setPreferredInputsHaveError,
  commonTableHaveError,
  optionsTableHaveError,
  warrantsTableHaveError,
  remainingOptionPoolTableHaveError,
  setOptionsTableHaveError,
  setCommonTableHaveError,
  setWarrantsTableHaveError,
  setRemainingOptionPoolTableHaveError,
  setChangeHasBeenMade,
  isRemainingOptionsPoolTable,
}) {
  const [editFirstColumnIndex, setEditFirstColumnIndex] = useState(-1);
  const [editIconIsVisibleIndex, setEditIconIsVisibleIndex] = useState(-1);

  const [dropdownFirstColumnIsOpen, setDropdownFirstColumnIsOpen] = useState(false);
  const [dropdownTypeIsOpen, setDropdownTypeIsOpen] = useState(false);
  const [dropdownIndexToOpen, setDropdownIndexToOpen] = useState(-1);

  const [editEquityValue, setEditEquityValue] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [rowIndexToDelete, setRowIndexToDelete] = useState(-1);
  const [rowDeleteBackgroundIndex, setRowDeleteBackgroundIndex] = useState(-1);

  const [preferredDropdownValues, setPreferredDropdownValues] = useState([]);
  const [commonDropdownValues, setCommonDropdownValues] = useState([]);

  const [currentTableData, setCurrentTableData] = useState([]);

  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [showCalculateError, setShowCalculateError] = useState(false);
  const [indexesWithErrors, setIndexesWithErrors] = useState([]);
  const [valueIndexWithErrors, setValueIndexWithErrors] = useState([]);
  const [possibleDuplicateModified, setPossibleDuplicateModified] = useState(false);

  const [removedRowInState, setRemovedRowInState] = useState({});

  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    if (rowCount <= 1) setRowCount(tableData.length);
    if (tableData) setCurrentTableData(tableData);
    if (isOpmTable && tableData[0].lateEquityValue.value) setEditEquityValue(true);
  }, [tableData]);

  // Checks for duplicate errors
  useEffect(() => {
    if (isPreferredTable || isSafeTable || isOptionTable || isWarrantTable || isCommonTable) {
      let duplicateCount = 0;
      const errorIndexes = [];
      tableData.forEach((row1, i) => {
        tableData.forEach((row2, j) => {
          if (isPreferredTable) {
            if (
              row1.preferred.value === row2.preferred.value &&
              (row1.transactionDate.value && row1.transactionDate.value.format('MM/DD/YYYY')) ===
              (row2.transactionDate.value && row2.transactionDate.value.format('MM/DD/YYYY')) &&
              i !== j
            ) {
              duplicateCount += 1;
              errorIndexes.push(i);
            }
          } else if (isSafeTable) {
            if (
              row1.safeConvertible.value === row2.safeConvertible.value &&
              (row1.transactionDate.value && row1.transactionDate.value.format('MM/DD/YYYY')) ===
              (row2.transactionDate.value && row2.transactionDate.value.format('MM/DD/YYYY')) &&
              i !== j
            ) {
              duplicateCount += 1;
              errorIndexes.push(i);
            }
          } else if (isOptionTable) {
            if (
              row1.options.value === row2.options.value &&
              row1.options.type === row2.options.type &&
              row1.strikePrice.value === row2.strikePrice.value &&
              i !== j
            ) {
              duplicateCount += 1;
              errorIndexes.push(i);
            }
          } else if (isWarrantTable) {
            if (
              row1.warrants.value === row2.warrants.value &&
              row1.warrants.type === row2.warrants.type &&
              row1.strikePrice.value === row2.strikePrice.value &&
              i !== j
            ) {
              duplicateCount += 1;
              errorIndexes.push(i);
            }
          } else if (isCommonTable) {
            if (
              row1.common.value === row2.common.value &&
              i !== j
            ) {
              duplicateCount += 1;
              errorIndexes.push(i);
            }
          }
        });
      });
      if (duplicateCount >= 2) {
        setShowDuplicateError(true);
        setCalcTableHasError(true);
        setIndexesWithErrors(errorIndexes);
      } else {
        setShowDuplicateError(false);
        setCalcTableHasError(false);
        setIndexesWithErrors([]);
      }
      setPossibleDuplicateModified(false);
    }
  }, [possibleDuplicateModified]);


  useEffect(() => {
    function appendDateIfDuplicatePreferred(value, rowIndex) {
      let instancesOfValue = 0;
      preferredData.forEach((row) => {
        if (row.preferred.value === value) {
          instancesOfValue += 1;
        }
      });
      if (instancesOfValue >= 2) {
        return `${value} ${preferredData[rowIndex].transactionDate.value ? preferredData[rowIndex].transactionDate.value.format('YYYY-MM-DD') : ''}`;
      }
      return value;
    }
    if (preferredData) {
      const dropdownValues = [];
      preferredData.forEach((row, index) => {
        if (row.preferred.value) {
          dropdownValues.push(
            appendDateIfDuplicatePreferred(row.preferred.value, index),
          );
        }
      });
      setPreferredDropdownValues(dropdownValues);
    }
    if (commonData) {
      const dropdownValues = [];
      commonData.forEach((row) => {
        if (row.common.value) dropdownValues.push(row.common.value);
      });
      setCommonDropdownValues(dropdownValues);
    }
  }, [preferredData, commonData]);

  useEffect(() => {
    const valueIndexErrors = [];
    if (isPreferredTable && preferredInputsHaveError) {
      preferredInputsHaveError.forEach((r, index) => {
        valueIndexErrors.push(index);
      });
      if (preferredInputsHaveError.every((row) => !Object.keys(row).length)) {
        setShowCalculateError(false);
      } else setShowCalculateError(true);
      setValueIndexWithErrors(valueIndexErrors);
    }
  }, [preferredInputsHaveError, tableData]);

  useEffect(() => {
    const valueIndexErrors = [];
    if (safeInputsHaveError && isSafeTable) {
      safeInputsHaveError.forEach((r, index) => {
        valueIndexErrors.push(index);
      });
      if (safeInputsHaveError.every((row) => !Object.keys(row).length)) {
        setShowCalculateError(false);
      } else setShowCalculateError(true);
      setValueIndexWithErrors(valueIndexErrors);
    }
  }, [safeInputsHaveError, tableData]);

  function addNewRow() {
    tableData.push(createTable(...assignDefaultValues(tableSchema, rowCount + 1)));
    setRowCount(rowCount + 1);
    setTableData(copy(tableData));
  }

  function addRemovedRowInState() {
    if (tableData.length === 1) {
      if (isPreferredTable && !tableData[0].preferred.value) tableData.splice(rowIndexToDelete, 1);
      tableData.splice(rowIndexToDelete, 0, removedRowInState);
    } else {
      tableData.splice(rowIndexToDelete, 0, removedRowInState);
    }
    setTableData(copy(tableData));
    setRemovedRowInState([]);
    setShowDeleteAlert(false);
  }

  function holdDeletedRowInState(rowIndex) {
    const removedRow = tableData[rowIndex];
    setRemovedRowInState(removedRow);
    tableData.splice(rowIndex, 1);
    setTableData(copy(tableData));
    setChangeHasBeenMade(true);
    if (!tableData.length) addNewRow();
    if (isPreferredTable || isSafeTable) {
      const errorsCopy = copy(isPreferredTable ? preferredInputsHaveError : safeInputsHaveError);
      errorsCopy.push({});
      errorsCopy.splice(rowIndex, 1);
      if (isPreferredTable) setPreferredInputsHaveError(errorsCopy);
      else setSafeInputsHaveError(errorsCopy);
    }
    if (isCommonTable) {
      const errorsCopy = copy(commonTableHaveError);
      errorsCopy.splice(rowIndex, 1);
      setCommonTableHaveError(errorsCopy);
    }
    if (isOptionTable) {
      const errorsCopy = copy(optionsTableHaveError);
      errorsCopy.splice(rowIndex, 1);
      setOptionsTableHaveError(errorsCopy);
    }
    if (isWarrantTable) {
      const errorsCopy = copy(warrantsTableHaveError);
      errorsCopy.splice(rowIndex, 1);
      setWarrantsTableHaveError(errorsCopy);
    }
    if (
      (isSafeTable && safeInputsHaveError.every((row) => Object.keys(row).length)) ||
      (isPreferredTable && preferredInputsHaveError.every((row) => Object.keys(row).length))
    ) {
      setShowCalculateError(false);
    }
  }

  function handleChange(dataName, dataValue, dataIndex, charLimit, valLimit, changeType) {
    const newValue = changeType ? dataValue : commaEvery3rdChar(onlyNums(dataValue));
    const newData = copy(currentTableData);
    newData[dataIndex][dataName].value =
      charLimit ? maxCharsAndValue(newValue, charLimit, valLimit) : newValue;
    setCurrentTableData(newData);
  }

  function handleOnBlurChange(dataName, dataValue, dataIndex) {
    const newData = copy(tableData);
    if (dataName === 'isDividends' && dataValue === 0) {
      newData[dataIndex].dividendRate.value = null;
      newData[dataIndex].dividendCompounding.value = null;
    } else if (dataName === 'participationRights' && dataValue === 'non-participating') {
      newData[dataIndex].participationCap.value = null;
    } else if (dataName === 'interestRate' && dataValue === '') {
      newData[dataIndex].interestCompounding.value = null;
      newData[dataIndex].maturityDate.value = null;
    } else if (dataName === 'earlyPercentage') {
      newData[dataIndex].latePercentage.value = `${100 - parseFloat(dataValue)}`;
    }
    if (newData[dataIndex][dataName].value !== dataValue) setChangeHasBeenMade(true);
    newData[dataIndex][dataName].value = dataValue;
    setTableData(newData);

    if (safeInputsHaveError && isSafeTable) {
      if (safeInputsHaveError[dataIndex]) {
        if (!safeInputsHaveError[dataIndex][dataName]) {
          if (newData[dataIndex][dataName].value) {
            /* eslint-disable no-param-reassign */
            delete safeInputsHaveError[dataIndex][dataName];
            setSafeInputsHaveError(safeInputsHaveError);
          }
        }
      }
    } else if (preferredInputsHaveError && isPreferredTable) {
      if (preferredInputsHaveError[dataIndex]) {
        if (!preferredInputsHaveError[dataIndex][dataName]) {
          if (newData[dataIndex][dataName].value) {
            /* eslint-disable no-param-reassign */
            delete preferredInputsHaveError[dataIndex][dataName];
            setPreferredInputsHaveError(preferredInputsHaveError);
          }
        }
      }
    } else if (isCommonTable && commonTableHaveError[dataIndex]?.[dataName]) {
      if (newData[dataIndex][dataName].value) {
        commonTableHaveError[dataIndex][dataName] = false;
        setCommonTableHaveError(commonTableHaveError);
      }
    } else if (isOptionTable && optionsTableHaveError[dataIndex]?.[dataName]) {
      if (newData[dataIndex][dataName].value) {
        optionsTableHaveError[dataIndex][dataName] = false;
        setOptionsTableHaveError(optionsTableHaveError);
      }
    } else if (isWarrantTable && warrantsTableHaveError[dataIndex]?.[dataName]) {
      if (newData[dataIndex][dataName].value) {
        warrantsTableHaveError[dataIndex][dataName] = false;
        setWarrantsTableHaveError(warrantsTableHaveError);
      }
    } else if (isRemainingOptionsPoolTable && remainingOptionPoolTableHaveError) {
      if (newData[dataIndex].available.value !== '') {
        setRemainingOptionPoolTableHaveError(false);
      }
    }
  }

  function formatInput(dataValue, formatter, dataName) {
    if (dataName === 'marketAdjustment') return formatter ? formatter(dataValue.trim()) : dataValue.trim();
    return dataValue && (formatter ? formatter(dataValue.trim()) : dataValue.trim());
  }

  function isFieldDisabled(dataName, rowIndex, rootArr) {
    return (
      (dataName === 'dividendRate' && parseInt(rootArr[rowIndex - 1].value, 10) === 0) ||
      (dataName === 'dividendCompounding' && parseInt(rootArr[rowIndex - 2].value, 10) === 0) ||
      (dataName === 'participationCap' && rootArr[rowIndex - 1].value === 'non-participating') ||
      (dataName === 'interestCompounding' && rootArr[rowIndex - 1].value === '') ||
      (dataName === 'maturityDate' && rootArr[rowIndex - 2].value === '')
    );
  }

  function getRowTotal(dataName, formatter) {
    let total = 0;
    tableData.forEach((row) => {
      const num = onlyNums(row[dataName].value);
      if (num !== '') total += parseFloat(num);
    });
    return formatter(total);
  }

  function calculateInput(dataName, cellIndex, rootArr) {
    if (rootArr[cellIndex - 2].value) {
      if (dataName === 'ntmOptions') {
        const availableValue = parseFloat(onlyNums(rootArr[cellIndex - 2].value));
        const ntmOptionsPercentValue = parseFloat(onlyNums(rootArr[cellIndex - 1].value));
        // ntm Options = Available / NTM Options %
        return (availableValue || availableValue === 0) ? `${(Math.floor(availableValue * (ntmOptionsPercentValue / 100))).toLocaleString('en-US')}` : '';
      }
      const earlyPercentageValue = parseFloat(rootArr[cellIndex - 1].value);
      // Late Percentage = 100 - Early Percentage
      return (earlyPercentageValue || earlyPercentageValue === 0) ? `${100 - earlyPercentageValue}%` : '%';
    }
    return null;
  }

  function getOptionOrWarrantMenuItems(rowIndex) {
    if (isOptionTable) {
      if (tableData[rowIndex].options.type === 'Preferred') {
        return preferredDropdownValues;
      }
      if (tableData[rowIndex].options.type === 'Common') {
        return commonDropdownValues;
      }
    }
    if (isWarrantTable) {
      if (tableData[rowIndex].warrants.type === 'Preferred') {
        return preferredDropdownValues;
      }
      if (tableData[rowIndex].warrants.type === 'Common') {
        return commonDropdownValues;
      }
    }
    return [];
  }

  function getOptionOrWarrantValue(dataName, rowIndex) {
    if (getOptionOrWarrantMenuItems(rowIndex).length > 0) {
      if (dataName === 'options') {
        if (tableData[rowIndex].options.type === 'Preferred') {
          if (preferredDropdownValues.includes(tableData[rowIndex].options.value)) {
            return tableData[rowIndex].options.value;
          }
        }
        if (tableData[rowIndex].options.type === 'Common') {
          if (commonDropdownValues.includes(tableData[rowIndex].options.value)) {
            return tableData[rowIndex].options.value;
          }
        }
      }
      if (dataName === 'warrants') {
        if (tableData[rowIndex].warrants.type === 'Preferred') {
          if (preferredDropdownValues.includes(tableData[rowIndex].warrants.value)) {
            return tableData[rowIndex].warrants.value;
          }
        }
        if (tableData[rowIndex].warrants.type === 'Common') {
          if (commonDropdownValues.includes(tableData[rowIndex].warrants.value)) {
            return tableData[rowIndex].warrants.value;
          }
        }
      }
    }
    return '';
  }

  function handleOptionOrWarrantTypeChange(dataName, dataValue, rowIndex) {
    const newData = copy(tableData);
    if (dataName === 'options') {
      newData[rowIndex].options.type = dataValue;
      if (dataValue === 'Preferred') {
        newData[rowIndex].options.value = preferredDropdownValues[0] || '';
      } else if (dataValue === 'Common') {
        newData[rowIndex].options.value = commonDropdownValues[0] || '';
      } else {
        newData[rowIndex].options.value = '';
      }
    }
    if (dataName === 'warrants') {
      newData[rowIndex].warrants.type = dataValue;
      if (dataValue === 'Preferred') {
        newData[rowIndex].warrants.value = preferredDropdownValues[0] || '';
      } else if (dataValue === 'Common') {
        newData[rowIndex].warrants.value = commonDropdownValues[0] || '';
      } else {
        newData[rowIndex].warrants.value = '';
      }
    }
    if (isOptionTable && optionsTableHaveError[rowIndex]?.options) {
      if (newData[rowIndex].options.value) {
        optionsTableHaveError[rowIndex].options = false;
        setOptionsTableHaveError(optionsTableHaveError);
      }
    } else if (isWarrantTable && warrantsTableHaveError[rowIndex]?.warrants) {
      if (newData[rowIndex].warrants.value) {
        warrantsTableHaveError[rowIndex].warrants = false;
        setWarrantsTableHaveError(warrantsTableHaveError);
      }
    }
    setChangeHasBeenMade(true);
    setTableData(newData);
  }

  return (
    <main className={`CalcTable ${isHalfWidth ? 'half-width' : ''}`}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            {extraHeadRowCells && (
              <TableRow>
                {extraHeadRowCells.map((cell) => {
                  return (
                    <TableCell
                      key={cell.headTitle}
                      sx={{ width: cell.width }}
                    >
                      {cell.headTitle}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            <TableRow>
              {tableSchema.map((data) => {
                return (
                  <TableCell
                    key={data.headTitle}
                    sx={{ width: data.cellWidth }}
                  >
                    {data.headTitle}
                  </TableCell>
                );
              })}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {showDuplicateError && (isPreferredTable || isSafeTable || isCommonTable) && (
              <TableRow className="duplicate-error-row">
                <TableCell>
                  <img src={alertIconFilled} alt="error, duplicate preferred values" />
                  {isCommonTable ? (
                    <span>
                      Duplicate Common class name. Make unique by changing one of the class name.
                    </span>
                  ) : (
                    <span>
                      {`Duplicate ${isPreferredTable ? 'Preferred' : 'Safe/Convertible Note'} class name and date.`}
                      {` Make unique by changing ${isPreferredTable ? 'Preferred' : 'Safe/Convertible Note'} class name, date or both.`}
                    </span>
                  )}
                </TableCell>
              </TableRow>
            )}
            {showCalculateError && (isPreferredTable || isSafeTable) && (
              <TableRow className="duplicate-error-row">
                <TableCell>
                  <img src={alertIconFilled} alt="error, duplicate preferred values" />
                  <span>
                    Please add value(s) for required field(s).
                  </span>
                </TableCell>
              </TableRow>
            )}
            {showDuplicateError && (isOptionTable || isWarrantTable) && (
              <TableRow className="duplicate-error-row">
                <TableCell>
                  <img src={alertIconFilled} alt="error, duplicate preferred values" />
                  <span>
                    {`Duplicate ${isOptionTable ? 'Option' : 'Warrant'} class name and date.`}
                    {` Make unique by changing the ${isOptionTable ? 'Option' : 'Warrant'} class or subclass name.`}
                  </span>
                </TableCell>
              </TableRow>
            )}
            {commonTableHaveError?.some((row) => Object.keys(row).some((key) => row[key])) && (
              <TableRow className="duplicate-error-row">
                <TableCell>
                  <img src={alertIconFilled} alt="error" />
                  <span>
                    Missing Value(s). Please fill in the required field(s) below.
                  </span>
                </TableCell>
              </TableRow>
            )}
            {optionsTableHaveError?.some((row) => Object.keys(row).some((key) => row[key])) && (
              <TableRow className="duplicate-error-row">
                <TableCell>
                  <img src={alertIconFilled} alt="error" />
                  {optionsTableHaveError?.some((row) => row.options) ? (
                    <span>
                      Missing Option(s) types. Please select Option(s) type(s) below.
                    </span>
                  ) : (
                    <span>
                      Missing Value(s). Please fill in the required field(s) below.
                    </span>
                  )}
                </TableCell>
              </TableRow>
            )}
            {warrantsTableHaveError?.some((row) => Object.keys(row).some((key) => row[key])) && (
              <TableRow className="duplicate-error-row">
                <TableCell>
                  <img src={alertIconFilled} alt="error" />
                  {warrantsTableHaveError?.some((row) => row.warrants) ? (
                    <span>
                      Missing Warrant(s) types. Please select Warrant(s) type(s) below.
                    </span>
                  ) : (
                    <span>
                      Missing Value(s). Please fill in the required field(s) below.
                    </span>
                  )}
                </TableCell>
              </TableRow>
            )}
            {remainingOptionPoolTableHaveError && (
              <TableRow className="duplicate-error-row">
                <TableCell>
                  <img src={alertIconFilled} alt="error" />
                  <span>
                    Please make sure that the &apos;Remaining options pool&apos; input field is not blank.
                    Insert a 0 if there are no options available or an options pool has not been allocated.
                  </span>
                </TableCell>
              </TableRow>
            )}
            {currentTableData.map((row, rowIndex) => (
              <TableRow
                className={`${rowDeleteBackgroundIndex === rowIndex ? 'delete-background' : ''}`}
                key={row[Object.values(row)[0].name].rowID}
              >
                {Object.values(row).map((data, index, rootArr) => {
                  if (index === 0 && data.isLateEquity) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        sx={{ width: data.cellWidth }}
                        className="late-equity"
                      >
                        {editEquityValue ? (
                          <TextField
                            autoFocus
                            autoComplete="off"
                            name={data.name}
                            value={data.value}
                            onChange={(e) => {
                              handleChange(data.name, e.target.value, rowIndex);
                            }}
                            onBlur={(e) => {
                              handleOnBlurChange(
                                data.name,
                                formatInput(e.target.value, data.formatter),
                                rowIndex,
                              );
                            }}
                          />
                        ) : (
                          <Button
                            className="set-equity-val-btn"
                            onClick={() => { setEditEquityValue(true); }}
                          >
                            Set value
                          </Button>
                        )}
                      </TableCell>
                    );
                  }
                  if (index === 0 && canEditFirstColumn) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        sx={{ width: data.cellWidth }}
                        className={`first-cell-edit ${(indexesWithErrors?.includes(rowIndex) && showDuplicateError) &&
                          (data.name === 'preferred' || data.name === 'safeConvertible' || data.name === 'common') ?
                          'has-error' : ''}`}
                        onMouseEnter={() => setEditIconIsVisibleIndex(rowIndex)}
                        onMouseLeave={() => setEditIconIsVisibleIndex(-1)}
                      >
                        {editFirstColumnIndex === rowIndex || data.value === '' ? (
                          <TextField
                            autoComplete="off"
                            autoFocus={data.value !== '' && true}
                            name={data.name}
                            value={data.value}
                            onChange={(e) => {
                              handleChange(data.name, e.target.value, rowIndex, null, null, 'firstColumn');
                            }}
                            onFocus={() => setEditFirstColumnIndex(rowIndex)}
                            onBlur={(e) => {
                              handleOnBlurChange(
                                data.name,
                                formatInput(e.target.value, data.formatter),
                                rowIndex,
                              );
                              setPossibleDuplicateModified(true);
                              setEditFirstColumnIndex(-1);
                            }}
                          />
                        ) : (
                          <>
                            {data.value}
                            <IconButton
                              className={`edit-icon-btn ${editIconIsVisibleIndex === rowIndex ? 'visible' : 'hidden'}`}
                              onClick={() => {
                                if (editFirstColumnIndex !== rowIndex) {
                                  setEditFirstColumnIndex(rowIndex);
                                }
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    );
                  }
                  if (index === 0 && firstColumnIsDropdown) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        className="first-cell-dropdown"
                        sx={{ width: data.cellWidth }}
                      >
                        {(data.name === 'options' || data.name === 'warrants') ? (
                          <>
                            <Select
                              open={dropdownTypeIsOpen && dropdownIndexToOpen === rowIndex}
                              className={`${(showDuplicateError && indexesWithErrors.includes(rowIndex)) ||
                                optionsTableHaveError?.[rowIndex]?.options || warrantsTableHaveError?.[rowIndex]?.warrants ? 'has-error' : ''}`}
                              onOpen={() => {
                                setDropdownTypeIsOpen(true);
                                setDropdownIndexToOpen(rowIndex);
                              }}
                              onClose={() => {
                                setDropdownTypeIsOpen(false);
                                setDropdownIndexToOpen(-1);
                              }}
                              displayEmpty
                              value={(data.name === 'options' ? tableData[rowIndex].options.type : tableData[rowIndex].warrants.type) || ''}
                              onChange={(e) => {
                                handleOptionOrWarrantTypeChange(
                                  data.name,
                                  e.target.value,
                                  rowIndex,
                                );
                                setPossibleDuplicateModified(true);
                              }}
                              IconComponent={ExpandMoreIcon}
                              MenuProps={{ disableScrollLock: true, classes: { paper: 'option-warrant-dropdown' } }}
                            >
                              <MenuItem value="" sx={{ display: 'none' }}>
                                Choose Option Type
                              </MenuItem>
                              <MenuItem value="Preferred">
                                Preferred
                              </MenuItem>
                              <MenuItem value="Common">
                                Common
                              </MenuItem>
                            </Select>
                            <Select
                              open={dropdownFirstColumnIsOpen && dropdownIndexToOpen === rowIndex}
                              className={`${getOptionOrWarrantMenuItems(rowIndex).length === 0 ?
                                'non-editable' : ''} ${showDuplicateError && indexesWithErrors.includes(rowIndex) ? 'has-error' : ''}`}
                              onOpen={() => {
                                setDropdownFirstColumnIsOpen(true);
                                setDropdownIndexToOpen(rowIndex);
                              }}
                              onClose={() => {
                                setDropdownFirstColumnIsOpen(false);
                                setDropdownIndexToOpen(-1);
                              }}
                              name={data.name}
                              value={getOptionOrWarrantValue(data.name, rowIndex)}
                              onChange={(e) => {
                                const newData = copy(tableData);
                                if (data.name === 'options') newData[rowIndex].options.value = e.target.value;
                                if (data.name === 'warrants') newData[rowIndex].warrants.value = e.target.value;
                                setTableData(newData);
                                setPossibleDuplicateModified(true);
                              }}
                              IconComponent={ExpandMoreIcon}
                              disabled={getOptionOrWarrantMenuItems(rowIndex).length === 0}
                              MenuProps={{ disableScrollLock: true, classes: { paper: 'option-warrant-dropdown' } }}
                            >
                              {getOptionOrWarrantMenuItems(rowIndex).map((item) => (
                                <MenuItem
                                  key={item}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        ) : (
                          <Select
                            open={dropdownFirstColumnIsOpen && dropdownIndexToOpen === rowIndex}
                            onOpen={() => {
                              setDropdownFirstColumnIsOpen(true);
                              setDropdownIndexToOpen(rowIndex);
                            }}
                            onClose={() => {
                              setDropdownFirstColumnIsOpen(false);
                              setDropdownIndexToOpen(-1);
                            }}
                            name={data.name}
                            value={data.value}
                            onChange={(e) => {
                              handleOnBlurChange(data.name, e.target.value, rowIndex);
                            }}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{ disableScrollLock: true, disablePortal: true }}
                          >
                            {data.menuItems.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </TableCell>
                    );
                  }
                  if (data.isDate) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        sx={{ width: data.cellWidth }}
                        className={
                          !isOpmTable && !isCapTable && indexesWithErrors.includes(rowIndex) && showDuplicateError ?
                            (rootArr[index - 1].name === 'preferred' || rootArr[index - 1].name === 'safeConvertible') ?
                              'has-error' : `${isFieldDisabled(data.name, index, rootArr ? 'non-editable' : '')}` :
                            `${!isOpmTable && !isCapTable && valueIndexWithErrors.includes(rowIndex) &&
                              Object.keys(preferredInputsHaveError ? preferredInputsHaveError[rowIndex] : safeInputsHaveError[rowIndex]).includes(data.name) ?
                              'has-error' : ''
                            }
                          ${isFieldDisabled(data.name, index, rootArr) ? 'non-editable' : ''}`
                        }
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            clearable
                            name={data.name}
                            className="date-picker"
                            inputFormat="MM/DD/YYYY"
                            value={data.value}
                            disabled={!!isFieldDisabled(data.name, index, rootArr)}
                            onChange={(newValue) => {
                              handleOnBlurChange(data.name, newValue, rowIndex);
                              setPossibleDuplicateModified(true);
                            }}
                            slotProps={{ textField: { variant: 'outlined' } }}
                          />
                        </LocalizationProvider>
                        <ClearIcon
                          className={`date-clear-icon ${data.value ? 'active' : 'inactive'}`}
                          onClick={() => {
                            handleOnBlurChange(data.name, null, rowIndex);
                            setPossibleDuplicateModified(true);
                          }}
                        />
                      </TableCell>
                    );
                  }
                  if (data.isSelect) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        disabled={isFieldDisabled(data.name, index, rootArr)}
                        sx={{ width: data.cellWidth }}
                        className={
                          `${!isOpmTable && !isCapTable && valueIndexWithErrors.includes(rowIndex) &&
                            Object.keys(preferredInputsHaveError ?
                              preferredInputsHaveError[rowIndex] :
                              safeInputsHaveError[rowIndex]).includes(data.name) ?
                            'has-error' : ''
                          }`
                        }
                      >
                        <TextField
                          name={data.name}
                          className="text-field-select"
                          disabled={!!isFieldDisabled(data.name, index, rootArr)}
                          defaultValue={isFieldDisabled(data.name, index, rootArr) && 'N/A'}
                          value={data.value !== null ? data.value : 'N/A'}
                          onChange={(e) => {
                            handleOnBlurChange(data.name, e.target.value, rowIndex);
                          }}
                          SelectProps={{
                            IconComponent: ExpandMoreIcon,
                            MenuProps: { disablePortal: true },
                          }}
                          select
                        >
                          {isFieldDisabled(data.name, index, rootArr) ? (
                            <MenuItem value="N/A" disabled>
                              N/A
                            </MenuItem>
                          ) : data.menuItems.map((item) => (
                            <MenuItem key={item.label} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                    );
                  }
                  if (data.nonEditable) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        sx={{ width: data.cellWidth }}
                        className="non-editable"
                      >
                        {data.name === 'ntmOptions' || data.name === 'latePercentage' ? calculateInput(data.name, index, rootArr) : data.value}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={data.headTitle}
                      sx={{ width: data.cellWidth }}
                      className={
                        `${(!isOpmTable && !isCapTable && valueIndexWithErrors.includes(rowIndex) &&
                          Object.keys(preferredInputsHaveError ? preferredInputsHaveError[rowIndex] :
                            safeInputsHaveError[rowIndex]).includes(data.name)) ||
                          (commonTableHaveError?.[rowIndex]?.[data.name] || optionsTableHaveError?.[rowIndex]?.[data.name] ||
                            warrantsTableHaveError?.[rowIndex]?.[data.name]) ?
                          'has-error' : ''}` +
                        `${isFieldDisabled(data.name, index, rootArr) ? ' non-editable' : ''}`
                      }
                    >
                      <TextField
                        autoComplete="off"
                        className={
                          `${(!isOpmTable && !isCapTable && valueIndexWithErrors.includes(rowIndex) &&
                            Object.keys(preferredInputsHaveError ? preferredInputsHaveError[rowIndex] :
                              safeInputsHaveError[rowIndex]).includes(data.name)) ||
                            (commonTableHaveError?.[rowIndex]?.[data.name] || optionsTableHaveError?.[rowIndex]?.[data.name] ||
                              warrantsTableHaveError?.[rowIndex]?.[data.name]) ?
                            'has-error' : ''}`
                        }
                        name={data.name}
                        placeholder={isFieldDisabled(data.name, index, rootArr) ? 'N/A' : ''}
                        value={data.value !== null ? data.value : ''}
                        onChange={(e) => {
                          handleChange(
                            data.name,
                            e.target.value,
                            rowIndex,
                            data.charLimit,
                            data.valLimit,
                          );
                        }}
                        onBlur={(e) => {
                          handleOnBlurChange(
                            data.name,
                            formatInput(e.target.value, data.formatter, data.name),
                            rowIndex,
                          );
                          setPossibleDuplicateModified(true);
                        }}
                        disabled={!!isFieldDisabled(data.name, index, rootArr)}
                      />
                    </TableCell>
                  );
                })}
                {canAddOrDeleteRow && (
                  <TableCell
                    className="delete-cell"
                    sx={{ width: '60px' }}
                    onClick={() => {
                      setRowIndexToDelete(rowIndex);
                      setShowDeleteAlert(!showDeleteAlert);
                      holdDeletedRowInState(rowIndex);
                    }}
                    onMouseEnter={() => setRowDeleteBackgroundIndex(rowIndex)}
                    onMouseLeave={() => setRowDeleteBackgroundIndex(-1)}
                  >
                    <DeleteOutlinedIcon />
                  </TableCell>
                )}

              </TableRow>
            ))}
            {hasTotalRow && (
              <TableRow
                className="total-row"
              >
                {tableSchema.map((data, rowIndex) => {
                  if (rowIndex === 0) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        className="total-first-cell"
                        sx={{ width: data.cellWidth }}
                      >
                        Total
                      </TableCell>
                    );
                  }
                  if (data.hasTotal) {
                    return (
                      <TableCell
                        key={data.headTitle}
                        sx={{ width: data.cellWidth }}
                      >
                        {getRowTotal(data.name, data.formatter)}
                      </TableCell>
                    );
                  }
                  return <TableCell key={data.headTitle} sx={{ width: data.cellWidth }} />;
                })}
              </TableRow>
            )}
            {canAddOrDeleteRow && (
              <TableRow>
                <TableCell
                  sx={{ width: '180px' }}
                  className="add-new-field-cell"
                  onClick={() => {
                    addNewRow();
                  }}
                >
                  <AddIcon />
                  Add new
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showDeleteAlert}
        onClose={() => {
          setShowDeleteAlert(false);
          if (!tableData.length) addNewRow();
        }}
        TransitionComponent={SlideTransition}
        message={(
          <>
            <span>Row Deleted</span>
            <Button
              variant="outlined"
              onClick={() => {
                addRemovedRowInState();
              }}
            >
              Undo
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setShowDeleteAlert(!showDeleteAlert);
              }}
            >
              Dismiss
            </Button>
          </>
        )}
        autoHideDuration={5000}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      />
    </main>
  );
}

CalcTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  setTableData: PropTypes.func.isRequired,
  tableSchema: PropTypes.array.isRequired,
  createTable: PropTypes.func.isRequired,
  canEditFirstColumn: PropTypes.bool,
  firstColumnIsDropdown: PropTypes.bool,
  canAddOrDeleteRow: PropTypes.bool,
  isHalfWidth: PropTypes.bool,
  hasTotalRow: PropTypes.bool,
  extraHeadRowCells: PropTypes.array,
  isWarrantTable: PropTypes.bool,
  isOptionTable: PropTypes.bool,
  isCapTable: PropTypes.bool,
  preferredData: PropTypes.array,
  commonData: PropTypes.array,
  isPreferredTable: PropTypes.bool,
  isSafeTable: PropTypes.bool,
  setCalcTableHasError: PropTypes.func,
  isCommonTable: PropTypes.bool,
  isOpmTable: PropTypes.bool,
  preferredInputsHaveError: PropTypes.array,
  commonTableHaveError: PropTypes.array,
  optionsTableHaveError: PropTypes.array,
  warrantsTableHaveError: PropTypes.array,
  remainingOptionPoolTableHaveError: PropTypes.bool,
  setCommonTableHaveError: PropTypes.func,
  setOptionsTableHaveError: PropTypes.func,
  setWarrantsTableHaveError: PropTypes.func,
  setRemainingOptionPoolTableHaveError: PropTypes.func,
  safeInputsHaveError: PropTypes.array,
  setSafeInputsHaveError: PropTypes.func,
  setPreferredInputsHaveError: PropTypes.func,
  setChangeHasBeenMade: PropTypes.func,
  isRemainingOptionsPoolTable: PropTypes.bool,
};
