import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import { ReactComponent as NewPriceLoadingSpinner } from '../../images/loading-spinner.svg';
import ValuationSlider from './ValuationSlider';
import RightInfoBlock from './RightInfoBlock';
import AuditRiskSlider2 from './AuditRiskSlider2';

import useFetch from '../../hooks/useFetch';

import { commaEvery3rdChar } from '../../utils';

import './index.scss';

export default function Sandbox({ userData }) {
  const [showDataLoadingOverlay, setShowDataLoadingOverlay] = useState(false);
  const [pricePerShare, setPricePerShare] = useState(0.25);
  const [concludedPercentOfPreferred, setConcludedPercentOfPreferred] = useState(25);
  const [averageOption, setAverageOption] = useState(0.07);
  const [medianMarketPercent, setMedianMarketPercent] = useState(27);
  const [lastRoundIssued, setLastRoundIssued] = useState(1.00);
  const [prior409AValue, setPrior409AValue] = useState('');
  const [sliderHasBeenChanged, setSliderHasBeenChanged] = useState(false);

  // Audit Risk
  const [farLeftVal, setFarLeftVal] = useState('$1.12');
  const [midLeftVal, setMidLeftVal] = useState('$1.17');
  const [centerVal, setCenterVal] = useState('$1.18');
  const [midRightVal, setMidRightVal] = useState('$1.19');
  const [farRightVal, setFarRightVal] = useState('$1.22');

  // Sliders - Term
  const [termMin, setTermMin] = useState(0.5);
  const [termMax, setTermMax] = useState(5);
  const [termIncrement, setTermIncrement] = useState(0.5);
  const [termChosenVal, setTermChosenVal] = useState(4);

  // Sliders - NTM
  const [ntmMin, setNtmMin] = useState(0);
  const [ntmMax, setNtmMax] = useState(100);
  const [ntmIncrement, setNtmIncrement] = useState(10);
  const [ntmChosenVal, setNtmChosenVal] = useState(100);

  // Sliders - Volatility
  const [volatilityMin, setVolatilityMin] = useState(20);
  const [volatilityMax, setVolatilityMax] = useState(80);
  const [volatilityIncrement, setVolatilityIncrement] = useState(10);
  const [volatilityChosenVal, setVolatilityChosenVal] = useState(80);

  // Sliders - DLOM
  const [dlomMin, setDlomMin] = useState(35);
  const [dlomMax, setDlomMax] = useState(45);
  const [dlomIncrement, setDlomIncrement] = useState(5);
  const [dlomChosenVal, setDlomChosenVal] = useState(45);

  // Non-slider props
  const [auditRiskLabel, setAuditRiskLabel] = useState('low');
  const [averageIndustryVolatility, setAverageIndustryVolatility] = useState('27');
  const [numDefensible, setNumDefensible] = useState('2175');

  // Optimize Dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentOptimizeSelection, setCurrentOptimizeSelection] = useState('');

  const [sandboxIsFinalized, setSandboxIsFinalized] = useState(false);

  // No Outcome Modal
  const [noOutcome, setNoOutcome] = useState(false);

  const [, sandboxDataRequest] = useFetch();
  const [, sandboxSelectionRequest] = useFetch();
  const [, optimizedValuesRequest] = useFetch();

  const [{ loading: checkingSandboxStatus }, clientStatusRequest] = useFetch();


  const { companyId, transactionId } = userData;


  const numTotalOutcomes = commaEvery3rdChar('7205');

  function doSetOfAllSandboxValues(sandboxData) {
    const {
      sliders, auditRisk, cards, perSharePrice, numDefensibleOutcomes,
    } = sandboxData;

    if (numDefensibleOutcomes) setNumDefensible(numDefensibleOutcomes);
    if (parseFloat(numDefensibleOutcomes) === 0) setNoOutcome(true);

    // #1: Audit Risk

    const {
      boundary1, boundary2, boundary4, boundary5, center,
    } = auditRisk;

    const twoDecimalPrice = (num) => parseFloat(num).toFixed(2);
    const formatBound = (num) => `$${num}`;

    const farLeft = twoDecimalPrice(boundary1);
    const midLeft = twoDecimalPrice(boundary2);
    const centerCalced = twoDecimalPrice(center);
    const midRight = twoDecimalPrice(boundary4);
    const farRight = twoDecimalPrice(boundary5);

    setAuditRiskLabel(
      (
        parseFloat(perSharePrice) >= parseFloat(midLeft) &&
        parseFloat(perSharePrice) <= parseFloat(midRight)
      ) ? 'low' : 'medium',
    );

    setFarLeftVal(formatBound(farLeft));
    setMidLeftVal(formatBound(midLeft));
    setMidRightVal(formatBound(midRight));
    setFarRightVal(formatBound(farRight));
    setCenterVal(formatBound(centerCalced));

    // #2: Sliders
    const {
      dlom, ntm, term, volatility,
    } = sliders;

    function doSetNew(currentVal, newVal, setter) {
      if (currentVal !== parseFloat(newVal)) {
        setter(parseFloat(newVal));
      }
    }

    // #2: Sliders - Term
    const {
      chosen: newChosenTerm, increment: newTermIncrement, min: newTermMin, max: newTermMax,
    } = term;

    doSetNew(termMin, newTermMin, setTermMin);
    doSetNew(termMax, newTermMax, setTermMax);
    doSetNew(termIncrement, newTermIncrement, setTermIncrement);
    doSetNew(termChosenVal, newChosenTerm, setTermChosenVal);

    // #2: Sliders - NTM
    const {
      chosen: newChosenNtm, increment: newNtmIncrement, min: newNtmMin, max: newNtmMax,
    } = ntm;

    doSetNew(ntmMin, newNtmMin, setNtmMin);
    doSetNew(ntmMax, newNtmMax, setNtmMax);
    doSetNew(ntmIncrement, newNtmIncrement, setNtmIncrement);
    doSetNew(ntmChosenVal, newChosenNtm, setNtmChosenVal);

    // #2: Sliders - Volatility
    const {
      chosen: newChosenVolatility,
      increment: newVolatilityIncrement,
      min: newVolatilityMin,
      max: newVolatilityMax,
    } = volatility;

    doSetNew(volatilityMin, newVolatilityMin, setVolatilityMin);
    doSetNew(volatilityMax, newVolatilityMax, setVolatilityMax);
    doSetNew(volatilityIncrement, newVolatilityIncrement, setVolatilityIncrement);
    doSetNew(volatilityChosenVal, newChosenVolatility, setVolatilityChosenVal);

    // #2: Sliders - DLOM
    const {
      chosen: newChosenDlom, increment: newDlomIncrement, min: newDlomMin, max: newDlomMax,
    } = dlom;

    doSetNew(dlomMin, newDlomMin, setDlomMin);
    doSetNew(dlomMax, newDlomMax, setDlomMax);
    doSetNew(dlomIncrement, newDlomIncrement, setDlomIncrement);
    doSetNew(dlomChosenVal, newChosenDlom, setDlomChosenVal);

    // #3 - Cards
    setPrior409AValue(cards.prior409A);
    setLastRoundIssued(cards.latestRoundIssued);

    setMedianMarketPercent(parseFloat(cards.medianMarketPercOfPreferred).toFixed(0).toString());
    setAverageOption(parseFloat(cards.averageOptionExercise).toFixed(2).toString());
    setConcludedPercentOfPreferred(parseFloat(cards.concludedPercentOfPreferred).toFixed(2).toString());
    setAverageIndustryVolatility(parseFloat(cards.averageIndustryVolatility).toFixed(0).toString());

    // #4: Price Per Share
    const pps = parseFloat(perSharePrice.replaceAll('$', '')).toFixed(2);
    if (Number.isNaN(pps) || (pps === 'NaN') || parseFloat(numDefensibleOutcomes) === 0) {
      setPricePerShare('N/A');
    } else {
      setPricePerShare(pps);
    }
    setShowDataLoadingOverlay(false);
  }

  function getSandboxDefaults() {
    setShowDataLoadingOverlay(true);

    clientStatusRequest({
      url: '/homepage/client',
      urlIds: [companyId],
      onSuccess: (clientHomepageResponse) => {
        const { userState } = clientHomepageResponse;
        const finalizedSandboxStates = ['sandbox-finalized', 'report-ready'];
        setSandboxIsFinalized(finalizedSandboxStates.includes(userState));
        sandboxDataRequest({
          url: '/sandbox/defaults',
          urlIds: [companyId, transactionId],
          onSuccess: (sandboxData) => doSetOfAllSandboxValues(sandboxData),
        });
      },
    });
  }

  useEffect(() => getSandboxDefaults(), []);

  function getChangedSandboxValues() {
    setShowDataLoadingOverlay(true);
    sandboxSelectionRequest({
      url: '/sandbox/selection',
      urlIds: [companyId, transactionId, ntmChosenVal, termChosenVal, volatilityChosenVal, dlomChosenVal],
      onSuccess: (changedSandboxValues) => doSetOfAllSandboxValues(changedSandboxValues),
      onFinally: () => setSliderHasBeenChanged(false),
    });
  }

  useEffect(() => { if (sliderHasBeenChanged) getChangedSandboxValues(); }, [sliderHasBeenChanged]);

  function grabOptimized(optimizationType) {
    setShowDataLoadingOverlay(true);
    optimizedValuesRequest({
      url: '/sandbox/optimized',
      urlIds: [companyId, transactionId, optimizationType],
      onSuccess: (optimizedSandboxValues) => doSetOfAllSandboxValues(optimizedSandboxValues),
    });
  }

  return (
    <div className="Sandbox">
      <div className="valuation-container">
        <div className="headline-container">
          <h2>409A Valuation Sandbox</h2>
          {!checkingSandboxStatus && (
            <>
              <Button
                variant="contained"
                className="secondary-top-btn printer-btn top-btn-instance"
                onClick={() => {
                  window.print();
                }}
              >
                <PrintOutlinedIcon />
                Print Exhibit
              </Button>
              {!sandboxIsFinalized && (
                <>
                  <Button
                    variant="contained"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    className="secondary-top-btn optimize-btn top-btn-instance"
                  >
                    <AutoGraphIcon />
                    Optimize
                  </Button>
                  <Menu
                    autoFocus={false}
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                    className="optimize-dropdown"
                    disableScrollLock
                  >
                    {['Company Optimize', 'Employee Optimize'].map((option, index) => (
                      <MenuItem
                        key={option}
                        className={option === currentOptimizeSelection ? 'active' : ''}
                        onClick={() => {
                          if (index === 0) setCurrentOptimizeSelection('Company Optimize');
                          else setCurrentOptimizeSelection('Employee Optimize');
                          grabOptimized(index === 0 ? 'company' : 'employee');
                          setAnchorEl(null);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                  <Button
                    variant="contained"
                    className="secondary-top-btn refresh-btn top-btn-instance"
                    disabled={showDataLoadingOverlay}
                    onClick={getSandboxDefaults}
                  >
                    <RefreshOutlinedIcon />
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    className="top-btn-instance finalize"
                    disabled
                  >
                    Finalize
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        <div className="sandbox-scrolling-wrapper">
          <div className="top-explanation">
            <p>
              These are your most defensible outcomes based on the assumptions you provided
              initio. Move the sliders below and see the impact in real-time. Don&apos;t worry, if
              you decide you want to reset all values back to our recommended defaults, simply
              click &lsquo;reset&rsquo;.
            </p>
          </div>
          <div className="info-container">
            <div className="info-container-left-col">
              <div>
                <div className="value-sliders-wrapper">
                  <div className="value-sliders-headline-wrapper">
                    <h2>Concluded value</h2>
                    <div className={`per-share-price-wrapper ${auditRiskLabel}-risk`}>
                      <h2>
                        {pricePerShare !== 'N/A' && <AttachMoneyIcon />}
                        {pricePerShare}
                      </h2>
                      <span className="per-share-label">
                        {pricePerShare !== 'N/A' && 'per share'}
                      </span>
                    </div>
                  </div>
                  {showDataLoadingOverlay && (
                    <div className="updated-price-loading-overlay">
                      <NewPriceLoadingSpinner className="custom-loading-spinner" />
                      <span>Calculating...</span>
                    </div>
                  )}
                  <ValuationSlider
                    disableSlider={sandboxIsFinalized}
                    setSliderHasBeenChanged={setSliderHasBeenChanged}
                    setCurrentOptimizeSelection={setCurrentOptimizeSelection}
                    valueYears
                    label="Term"
                    defaultVal={termChosenVal}
                    parentVal={termChosenVal}
                    parentSetter={setTermChosenVal}
                    stepInterval={termIncrement}
                    min={termMin}
                    max={termMax}
                    toolTipContent="A term is the length of time until an expected exit event (M&A, IPO, etc.)"
                  />
                  <ValuationSlider
                    disableSlider={sandboxIsFinalized}
                    setSliderHasBeenChanged={setSliderHasBeenChanged}
                    setCurrentOptimizeSelection={setCurrentOptimizeSelection}
                    label="Volatility"
                    defaultVal={volatilityChosenVal}
                    parentVal={volatilityChosenVal}
                    parentSetter={setVolatilityChosenVal}
                    stepInterval={volatilityIncrement}
                    min={volatilityMin}
                    max={volatilityMax}
                    toolTipContent={"Volatility is a measure of uncertainty and risk related " +
                      "to the size of changes within a securities value. We've used current market " +
                      "data to set this slider's values. If you believe your company is likely to be more or less volatile, " +
                      "you can adjust this input here."}
                  />
                  <ValuationSlider
                    disableSlider={sandboxIsFinalized}
                    setSliderHasBeenChanged={setSliderHasBeenChanged}
                    setCurrentOptimizeSelection={setCurrentOptimizeSelection}
                    label="DLOM"
                    defaultVal={dlomChosenVal}
                    parentVal={dlomChosenVal}
                    parentSetter={setDlomChosenVal}
                    stepInterval={dlomIncrement}
                    min={dlomMin}
                    max={dlomMax}
                    toolTipContent={"The DLOM (Discount for Lack of Marketability) is used to capture " +
                      "the discount associated with not having a readily available market to sell privately " +
                      "held stock. A lower slider value will generate a greater discount."}
                  />
                  {ntmIncrement ? (
                    <ValuationSlider
                      disableSlider={sandboxIsFinalized}
                      setSliderHasBeenChanged={setSliderHasBeenChanged}
                      setCurrentOptimizeSelection={setCurrentOptimizeSelection}
                      label="NTM Options"
                      defaultVal={ntmChosenVal}
                      parentVal={ntmChosenVal}
                      parentSetter={setNtmChosenVal}
                      stepInterval={ntmIncrement}
                      min={ntmMin}
                      max={ntmMax}
                      toolTipContent={"NTM Options is the number of options that the Company " +
                        "expects to issue over the next twelve months. This input is calculated as a percentage " +
                        "of the remaining option pool as of the Valuation Date. " +
                        "If you expect your company to generate more or less revenue in the next twelve months, " +
                        "you can adjust this input here."}
                    />
                  ) : (
                    <div className="no-ntm">
                      <NotificationsOutlinedIcon />
                      <p>
                        <span>
                          NTM slider not shown -&nbsp;
                        </span>
                        to show slider &apos;Remaining option pool&apos;
                        on the &apos;409A basic inputs&apos;
                        tab must be greater than zero.
                      </p>
                    </div>
                  )}
                </div>
                <div className="audit-risk-info-and-slider">
                  <h3>
                    Audit risk:
                    <span style={{ color: /low/ig.test(auditRiskLabel) ? '#42A179' : '#D8AA07' }}>
                      {auditRiskLabel}
                    </span>
                  </h3>
                  <AuditRiskSlider2
                    farLeftPrice={farLeftVal}
                    midLeftPrice={midLeftVal}
                    midRightPrice={midRightVal}
                    farRightPrice={farRightVal}
                    modeVal={centerVal}
                    defensibleOutcomesPrice={`${pricePerShare}`}
                    numOfDefensibleOutcomes={commaEvery3rdChar(numDefensible)}
                    auditRiskLabel={auditRiskLabel}
                  />
                  <div className="iterations-bar">
                    <span className="iterations-text">
                      {numTotalOutcomes}
                      {' '}
                      total outcomes
                    </span>
                  </div>
                </div>
                <div className="outcomes-text-block">
                  <p className="outcomes-text">
                    All outcomes generated here are your most defensible outcomes based on
                    the assumptions you provided initio. And yet, sometimes the IRS chooses
                    companies to audit at random. If this happens to
                    you, rest assured that the only difference between a green and yellow outcome
                    is that the yellow may generate slightly more questions from auditors than
                    outcomes that fall in the green.
                  </p>
                </div>
                <div className="key-terms-block">
                  <h3 className="key-terms-title">Key terms</h3>
                  {
                    [
                      {
                        title: `${centerVal} median.`,
                        // eslint-disable-next-line max-len
                        paragraph: 'This is your company\'s most defensible outcome. It\'s the most frequently concluded price per share of all iterations calculated.',
                      },
                      {
                        title: `${numTotalOutcomes} total outcomes.`,
                        // eslint-disable-next-line max-len
                        paragraph: 'This is the total number of times initio\'s proprietary calculation engine iterated on your company\'s share price. It processed multiple methodologies, that our valuation experts reviewed and finalized.',
                      },
                      {
                        title: 'Defensible outcomes,',
                        // eslint-disable-next-line max-len
                        paragraph: 'this is the number of times the above selected share price was the outcome of an iteration. All outcomes in this sandbox are capable of being defended in case of an audit.',
                      },
                    ].map(({ title, paragraph }) => {
                      return (
                        <div key={paragraph.replaceAll(' ', '+-=!')} className="key-terms-entry">
                          <p className="key-terms-explanation">
                            <span className="key-term">
                              {title}
                            </span>
                            {' '}
                            {paragraph}
                          </p>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            <div className="info-container-right-col">
              <div className="right-col-wrapper">
                <h3>Insights</h3>
                <h4>Company data</h4>
                {
                  [
                    {
                      title: 'Concluded percent of preferred',
                      label: `${concludedPercentOfPreferred}%`,
                    },
                    {
                      title: 'Average option exercise price',
                      label: `$${averageOption}`,
                    },
                  ].map(({ title, label }) => (
                    <RightInfoBlock
                      title={title}
                      label={label}
                      key={title.replaceAll(' ', '-+-+-')}
                    />
                  ))
                }
                <hr />
                <h4>Market data</h4>
                {
                  [
                    {
                      title: 'Prior 409A value',
                      label: (prior409AValue.length ? `$${prior409AValue}` : 'N/A'),
                    },
                    {
                      title: 'Median Market % of preferred',
                      label: `${medianMarketPercent}%`,
                    },
                    {
                      title: 'Average industry volatility',
                      label: `${averageIndustryVolatility}%`,
                    },
                    {
                      title: 'Latest round issue price',
                      label: `$${lastRoundIssued}`,
                    },
                  ].map(({ title, label }) => (
                    <RightInfoBlock
                      title={title}
                      label={label}
                      key={title.replaceAll(' ', '-+-+-')}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={noOutcome}
        className="no-outcome-dialog"
        disableScrollLock
      >
        <div className="box-header">
          <ErrorOutlineIcon />
          <h4>No outcome available</h4>
        </div>
        <p>
          This selected combination of inputs is either indefensible or not within the set of calculated values.
          <br />
          <br />
          Please select a different combination to find an available price.
        </p>
        <Button
          className="got-it-btn"
          onClick={() => {
            setNoOutcome(false);
          }}
        >
          Got it
        </Button>
      </Dialog>
    </div>
  );
}

Sandbox.propTypes = {
  userData: PropTypes.object,
};
