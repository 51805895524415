import { useContext, useEffect } from 'react';

import LogRocket from 'logrocket';

import { AuthContext, UserDataContext } from '../contexts';

export default function useLogRocket() {
  const { userData } = useContext(UserDataContext);
  const { isAuthenticated } = useContext(AuthContext);

  const logRocketEnabled = process.env.REACT_APP_USE_LOG_ROCKET === 'true';

  useEffect(() => {
    if (!logRocketEnabled || !isAuthenticated) return;

    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID, {
      rootHostname: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
      mergeIframes: true,
    });

    const userEmail = userData.userAttributes.email;
    const userName = userEmail.split('@')[0].charAt(0).toUpperCase() + userEmail.split('@')[0].slice(1);

    LogRocket.identify(userData.userAttributes.userId, {
      name: userName,
      email: userEmail,
      companyName: 'initio',
    });
  }, [isAuthenticated]);
}
