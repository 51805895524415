import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import moment from 'moment';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import Tabs from './Tabs';
import Archive from './Archive';
import TransactionStatusBlock from './TransactionStatusBlock';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { copy } from '../../utils';

import { UserDataContext, NavigationContext, NavWidthContext, ShowArchiveContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';

import './index.scss';

export default function CalcForm409A() {
  const { userData } = useContext(UserDataContext);
  const { setNavWidth } = useContext(NavWidthContext);

  const { showArchive, setShowArchive } = useContext(ShowArchiveContext);

  const { to, from } = useContext(NavigationContext);

  const [tabToView, setTabToView] = useState(0);
  const [companyToViewData, setCompanyToViewData] = useState({});

  const [viewAccountInfo, setViewAccountInfo] = useState(false);

  const [allCompanies, setAllCompanies] = useState([]);

  const [notPaidCompanies, setNotPaidCompanies] = useState([]);
  const [toDoTransactions, setToDoTransactions] = useState([]);
  const [inProgressTransactions, setInProgressTransactions] = useState([]);
  const [completedTransactions, setCompletedTransactions] = useState([]);

  const [archivedCompanies, setArchivedCompanies] = useState([]);

  const [reloadTransactions, setReloadTransactions] = useState(false);

  const [calculatingTransactions, setCalculatingTransactions] = useState([]);

  // Pagination
  const [notPaidNumberOfPages, setNotPaidNumberOfPages] = useState(1);
  const [toDoNumberOfPages, setToDoNumberOfPages] = useState(1);
  const [inProgressNumberOfPages, setInProgressNumberOfPages] = useState(1);
  const [completedNumberOfPages, setCompletedNumberOfPages] = useState(1);

  const [notPaidCurrentPage, setNotPaidCurrentPage] = useState(1);
  const [toDoCurrentPage, setToDoCurrentPage] = useState(1);
  const [inProgressCurrentPage, setInProgressCurrentPage] = useState(1);
  const [completedCurrentPage, setCompletedCurrentPage] = useState(1);

  // Search/Sorting
  const [searchValueToDisplay, setSearchValueToDisplay] = useState('');
  const [searchCompanyInput, setSearchCompanyInput] = useState('');
  const [sortByValue, setSortByValue] = useState('');
  const [sortNotPaidByStatusValue, setSortNotPaidByStatusValue] = useState('all');
  const [sortToDoByStatusValue, setSortToDoByStatusValue] = useState('all');
  const [sortInProgressByStatusValue, setSortInProgressByStatusValue] = useState('all');
  const [sortCompletedByStatusValue, setSortCompletedByStatusValue] = useState('all');

  const [notPaidColumnIsHovered, setNotPaidColumnIsHovered] = useState(false);
  const [toDoColumnOnIsHovered, setToDoColumnOnIsHovered] = useState(false);
  const [inProgressColumnIsHovered, setInProgressColumnIsHovered] = useState(false);
  const [completedColumnIsHovered, setCompletedColumnIsHovered] = useState(false);

  const [dropdownBlockIsOpen, setDropdownBlockIsOpen] = useState(0);

  const [, updateAdminProgressRequest] = useFetch();
  const [, updatePriorityRequest] = useFetch();
  const [, transactionDataRequest] = useFetch();

  const numberOfNotPaidBlocksPerColumn = 8;
  const numberOfToDoBlocksPerColumn = 7;
  const numberOfInProgressBlocksPerColumn = 7;
  const numberOfCompletedBlocksPerColumn = 7;

  const adminEmail = userData.userAttributes.email;
  const currentAdminName = adminEmail.split('@')[0].charAt(0).toUpperCase() + adminEmail.split('@')[0].slice(1);

  // Handles browser navigation
  function handleNav(event) {
    if (!event) return;
    if (!event.search) {
      setCompanyToViewData({});
      setTabToView(0);
      if (!event.state?.showArchive) setShowArchive(false);
      return;
    }
    const navParams = new URLSearchParams(event.search);
    const paramTabToView = navParams.get('tabToView');
    const paramCompanyId = navParams.get('cId');
    const paramTransactionId = navParams.get('tId');
    if (paramCompanyId && allCompanies.length && !companyToViewData.companyData) {
      setCompanyToViewData(allCompanies.find((company) => company.companyData.companyId === paramCompanyId &&
        (!company.transactionData || company.transactionData?.transactionId === paramTransactionId)));
    }
    if (paramTabToView && parseInt(paramTabToView, 10) !== tabToView) setTabToView(parseInt(paramTabToView, 10));
  }

  useEffect(() => handleNav(to), [to]);
  useEffect(() => handleNav(from), [from]);

  const appWidth = useRef(null);

  const appWidthRef = useCallback((node) => {
    if (appWidth?.current) window.removeEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    if (node) {
      appWidth.current = node;
      window.addEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
      setNavWidth(appWidth?.current.scrollWidth);
    }
  }, []);

  function updateLocalProgress(transactionToUpdate, adminProgress, trackAdminName) {
    const allCompaniesCopy = copy(allCompanies);
    const indexToUpdate = allCompanies.findIndex((company) => {
      return company.transactionData?.transactionId === transactionToUpdate.transactionData?.transactionId;
    });
    if (trackAdminName) {
      allCompaniesCopy[indexToUpdate].transactionData.lastModified = moment().utc().local().format('YYYY-MM-DD HH:mm:ss');
      allCompaniesCopy[indexToUpdate].transactionData.lastModifiedBy = currentAdminName;
    }
    if (adminProgress === 'ready for calculation') {
      allCompaniesCopy[indexToUpdate].transactionData.isCalculating = 1;
      allCompaniesCopy[indexToUpdate].transactionData.calculationStartTime = moment().format('YYYY-MM-DD HH:mm:ss');
    } else if (adminProgress === 'calculation completed' || adminProgress === 'calculation failed') {
      allCompaniesCopy[indexToUpdate].transactionData.isCalculating = 0;
      if (adminProgress === 'calculation completed') {
        allCompaniesCopy[indexToUpdate].transactionData.hasBeenCalculated = 1;
      }
    }
    allCompaniesCopy[indexToUpdate].transactionData.adminProgress = adminProgress;
    allCompaniesCopy[indexToUpdate].transactionData.lastModified = moment().format('YYYY-MM-DD HH:mm:ss');
    allCompaniesCopy[indexToUpdate].transactionData.lastModifiedBy = currentAdminName;
    setAllCompanies(allCompaniesCopy);
  }

  function updateAdminProgress(transactionToUpdate, adminProgress, localOnly = false, trackAdminName = false) {
    if (localOnly) {
      updateLocalProgress(transactionToUpdate, adminProgress, trackAdminName);
      return;
    }

    const progressData = {
      companyId: transactionToUpdate.transactionData.companyId,
      transactionId: transactionToUpdate.transactionData.transactionId,
      adminProgress,
      adminName: currentAdminName,
    };

    updateAdminProgressRequest({
      url: '/transactions/update-admin-progress',
      method: 'post',
      body: progressData,
      bodyIds: ['userId'],
      onSuccess: () => updateLocalProgress(transactionToUpdate, adminProgress),
    });
  }

  function updatePriority(companyToUpdate, priority) {
    updatePriorityRequest({
      url: '/companies/update-admin-priority',
      method: 'post',
      body: { companyId: companyToUpdate.companyData.companyId, priority },
      bodyIds: ['userId'],
      onSuccess: () => {
        const allCompaniesCopy = copy(allCompanies);
        const indexToUpdate = allCompanies.findIndex((company) => {
          return company.companyData.companyId === companyToUpdate.companyData.companyId;
        });
        allCompaniesCopy[indexToUpdate].companyData.priority = priority;
        setAllCompanies(allCompaniesCopy);
      },
    });
  }

  function checkCalcStatus(transactionToCheck) {
    transactionDataRequest({
      url: '/transactions/details',
      urlIds: [transactionToCheck.transactionData.companyId, transactionToCheck.transactionData.transactionId],
      onSuccess: (response) => {
        const transactionData = response.Body[0];
        if (transactionData.adminProgress === 'calculation completed') {
          const indexToUpdate = allCompanies.findIndex((company) => {
            return company.transactionData?.transactionId === transactionToCheck.transactionData.transactionId;
          });
          updateAdminProgress(allCompanies[indexToUpdate], 'calculation completed', true);
        } else if (transactionData.adminProgress === 'calculation failed') {
          const indexToUpdate = allCompanies.findIndex((company) => {
            return company.transactionData?.transactionId === transactionToCheck.transactionData.transactionId;
          });
          updateAdminProgress(allCompanies[indexToUpdate], 'calculation failed', true);
        }
      },
    });
  }

  const [{ loading: fetchingTransactions }] = useFetch({
    url: '/homepage/initio',
    onSuccess: setAllCompanies,
  });

  useEffect(() => { if (tabToView === 0) setViewAccountInfo(false); }, [tabToView]);

  useEffect(() => { if (showArchive) setCompanyToViewData({}); }, [showArchive]);

  useEffect(() => { if (companyToViewData.companyData) setShowArchive(false); }, [companyToViewData]);

  useEffect(() => {
    if (allCompanies.length) {
      const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
      const paramTabToView = urlParamsOnPgLoad.get('tabToView');
      const paramCompanyId = urlParamsOnPgLoad.get('cId');
      const paramTransactionId = urlParamsOnPgLoad.get('tId');
      if (paramTabToView && paramCompanyId) {
        setTabToView(parseInt(paramTabToView, 10));
        setCompanyToViewData(allCompanies.find((company) => company.companyData.companyId === paramCompanyId &&
          (!company.transactionData || company.transactionData?.transactionId === paramTransactionId)));
      }

      setCalculatingTransactions(allCompanies.filter((transaction) => transaction.transactionData?.isCalculating));
      setReloadTransactions(true);
    }
  }, [allCompanies]);

  useEffect(() => {
    if (calculatingTransactions.length > 0) {
      calculatingTransactions.forEach((calculatingTransaction) => {
        const calcStatusCheckInterval = setInterval(() => {
          checkCalcStatus(calculatingTransaction);
        }, 30000);
        return () => clearInterval(calcStatusCheckInterval);
      });
    }
    return undefined;
  }, [calculatingTransactions]);

  // Sort transactions to show based on search input and sort-by dropdowns
  useEffect(() => {
    const inProgressStatuses = [
      'in progress',
      'work in progress',
      'ready for calculation',
      'calculation in progress',
      'calculation failed',
      'review sandbox',
      'review report',
      'calculation completed',
    ];
    const completedStatuses = ['sandbox sent to client', 'completed'];

    const defaultNotPaidSorting = (data) => data.sort((a, b) => {
      return moment(b.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss') - moment(a.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss');
    });

    const defaultToDoNotStartedSorting = (data) => data.sort((a, b) => {
      return moment(b.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss') - moment(a.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss');
    });
    const defaultToDoStartedSorting = (data) => data.sort((a, b) => {
      return moment(b.transactionData.createdDatetime, 'YYYY-MM-DD HH:mm:ss') - moment(a.transactionData.createdDatetime, 'YYYY-MM-DD HH:mm:ss');
    });
    const defaultToDoSubmittedSorting = (data) => data.sort((a, b) => {
      return moment(b.transactionData.submittedDate, 'YYYY-MM-DD HH:mm:ss') - moment(a.transactionData.submittedDate);
    });

    const defaultInProgressSorting = (data) => data.sort((a, b) => {
      return a.transactionData.adminProgress === 'review report' ? -1 : 0 || b.transactionData.adminProgress === 'review report' ? 1 : 0 ||
        a.transactionData.adminProgress === 'calculation failed' ? -1 : 0 || b.transactionData.adminProgress === 'calculation failed' ? 1 : 0 ||
          // a.transactionData.adminProgress === 'calculation completed' ? -1 : 0 ||
          //   b.transactionData.adminProgress === 'calculation completed' ? 1 : 0 ||
          //     a.transactionData.adminProgress === 'review sandbox' ? -1 : 0 || b.transactionData.adminProgress === 'review sandbox' ? 1 : 0 ||
          //       a.transactionData.isCalculating ? -1 : 0 || b.transactionData.isCalculating ? 1 : 0 ||
          moment(b.transactionData.lastModified, 'YYYY-MM-DD HH:mm:ss') - moment(a.transactionData.lastModified, 'YYYY-MM-DD HH:mm:ss');
    });

    const defaultCompletedSorting = (data) => data.sort((a, b) => {
      return a.transactionData.adminProgress === 'sandbox sent to client' ? -1 : 0 ||
        b.transactionData.adminProgress === 'sandbox sent to client' ? 1 : 0 ||
      moment(b.transactionData.lastModified, 'YYYY-MM-DD HH:mm:ss') - moment(a.transactionData.lastModified, 'YYYY-MM-DD HH:mm:ss');
    });

    const defaultArchivedSorting = (data) => data.sort((a, b) => {
      return moment(b.transactionData.lastModified, 'YYYY-MM-DD HH:mm:ss') - moment(a.transactionData.lastModified, 'YYYY-MM-DD HH:mm:ss');
    });

    let sortedThroughNotPaidCompanies = allCompanies.filter((company) => !company.paymentData);

    let sortedThroughToDoTransactions =
      allCompanies.filter((company) => company.paymentData && (!company.transactionData ||
        !inProgressStatuses.concat(completedStatuses).concat(['archived']).includes(company.transactionData?.adminProgress)));

    let sortedThroughInProgressTransactions =
      allCompanies.filter((company) => inProgressStatuses.includes(company.transactionData?.adminProgress));

    let sortedThroughCompletedTransactions =
      allCompanies.filter((company) => completedStatuses.includes(company.transactionData?.adminProgress));

    let sortedThroughArchivedTransactions =
      allCompanies.filter((company) => company.transactionData?.adminProgress === 'archived');

    sortedThroughNotPaidCompanies = defaultNotPaidSorting(sortedThroughNotPaidCompanies);
    sortedThroughToDoTransactions = defaultToDoSubmittedSorting(sortedThroughToDoTransactions.filter((transaction) => {
      return transaction.transactionData && transaction.transactionData.progress === 'completed';
    })).concat(defaultToDoStartedSorting(sortedThroughToDoTransactions.filter((transaction) => {
      return transaction.transactionData && transaction.transactionData.progress !== 'completed';
    })), defaultToDoNotStartedSorting(sortedThroughToDoTransactions.filter((transaction) => {
      return !transaction.transactionData && transaction.paymentData;
    })));
    sortedThroughInProgressTransactions = defaultInProgressSorting(sortedThroughInProgressTransactions);
    sortedThroughCompletedTransactions = defaultCompletedSorting(sortedThroughCompletedTransactions);
    sortedThroughArchivedTransactions = defaultArchivedSorting(sortedThroughArchivedTransactions);

    sortedThroughNotPaidCompanies =
      sortedThroughNotPaidCompanies.filter((transaction) => {
        return transaction.accountData.companyName.toLowerCase().includes(
          searchCompanyInput.trim().toLowerCase(),
        );
      });
    sortedThroughToDoTransactions =
      sortedThroughToDoTransactions.filter((transaction) => {
        return transaction.accountData.companyName.toLowerCase().includes(
          searchCompanyInput.trim().toLowerCase(),
        );
      });
    sortedThroughInProgressTransactions =
      sortedThroughInProgressTransactions.filter((transaction) => {
        return transaction.accountData.companyName.toLowerCase().includes(
          searchCompanyInput.trim().toLowerCase(),
        );
      });
    sortedThroughCompletedTransactions =
      sortedThroughCompletedTransactions.filter((transaction) => {
        return transaction.accountData.companyName.toLowerCase().includes(
          searchCompanyInput.trim().toLowerCase(),
        );
      });
    sortedThroughArchivedTransactions =
      sortedThroughArchivedTransactions.filter((transaction) => {
        return transaction.accountData.companyName.toLowerCase().includes(
          searchCompanyInput.trim().toLowerCase(),
        );
      });

    if (sortByValue === 'most-recent') {
      const sortData = (data) => data.sort((a, b) => {
        return moment(b.transactionData?.lastModified || b.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss') -
          moment(a.transactionData?.lastModified || a.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss');
      });
      sortedThroughNotPaidCompanies = sortData(sortedThroughNotPaidCompanies);
      sortedThroughToDoTransactions = sortData(sortedThroughToDoTransactions);
      sortedThroughInProgressTransactions = sortData(sortedThroughInProgressTransactions);
      sortedThroughCompletedTransactions = sortData(sortedThroughCompletedTransactions);
      sortedThroughArchivedTransactions = sortData(sortedThroughArchivedTransactions);
    }
    if (sortByValue === 'alphabetical') {
      const sortData = (data) => data.sort((a, b) => a.accountData.companyName.localeCompare(b.accountData.companyName));
      sortedThroughNotPaidCompanies = sortData(sortedThroughNotPaidCompanies);
      sortedThroughToDoTransactions = sortData(sortedThroughToDoTransactions);
      sortedThroughInProgressTransactions = sortData(sortedThroughInProgressTransactions);
      sortedThroughCompletedTransactions = sortData(sortedThroughCompletedTransactions);
      sortedThroughArchivedTransactions = sortData(sortedThroughArchivedTransactions);
    }
    if (sortByValue === 'series') {
      const sortData = (data) => data.sort((a, b) => a.transactionData?.chosenFunding && b.transactionData?.chosenFunding ?
        a.transactionData?.chosenFunding.localeCompare(b.transactionData?.chosenFunding) : 0);
      sortedThroughToDoTransactions = sortData(sortedThroughToDoTransactions);
      sortedThroughInProgressTransactions = sortData(sortedThroughInProgressTransactions);
      sortedThroughCompletedTransactions = sortData(sortedThroughCompletedTransactions);
      sortedThroughArchivedTransactions = sortData(sortedThroughArchivedTransactions);
    }
    if (sortByValue === 'date-submitted') {
      const sortData = (data) => data.sort((a, b) => {
        return moment(b.transactionData?.submittedDate || b.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss') -
          moment(a.transactionData?.submittedDate || a.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss');
      });
      sortedThroughToDoTransactions =
        sortData(sortedThroughToDoTransactions.filter((company) => {
          return company.transactionData;
        })).concat(sortedThroughToDoTransactions.filter((company) => !company.transactionData));
      sortedThroughInProgressTransactions = sortData(sortedThroughInProgressTransactions);
      sortedThroughCompletedTransactions = sortData(sortedThroughCompletedTransactions);
      sortedThroughArchivedTransactions = sortData(sortedThroughArchivedTransactions);
    }
    if (sortByValue === 'priority') {
      const sortData = (data) => data.sort((a, b) => (a.companyData.priority || 2) - (b.companyData.priority || 2));
      sortedThroughNotPaidCompanies = sortData(sortedThroughNotPaidCompanies);
      sortedThroughToDoTransactions = sortData(sortedThroughToDoTransactions);
      sortedThroughInProgressTransactions = sortData(sortedThroughInProgressTransactions);
      sortedThroughCompletedTransactions = sortData(sortedThroughCompletedTransactions);
      sortedThroughArchivedTransactions = sortData(sortedThroughArchivedTransactions);
    }

    if (sortNotPaidByStatusValue === 'last week') {
      const aWeekAgo = moment().subtract(1, 'week');
      sortedThroughNotPaidCompanies =
        sortedThroughNotPaidCompanies.filter((company) => {
          return moment(company.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss') > aWeekAgo;
        });
    } else if (sortNotPaidByStatusValue === 'last month') {
      const aMonthAgo = moment().subtract(1, 'month');
      sortedThroughNotPaidCompanies =
        sortedThroughNotPaidCompanies.filter((company) => {
          return moment(company.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss') > aMonthAgo;
        });
    }

    if (sortToDoByStatusValue === 'submitted') {
      sortedThroughToDoTransactions =
        sortedThroughToDoTransactions.filter((company) => {
          return company.transactionData && company.transactionData.progress === 'completed';
        });
    } else if (sortToDoByStatusValue === 'in progress') {
      sortedThroughToDoTransactions =
        sortedThroughToDoTransactions.filter((company) => company.transactionData && company.transactionData.progress !== 'completed');
    } else if (sortToDoByStatusValue === 'not started') {
      sortedThroughToDoTransactions = sortedThroughToDoTransactions.filter((company) => !company.transactionData);
    }

    if (sortInProgressByStatusValue !== 'all' && sortInProgressByStatusValue !== 'calculating') {
      sortedThroughInProgressTransactions =
        sortedThroughInProgressTransactions.filter((company) => {
          return company.transactionData.adminProgress === sortInProgressByStatusValue;
        });
    } else if (sortInProgressByStatusValue === 'calculating') {
      sortedThroughInProgressTransactions =
        sortedThroughInProgressTransactions.filter((company) => {
          return company.transactionData.isCalculating;
        });
    }

    if (sortCompletedByStatusValue !== 'all') {
      sortedThroughCompletedTransactions =
        sortedThroughCompletedTransactions.filter((company) => {
          return company.transactionData.adminProgress === sortCompletedByStatusValue;
        });
    }

    setNotPaidNumberOfPages(Math.ceil(sortedThroughNotPaidCompanies.length /
      numberOfNotPaidBlocksPerColumn));
    setToDoNumberOfPages(Math.ceil(sortedThroughToDoTransactions.length /
      numberOfToDoBlocksPerColumn));
    setInProgressNumberOfPages(Math.ceil(sortedThroughInProgressTransactions.length /
      numberOfInProgressBlocksPerColumn));
    setCompletedNumberOfPages(Math.ceil(sortedThroughCompletedTransactions.length /
      numberOfCompletedBlocksPerColumn));

    sortedThroughNotPaidCompanies = sortedThroughNotPaidCompanies.slice(
      (notPaidCurrentPage - 1) * numberOfNotPaidBlocksPerColumn,
      notPaidCurrentPage * numberOfNotPaidBlocksPerColumn,
    );
    sortedThroughToDoTransactions = sortedThroughToDoTransactions.slice(
      (toDoCurrentPage - 1) * numberOfToDoBlocksPerColumn,
      toDoCurrentPage * numberOfToDoBlocksPerColumn,
    );
    sortedThroughInProgressTransactions = sortedThroughInProgressTransactions.slice(
      (inProgressCurrentPage - 1) * numberOfInProgressBlocksPerColumn,
      inProgressCurrentPage * numberOfInProgressBlocksPerColumn,
    );
    sortedThroughCompletedTransactions = sortedThroughCompletedTransactions.slice(
      (completedCurrentPage - 1) * numberOfCompletedBlocksPerColumn,
      completedCurrentPage * numberOfCompletedBlocksPerColumn,
    );

    setNotPaidCompanies(sortedThroughNotPaidCompanies.map((company, i) => ({ ...company, key: i })));
    setToDoTransactions(sortedThroughToDoTransactions.map((company, i) => ({ ...company, key: i })));
    setInProgressTransactions(sortedThroughInProgressTransactions.map((company, i) => ({ ...company, key: i })));
    setCompletedTransactions(sortedThroughCompletedTransactions.map((company, i) => ({ ...company, key: i })));
    setArchivedCompanies(sortedThroughArchivedTransactions.map((company, i) => ({ ...company, key: i })));
    if (reloadTransactions) setReloadTransactions(false);
  }, [searchCompanyInput,
    sortByValue,
    sortNotPaidByStatusValue,
    sortToDoByStatusValue,
    sortInProgressByStatusValue,
    sortCompletedByStatusValue,
    notPaidCurrentPage,
    toDoCurrentPage,
    inProgressCurrentPage,
    completedCurrentPage,
    reloadTransactions]);

  useEffect(() => {
    setNotPaidCurrentPage(1);
    setToDoCurrentPage(1);
    setInProgressCurrentPage(1);
    setCompletedCurrentPage(1);
  }, [searchCompanyInput, sortByValue]);

  useEffect(() => { setNotPaidCurrentPage(1); }, [sortNotPaidByStatusValue]);
  useEffect(() => { setToDoCurrentPage(1); }, [sortToDoByStatusValue]);
  useEffect(() => { setInProgressCurrentPage(1); }, [sortInProgressByStatusValue]);
  useEffect(() => { setCompletedCurrentPage(1); }, [sortCompletedByStatusValue]);

  // Only show the pagination bar if any of the rows have more than one page
  function showPaginationBar() {
    return notPaidNumberOfPages > 1 || toDoNumberOfPages > 1 || inProgressNumberOfPages > 1 || completedNumberOfPages > 1;
  }

  if (companyToViewData?.companyData) {
    return (
      <Tabs
        tabToView={tabToView}
        setTabToView={setTabToView}
        companyToViewData={companyToViewData}
        setCompanyToViewData={setCompanyToViewData}
        updateAdminProgress={updateAdminProgress}
        updatePriority={updatePriority}
        viewAccountInfo={viewAccountInfo}
        setViewAccountInfo={setViewAccountInfo}
        currentAdminName={currentAdminName}
        allCompanies={allCompanies}
        setAllCompanies={setAllCompanies}
      />
    );
  }

  return (
    <div className="CalcForm409A">
      <div className="tables-pg-top-nav-buttons" ref={appWidthRef}>
        <button
          className={`top-nav-button ${!showArchive ? 'active' : ''}`}
          type="button"
          onClick={() => setShowArchive(false)}
        >
          <HomeOutlinedIcon />
          Home
        </button>
      </div>
      <div className="search-bar-and-sort-dropdown">
        <TextField
          autoComplete="off"
          className="search-field"
          placeholder="Search for client company"
          value={searchValueToDisplay}
          onChange={(e) => {
            setSearchValueToDisplay(e.target.value);
            setTimeout(() => {
              setSearchCompanyInput(e.target.value);
            }, 400);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <span className="sort-by">
          <span>Sort by:</span>
          <Select
            onChange={(e) => setSortByValue(e.target.value)}
            value={sortByValue || 'most-recent'}
            IconComponent={ExpandMoreIcon}
            MenuProps={{ disableScrollLock: true }}
          >
            <MenuItem className={sortByValue === 'most-recent' ? 'active' : ''} value="most-recent">
              Most recent
            </MenuItem>
            <MenuItem className={sortByValue === 'priority' ? 'active' : ''} value="priority">
              Priority
            </MenuItem>
            <MenuItem className={sortByValue === 'alphabetical' ? 'active' : ''} value="alphabetical">
              Alphabetical
            </MenuItem>
            <MenuItem className={sortByValue === 'series' ? 'active' : ''} value="series">
              Series
            </MenuItem>
            <MenuItem className={sortByValue === 'date-submitted' ? 'active' : ''} value="date-submitted">
              Date submitted
            </MenuItem>
          </Select>
        </span>
      </div>
      {showArchive ? (
        <Archive
          archivedCompanies={archivedCompanies}
          currentAdminName={currentAdminName}
          setCompanyToViewData={setCompanyToViewData}
          setTabToView={setTabToView}
          updatePriority={updatePriority}
          setViewAccountInfo={setViewAccountInfo}
          setDropdownBlockIsOpen={setDropdownBlockIsOpen}
          updateAdminProgress={updateAdminProgress}
        />
      ) : (
        <>
          <div className="progress-cols-wrapper">
            <div
              className={`progress-col ${dropdownBlockIsOpen ? 'has-open' : ''}`}
              onMouseEnter={() => setNotPaidColumnIsHovered(true)}
              onMouseLeave={() => setNotPaidColumnIsHovered(false)}
            >
              <h3>Not paid</h3>
              <span className="sort-by-status">
                <span>Filter by:</span>
                <Select
                  onChange={(e) => setSortNotPaidByStatusValue(e.target.value)}
                  value={sortNotPaidByStatusValue}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{ disableScrollLock: true, classes: { paper: 'filter-by-dropdown' } }}
                >
                  <MenuItem className={sortNotPaidByStatusValue === 'all' ? 'active' : ''} value="all">
                    View all
                  </MenuItem>
                  <MenuItem className={sortNotPaidByStatusValue === 'last week' ? 'active' : ''} value="last week">
                    Signed up in the last week
                  </MenuItem>
                  <MenuItem className={sortNotPaidByStatusValue === 'last month' ? 'active' : ''} value="last month">
                    Signed up in the last month
                  </MenuItem>
                </Select>
              </span>
              {notPaidCompanies.map((company) => (
                <TransactionStatusBlock
                  key={company.key}
                  companyToViewData={company}
                  currentAdminName={currentAdminName}
                  setCompanyToViewData={setCompanyToViewData}
                  setTabToView={setTabToView}
                  updatePriority={updatePriority}
                  setViewAccountInfo={setViewAccountInfo}
                  setDropdownBlockIsOpen={setDropdownBlockIsOpen}
                  updateAdminProgress={updateAdminProgress}
                />
              ))}
            </div>
            <div
              className={`progress-col ${dropdownBlockIsOpen ? 'has-open' : ''}`}
              onMouseEnter={() => setToDoColumnOnIsHovered(true)}
              onMouseLeave={() => setToDoColumnOnIsHovered(false)}
            >
              <h3>To do</h3>
              <span className="sort-by-status">
                <span>Filter by:</span>
                <Select
                  onChange={(e) => setSortToDoByStatusValue(e.target.value)}
                  value={sortToDoByStatusValue}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{ disableScrollLock: true, classes: { paper: 'filter-by-dropdown' } }}
                >
                  <MenuItem className={sortToDoByStatusValue === 'all' ? 'active' : ''} value="all">
                    View all
                  </MenuItem>
                  <MenuItem className={sortToDoByStatusValue === 'submitted' ? 'active' : ''} value="submitted">
                    Client submitted 409A
                  </MenuItem>
                  <MenuItem className={sortToDoByStatusValue === 'in progress' ? 'active' : ''} value="in progress">
                    Client 409A in progress
                  </MenuItem>
                  <MenuItem className={sortToDoByStatusValue === 'not started' ? 'active' : ''} value="not started">
                    Client not started 409A
                  </MenuItem>
                </Select>
              </span>
              {toDoTransactions.map((company) => (
                <TransactionStatusBlock
                  key={company.key}
                  companyToViewData={company}
                  currentAdminName={currentAdminName}
                  setCompanyToViewData={setCompanyToViewData}
                  setTabToView={setTabToView}
                  updatePriority={updatePriority}
                  setViewAccountInfo={setViewAccountInfo}
                  setDropdownBlockIsOpen={setDropdownBlockIsOpen}
                  updateAdminProgress={updateAdminProgress}
                />
              ))}
            </div>
            <div
              className={`progress-col ${dropdownBlockIsOpen ? 'has-open' : ''}`}
              onMouseEnter={() => setInProgressColumnIsHovered(true)}
              onMouseLeave={() => setInProgressColumnIsHovered(false)}
            >
              <h3>In progress</h3>
              <span className="sort-by-status">
                <span>Filter by:</span>
                <Select
                  onChange={(e) => setSortInProgressByStatusValue(e.target.value)}
                  value={sortInProgressByStatusValue}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{ disableScrollLock: true, classes: { paper: 'filter-by-dropdown' } }}
                >
                  <MenuItem className={sortInProgressByStatusValue === 'all' ? 'active' : ''} value="all">
                    View all
                  </MenuItem>
                  <MenuItem className={sortInProgressByStatusValue === 'review report' ? 'active' : ''} value="review report">
                    Review report
                  </MenuItem>
                  <MenuItem className={sortInProgressByStatusValue === 'review sandbox' ? 'active' : ''} value="review sandbox">
                    Review sandbox
                  </MenuItem>
                  <MenuItem className={sortInProgressByStatusValue === 'calculating' ? 'active' : ''} value="calculating">
                    Calculating
                  </MenuItem>
                  <MenuItem className={sortInProgressByStatusValue === 'calculation failed' ? 'active' : ''} value="calculation failed">
                    Calculation failed
                  </MenuItem>
                </Select>
              </span>
              {inProgressTransactions.map((company) => (
                <TransactionStatusBlock
                  key={company.key}
                  companyToViewData={company}
                  currentAdminName={currentAdminName}
                  setCompanyToViewData={setCompanyToViewData}
                  setTabToView={setTabToView}
                  updatePriority={updatePriority}
                  setViewAccountInfo={setViewAccountInfo}
                  setDropdownBlockIsOpen={setDropdownBlockIsOpen}
                  updateAdminProgress={updateAdminProgress}
                />
              ))}
            </div>
            <div
              className={`progress-col ${dropdownBlockIsOpen ? 'has-open' : ''}`}
              onMouseEnter={() => setCompletedColumnIsHovered(true)}
              onMouseLeave={() => setCompletedColumnIsHovered(false)}
            >
              <h3>Completed</h3>
              <span className="sort-by-status">
                <span>Filter by:</span>
                <Select
                  onChange={(e) => setSortCompletedByStatusValue(e.target.value)}
                  value={sortCompletedByStatusValue}
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{ disableScrollLock: true, classes: { paper: 'filter-by-dropdown' } }}
                >
                  <MenuItem value="all">
                    View all
                  </MenuItem>
                  <MenuItem value="sandbox sent to client">
                    Sandbox sent
                  </MenuItem>
                  <MenuItem value="completed">
                    409A Done
                  </MenuItem>
                </Select>
              </span>
              {completedTransactions.map((company) => (
                <TransactionStatusBlock
                  key={company.key}
                  companyToViewData={company}
                  currentAdminName={currentAdminName}
                  setCompanyToViewData={setCompanyToViewData}
                  setTabToView={setTabToView}
                  updatePriority={updatePriority}
                  setViewAccountInfo={setViewAccountInfo}
                  setDropdownBlockIsOpen={setDropdownBlockIsOpen}
                  updateAdminProgress={updateAdminProgress}
                />
              ))}
            </div>
          </div>
          {showPaginationBar() && (
            <div className="bottom-pagination">
              <Pagination
                className={`${notPaidNumberOfPages <= 1 || !notPaidColumnIsHovered ? 'hidden' : ''}`}
                showFirstButton
                showLastButton
                page={notPaidCurrentPage}
                count={notPaidNumberOfPages}
                onChange={(e, value) => setNotPaidCurrentPage(value)}
                siblingCount={0}
                onMouseEnter={() => setNotPaidColumnIsHovered(true)}
                onMouseLeave={() => setNotPaidColumnIsHovered(false)}
              />
              <Pagination
                className={`${toDoNumberOfPages <= 1 || !toDoColumnOnIsHovered ? 'hidden' : ''}`}
                showFirstButton
                showLastButton
                page={toDoCurrentPage}
                count={toDoNumberOfPages}
                onChange={(e, value) => setToDoCurrentPage(value)}
                siblingCount={0}
                onMouseEnter={() => setToDoColumnOnIsHovered(true)}
                onMouseLeave={() => setToDoColumnOnIsHovered(false)}
              />
              <Pagination
                className={`${inProgressNumberOfPages <= 1 || !inProgressColumnIsHovered ? 'hidden' : ''}`}
                showFirstButton
                showLastButton
                page={inProgressCurrentPage}
                count={inProgressNumberOfPages}
                onChange={(e, value) => setInProgressCurrentPage(value)}
                siblingCount={0}
                onMouseEnter={() => setInProgressColumnIsHovered(true)}
                onMouseLeave={() => setInProgressColumnIsHovered(false)}
              />
              <Pagination
                className={`${completedNumberOfPages <= 1 || !completedColumnIsHovered ? 'hidden' : ''}`}
                showFirstButton
                showLastButton
                page={completedCurrentPage}
                count={completedNumberOfPages}
                onChange={(e, value) => setCompletedCurrentPage(value)}
                siblingCount={0}
                onMouseEnter={() => setCompletedColumnIsHovered(true)}
                onMouseLeave={() => setCompletedColumnIsHovered(false)}
              />
            </div>
          )}
        </>
      )}
      <Dialog
        open={fetchingTransactions}
        className="loading-inputs-dialog"
        disableScrollLock
      >
        <div className="loading-wrapper">
          <LoadingSpinner className="custom-loading-spinner" />
        </div>
        Loading Transactions . . .
      </Dialog>
    </div>
  );
}
