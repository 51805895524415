import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, InputLabel, Paper, Select, MenuItem, Typography, FormControl,
} from '@mui/material';
import './CalcTable.scss';
import './CalcLimits.scss';

function createOptionsInIncrements(min, max, inc, prettyFormatter) {
  const values = [];
  for (let i = min; i <= max; i += inc) {
    values.push(prettyFormatter(i));
  }

  return values;
}

function toPercent(val) {
  return [val, `${val}%`];
}

function toYears(val) {
  return [val, `${val} yrs`];
}

const Text = {
  Title: 'Model limits',
  Description: 'You can proceed with the calc engine\'s default limits or override any or all of the below. ' +
    'These are the parameters shared by all models.',
};

const CalcLimitDisplayNames = {
  volatility: 'Volatility range',
  ntmOptions: 'NTM options range',
  term: 'Term range',
  dlom: 'DLOM range',
  min: 'Minimum',
  max: 'Maximum',
  inc: 'Increment',
};

const CalcLimitOptions = {
  volatility: {
    max: createOptionsInIncrements(50, 120, 5, toPercent),
    min: createOptionsInIncrements(0, 60, 5, toPercent),
    inc: [1, 2.5, 5].map(toPercent),
  },
  dlom: {
    max: createOptionsInIncrements(30, 60, 5, toPercent),
    min: createOptionsInIncrements(0, 50, 5, toPercent),
    inc: [1, 5].map(toPercent),
  },
  ntmOptions: {
    max: createOptionsInIncrements(50, 100, 5, toPercent),
    min: createOptionsInIncrements(0, 60, 5, toPercent),
    inc: [1, 5, 10].map(toPercent),
  },
  term: {
    max: createOptionsInIncrements(2, 5, 1, toYears),
    min: createOptionsInIncrements(1, 3, 1, toYears),
    inc: [0.25, 0.5].map(toYears),
  },
};

export default function CalcLimits({
  modelCalcLimits: calcLimits,
  setModelCalcLimits: setCalcLimits,
  loading,
}) {
  const onChange = (key, field) => async (event) => {
    const newValue = event.target.value;
    let newRowState;

    // set state, then if the save fails, we revert back to the original values
    setCalcLimits((prevState) => {
      newRowState = {
        ...prevState[key],
        [field]: newValue,
      };
      return {
        ...prevState,
        [key]: newRowState,
      };
    });
  };

  return (
    <main className="CalcTable CalcLimitsTable">
      {/* {
        error && <Alert color="error">An error occurred saving limits, please try again later.</Alert>
      } */}
      <Paper className="limits-container">
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Typography sx={{ color: '#707ADB', fontWeight: 'bold' }}>{Text.Title}</Typography>
          <Typography>{Text.Description}</Typography>
        </div>
        <Card className="calc-limits-controls">
          {Object.entries(CalcLimitOptions).map(([key, limitLimits], i) => (
            <React.Fragment key={key}>
              {i % 2 === 1 && <div key={`sep_${Math.floor(i / 2) + 1}`} className="separator" />}
              <div className="limit-control-section" key={key}>
                <Typography variant="caption" fontWeight="bold">{CalcLimitDisplayNames[key]}</Typography>
                <div className="limit-control">
                  {
                    ['min', 'max', 'inc'].map((k) => {
                      const selectProps = {
                        labelId: 'selector-label',
                        id: 'selector',
                        label: CalcLimitDisplayNames[k],
                        disabled: loading,
                        value: calcLimits?.[key]?.[k] || '',
                        onChange: onChange(key, k),
                        variant: 'outlined',
                        size: 'small',
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        },
                      };
                      return (
                        <FormControl key={k}>
                          <InputLabel id="selector-label">{selectProps.label}</InputLabel>
                          {
                            (calcLimits?.[key][k] !== undefined) ?
                              (
                                <Select {...selectProps}>
                                  {limitLimits[k].map(([value, display]) => (
                                    ((k === 'min' && value < calcLimits[key].max) || (k === 'max' && value > calcLimits[key].min) || (k === 'inc')) &&
                                    <MenuItem key={value} value={value}>{display}</MenuItem>
                                  ))}
                                </Select>
                              ) :
                              (
                                <Select
                                  {...selectProps}
                                  key="loading"
                                >
                                  <MenuItem value={0}>-</MenuItem>
                                </Select>
                              )
                          }
                        </FormControl>
                      );
                    })
                  }
                </div>
              </div>
            </React.Fragment>
          ))}
        </Card>
        <div style={{ height: '4rem' }} />
      </Paper>
    </main>
  );
}

CalcLimits.propTypes = {
  modelCalcLimits: PropTypes.object,
  companyId: PropTypes.string,
  transactionId: PropTypes.string,
  loading: PropTypes.bool,
  setModelCalcLimits: PropTypes.func,
  setChangeHasBeenMade: PropTypes.func,
};
