import { createContext } from 'react';

const AuthContext = createContext();
const UserDataContext = createContext();
const NavWidthContext = createContext();
const NavigationContext = createContext();
const ErrorMessageContext = createContext();
const ShowArchiveContext = createContext();

export {
  AuthContext,
  NavWidthContext,
  NavigationContext,
  UserDataContext,
  ErrorMessageContext,
  ShowArchiveContext,
};
