import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import Loader from '../components/Loader';
import { AuthContext, UserDataContext } from '.';

import amplifyConfig from '../utils/amplifyConfig';

import useAsync from '../hooks/useAsync';
import useSignOut from '../hooks/useSignOut';

import { authenticationCheck, parseUserAttributes } from '../utils/auth';

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { userData, setUserData } = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [signOut] = useSignOut();

  const [, authCheckRequest] = useAsync();
  const [, authSessionRequest] = useAsync();
  const [, fetchAttributesRequest] = useAsync();

  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN }));

  function initSession() {
    amplifyConfig();
    authCheckRequest({
      promise: () => getCurrentUser(),
      onSuccess: () => {
        authSessionRequest({
          promise: () => fetchAuthSession(),
          onSuccess: (authResponse) => {
            if (authenticationCheck(authResponse)) {
              fetchAttributesRequest({
                promise: () => fetchUserAttributes(),
                onSuccess: (attributes) => {
                  const userAttributes = parseUserAttributes(attributes);
                  setIsLoading(false);
                  setUserData({ ...userData, userAttributes });

                  setIsAuthenticated(true);
                },
              });
            } else signOut();
          },
        });
      },
      onError: () => signOut(),
    });
  }

  useEffect(() => { if (!isAuthenticated) initSession(); }, []);
  if (isLoading) {
    return (
     <Loader/>
    );
  }
  return <AuthContext.Provider value={isAuthenticated}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = { children: PropTypes.element.isRequired };
