import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StorefrontIcon from '@mui/icons-material/Storefront';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { UserDataContext, NavWidthContext } from '../../contexts';

import StatusChip from './StatusChip';
import TabsContent from './TabsContent';
import AccountInfo from './AccountInfo';

import useFetch from '../../hooks/useFetch';
import useS3 from '../../hooks/useS3';

import {
  defaultPreferredTableData,
  defaultSafeConvertibleTableData,
  defaultCommonTableData,
  defaultOptionsTableData,
  defaultWarrantsTableData,
  defaultRemainingOptionsPoolTableData,
  preferredTableSchema,
  safeConvertibleTableSchema,
  commonTableSchema,
  optionsTableSchema,
  warrantsTableSchema,
} from './tab1TableData';

import {
  defaultOpmInputsTableData,
  opmInputsTableSchema,
  defaultOpmInputsTableData2,
  opmInputsTableSchema2,
} from './tab2TableData';

import {
  defaultSharesTableData,
  defaultLiquidPreferencesTableData,
} from './tab3TableData';


import {
  allocateDBData,
  checkForErrorOnPreferredOrSafeTable,
  convertToDBData,
  checkForErrorOnCommonTable,
  checkForErrorOnOptionsOrWarrantsTable,
} from './calcTablesUtils';

import {
  copy,
  concatCharacter,
  removeCommas,
} from '../../utils';

import './Tabs.scss';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function Tabs({
  tabToView,
  setTabToView,
  companyToViewData,
  setCompanyToViewData,
  updateAdminProgress,
  updatePriority,
  viewAccountInfo,
  setViewAccountInfo,
  currentAdminName,
}) {
  const { userData } = useContext(UserDataContext);
  const { setNavWidth } = useContext(NavWidthContext);

  // Tab1 Data
  const [preferredTableData, setPreferredTableData] =
    useState([defaultPreferredTableData]);
  const [safeConvertibleTableData, setSafeConvertibleTableData] =
    useState([defaultSafeConvertibleTableData]);
  const [commonTableData, setCommonTableData] =
    useState([defaultCommonTableData]);
  const [optionsTableData, setOptionsTableData] =
    useState([defaultOptionsTableData]);
  const [warrantsTableData, setWarrantsTableData] =
    useState([defaultWarrantsTableData]);
  const [remainingOptionsPoolTableData, setRemainingOptionsPoolTableData] =
    useState([defaultRemainingOptionsPoolTableData]);

  const [tab1DBData, setTab1DBData] = useState(null);

  // Tab2 Data
  const [opmInputsTableData, setOpmInputsTableData] = useState([defaultOpmInputsTableData]);
  const [opmInputsTableData2, setOpmInputsTableData2] = useState([defaultOpmInputsTableData2]);
  const [modelCalcLimits, _setModelCalcLimits] = useState(undefined);
  const [modelLimitsChanged, setModelLimitsChanged] = useState(false);
  const setModelCalcLimits = (data) => {
    _setModelCalcLimits(data);
    setModelLimitsChanged(true);
  };

  const [tab2DBData, setTab2DBData] = useState(null);

  // Tab 3 Data
  const [sharesTableData, setSharesTableData] = useState([defaultSharesTableData]);
  const [liquidPreferencesTableData, setLiquidPreferencesTableData] =
    useState([defaultLiquidPreferencesTableData]);

  // Tab 4 Data
  const [priceSandboxData, setPriceSandboxData] = useState(null);

  // Fixed Data
  const [metaData, setMetaData] = useState({});
  const [calcRequestData, setCalcRequestData] = useState({});
  const [ompFixedValues, setOpmFixedValues] = useState({});

  // For handling loading states
  const [DBDataIsLoading, setDBDataIsLoading] = useState(false);
  const [calcTableHasError, setCalcTableHasError] = useState(false);

  // Calculation Flow
  const [showCalcConfirmDialog, setShowCalcConfirmDialog] = useState(false);
  const [showReCalcConfirmDialog, setShowReCalcConfirmDialog] = useState(false);
  const [calculationIsInProgress, setCalculationIsInProgress] = useState(false);
  const [showCalculationSuccess, setShowCalculationSuccess] = useState(false);
  const [showCalculationFailure, setShowCalculationFailure] = useState(false);
  const [calcFailureErrorMessage, setCalcFailureErrorMessage] = useState(false);

  const [runWithNoNtm, setRunWithNoNtm] = useState(false);

  const [calcBtnMenuAnchorEl, setCalcBtnMenuAnchorEl] = useState(null);
  const [calcBtnMenuIsOpen, setCalcBtnMenuIsOpen] = useState(false);

  // PDF Report
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [recreateReport, setRecreateReport] = useState(false);

  // Snackbars
  const [showSendReportToClientSnackbar, setShowSendReportToClientSnackbar] = useState(false);
  const [reportApproved, setReportApproved] = useState(false);

  const [showSendSandboxToClientSnackbar, setShowSendSandboxToClientSnackbar] = useState(false);
  const [sandboxEmailHasBeenSent, setSandboxEmailHasBeenSent] = useState(false);

  const [priorityDropdownIsOpen, setPriorityDropdownIsOpen] = useState(false);
  const [priority, setPriority] = useState(companyToViewData.companyData.priority || 2);

  // Error Handling for Preferred and Safe Convertible
  const [preferredInputsHaveError, setPreferredInputsHaveError] = useState([{}]);
  const [safeInputsHaveError, setSafeInputsHaveError] = useState([{}]);
  const [commonTableHaveError, setCommonTableHaveError] = useState([]);
  const [optionsTableHaveError, setOptionsTableHaveError] = useState([]);
  const [warrantsTableHaveError, setWarrantsTableHaveError] = useState([]);
  const [remainingOptionPoolTableHaveError, setRemainingOptionPoolTableHaveError] = useState(false);

  const [calcDataHasBeenFetched, setCalcDataHasBeenFetched] = useState(false);

  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);

  const [, calcInfoRequest] = useFetch();
  const [, calcStatusRequest] = useFetch();

  const [{ loading: savingCalcData }, updateCalculationRequest] = useFetch();
  const [{ loading: savingCalcLimits }, modifyCalcLimitsRequest] = useFetch();

  const [, sandboxDataRequest] = useFetch();

  const [{ loading: approvingReport }, approveReportRequest] = useFetch();

  const [{ loading: sandboxEmailIsSending }, sendEmailRequest] = useFetch();

  const [, getReportRequest] = useS3();


  const nav = useNavigate();

  const appWidth = useRef(null);

  const appWidthRef = useCallback((node) => {
    if (appWidth?.current) window.removeEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    if (node) {
      appWidth.current = node;
      window.addEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
      setNavWidth(appWidth?.current.scrollWidth);
    }
  }, []);


  function getDBTableData({ showLoadingModal = false } = {}) {
    if (showLoadingModal) setDBDataIsLoading(true);
    calcInfoRequest({
      url: '/calculations/get-calc-info',
      urlIds: [companyToViewData.transactionData?.companyId, companyToViewData.transactionData?.transactionId],
      onSuccess: (calcData) => {
        setMetaData({
          companyId: companyToViewData.transactionData?.companyId,
          transactionId: companyToViewData.transactionData?.transactionId,
          insertUserId: userData.userAttributes.userId,
          isReviewed: calcData.isReviewed,
          progress: calcData.progress,
          calcObjectId: calcData.calcObjectId,
          reviewerUserId: calcData.reviewerUserId,
          companyName: companyToViewData.accountData.companyName,
          transactionDate: companyToViewData.transactionData?.transactionDate,
          valuationDate: companyToViewData.transactionData?.valuationDate || companyToViewData.transactionData?.transactionDate,
          chosenFunding: companyToViewData.transactionData?.chosenFunding,
          latestFundingDate: companyToViewData.transactionData?.currentFundingDate,
          companyIndustry: companyToViewData.transactionData?.industryVertical,
        });
        setCalcRequestData(calcData.calcRequest);
        setTab1DBData({
          preferred: calcData.preferred,
          safeConvertible: calcData.safeConvertible,
          common: calcData.common,
          option: calcData.option,
          warrant: calcData.warrant,
          remainingOptionsPool: calcData.remainingOptionsPool,
        });
        setTab2DBData(calcData.opmInputs);
        setOpmFixedValues({
          earlyVolatilityAnnualEstimate: calcData.opmInputs.earlyVolatilityAnnualEstimate,
          earlyVolatilityLowerAnnualEstimate: calcData.opmInputs.earlyVolatilityLowerAnnualEstimate,
          lateVolatilityMeanAnnualEstimate: calcData.opmInputs.lateVolatilityMeanAnnualEstimate,
        });
        setSandboxEmailHasBeenSent(companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
          companyToViewData.transactionData?.adminProgress === 'review report' ||
          companyToViewData.transactionData?.adminProgress === 'completed');
        setReportApproved(companyToViewData.transactionData?.adminProgress === 'completed');
        setModelCalcLimits(calcData.calcLimits);
        setCalcDataHasBeenFetched(true);
      },
      onFinally: () => setDBDataIsLoading(false),
    });
  }

  function getCalcErrorMessage() {
    calcStatusRequest({
      url: '/calculations/get-calc-status',
      urlIds: [companyToViewData.transactionData?.companyId, companyToViewData.transactionData?.transactionId],
      onSuccess: (calcResponse) => setCalcFailureErrorMessage(calcResponse.Message),
    });
  }

  useEffect(() => {
    if (
      companyToViewData.transactionData?.isCalculating &&
      !calculationIsInProgress &&
      !showCalculationFailure &&
      !showCalculationSuccess
    ) {
      setCalculationIsInProgress(true);
      getDBTableData();
    } else if (companyToViewData.transactionData?.adminProgress === 'calculation completed') {
      setCalculationIsInProgress(false);
      setShowCalculationSuccess(true);
      getDBTableData();
    } else if (companyToViewData.transactionData?.adminProgress === 'calculation failed') {
      getCalcErrorMessage();
      setCalculationIsInProgress(false);
      setShowCalculationFailure(true);
      getDBTableData();
    } else if (companyToViewData.transactionData?.progress === 'completed' && !viewAccountInfo && !calcDataHasBeenFetched) {
      getDBTableData({ showLoadingModal: true });
    } else if (!companyToViewData.paymentData) {
      setViewAccountInfo(true);
    }
  }, [viewAccountInfo, companyToViewData.transactionData?.adminProgress]);

  useEffect(() => {
    if (tabToView !== 5) setNumberOfPages(null);
    setNavWidth(appWidth?.current.scrollWidth);
  }, [tabToView]);

  useEffect(() => {
    if (companyToViewData?.companyData) {
      const newUrl = `?tabToView=${tabToView}&cId=${companyToViewData.companyData.companyId}${companyToViewData.transactionData ?
        `&tId=${companyToViewData.transactionData?.transactionId}` : ''}`;
      if (newUrl !== window.location.search) nav(newUrl);
    }
  }, [companyToViewData, tabToView]);

  // This formats the data from the DB to the front-end format
  useEffect(() => {
    function assignRemainingOptionsPoolValues() {
      const newArray = [];
      const newObject = copy(defaultRemainingOptionsPoolTableData);
      newArray.push({
        ...newObject,
        available: {
          ...newObject.available,
          value: tab1DBData.remainingOptionsPool || '',
        },
        ntmOptionPercent: {
          ...newObject.ntmOptionPercent,
          value: concatCharacter(calcRequestData.ntmOptionsPerc || '', '%'), // TODO Check this
        },
      });
      return newArray;
    }
    if (tab1DBData) {
      setPreferredTableData(tab1DBData.preferred ?
        allocateDBData(tab1DBData.preferred, preferredTableSchema, defaultPreferredTableData) :
        [defaultPreferredTableData]);
      setSafeConvertibleTableData(tab1DBData.safeConvertible ?
        allocateDBData(
          tab1DBData.safeConvertible,
          safeConvertibleTableSchema,
          defaultSafeConvertibleTableData,
        ) : [defaultSafeConvertibleTableData]);
      setCommonTableData(tab1DBData.common ?
        allocateDBData(tab1DBData.common, commonTableSchema, defaultCommonTableData, 'common') :
        [defaultCommonTableData]);

      setOptionsTableData(tab1DBData.option ?
        allocateDBData(
          tab1DBData.option,
          optionsTableSchema,
          defaultOptionsTableData,
          'options',
        ) :
        [defaultOptionsTableData]);
      setWarrantsTableData(tab1DBData.warrant ?
        allocateDBData(
          tab1DBData.warrant,
          warrantsTableSchema,
          defaultWarrantsTableData,
          'warrants',
        ) :
        [defaultWarrantsTableData]);
      setRemainingOptionsPoolTableData(
        tab1DBData.remainingOptionsPool || calcRequestData.ntmOptionsPerc ?
          assignRemainingOptionsPoolValues() : [defaultRemainingOptionsPoolTableData],
      );
    }
  }, [tab1DBData]);

  useEffect(() => {
    function assignOpmInputsDBValues(DBData, tableSchema, defaultTableValues) {
      const newData = [];
      const newTableValues = copy(defaultTableValues);
      tableSchema.forEach((data, dataIndex) => {
        if (dataIndex === 0) {
          newTableValues[data.name].rowID = `${data.name}+-+-${0}`;
        }
        newTableValues[data.name].value =
          (DBData[data.name] && (data.formatter ?
            data.formatter(DBData[data.name]) : DBData[data.name])) ||
          defaultTableValues[data.name].value;
      });
      newData.push(newTableValues);
      return newData;
    }
    if (tab2DBData) {
      setOpmInputsTableData(
        assignOpmInputsDBValues(tab2DBData, opmInputsTableSchema, defaultOpmInputsTableData),
      );
      setOpmInputsTableData2(
        assignOpmInputsDBValues(tab2DBData, opmInputsTableSchema2, defaultOpmInputsTableData2),
      );
    }
  }, [tab2DBData]);

  // , 'Cap table' implemented later
  const tabsLabels = ['Basic Inputs', 'Model Inputs', 'Sandbox', 'Price Sandbox', 'Report', 'Client Inputs'];

  function saveDataToDB(newDBData, progress) {
    metaData.progress = progress;
    setMetaData(metaData);
    updateAdminProgress(companyToViewData, progress, true, true);
    if ((!companyToViewData.transactionData?.adminProgress ||
      companyToViewData.transactionData?.adminProgress === 'not started' ||
      companyToViewData.transactionData?.adminProgress === 'in progress' ||
      companyToViewData.transactionData?.adminProgress === 'work in progress' ||
      companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
      companyToViewData.transactionData?.adminProgress === 'review sandbox')) {
      updateCalculationRequest({
        url: `/calculations/${progress === 'ready for calculation' ? 'submit-calculation' : 'update-calc-object'}`,
        body: { ...newDBData, adminName: currentAdminName, progress },
      });
    }
  }

  function convertAllocatedDataToDBData(progress) {
    // Format Data to DB format
    let commonData = null;
    commonTableData.forEach((row, rowIndex) => {
      let title = '';
      let values = {};
      if (row.common.value) {
        title = row.common.value;
        values = {
          common: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          issuePrice: null,
          participationRights: null,
          prior: null,
          rowPosition: rowIndex,
        };
        if (!commonData) commonData = {};
        commonData = {
          ...commonData,
          [title]: values,
        };
      }
    });

    // TODO  possibly have them be all in the one function in utils with different arguments
    let optionsData = null;
    optionsTableData.forEach((row, rowIndex) => {
      let title = '';
      let values = {};
      if (
        (
          (row.outstanding.value.length !== 0) &&
          (row.strikePrice.value.length !== 0)
        ) ||
        (row.options.value.length > 0)
      ) {
        title = `${row.options.value ? row.options.value : ''} Option @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.options.type ? row.options.type : null,
          preferredClass: row.options.value ? row.options.value : null,
          rowPosition: rowIndex,
        };
        if (!optionsData) optionsData = {};
        optionsData = {
          ...optionsData,
          [title.trim()]: values,
        };
      }
    });
    let warrantsData = null;
    warrantsTableData.forEach((row, rowIndex) => {
      let title = '';
      let values = {};
      if (
        (
          (row.outstanding.value.length !== 0) &&
          (row.strikePrice.value.length !== 0)
        ) ||
        (row.warrants.value.length > 0)
      ) {
        title = `${row.warrants.value ? row.warrants.value : ''} Warrant @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.warrants.type ? row.warrants.type : null,
          preferredClass: row.warrants.value ? row.warrants.value : null,
          rowPosition: rowIndex,
        };
        if (!warrantsData) warrantsData = {};
        warrantsData = {
          ...warrantsData,
          [title.trim()]: values,
        };
      }
    });
    const { available } = remainingOptionsPoolTableData[0];
    function convertOPMTableToDBData(tableData) {
      let valuesToSave;
      tableData.forEach((row) => {
        Object.keys(row).forEach((key) => {
          valuesToSave = {
            ...valuesToSave,
            [row[key].name]: (row[key].value &&
              (row[key].dbFormat ? row[key].dbFormat(row[key].value) :
                row[key].value)) || null,
          };
        });
      });
      return valuesToSave;
    }
    const preferred = convertToDBData(preferredTableData);
    const safeConvertible = convertToDBData(safeConvertibleTableData);

    const newTableData = {
      ...metaData,
      calcRequest: { ...calcRequestData, runWithNoNtm: runWithNoNtm ? 1 : 0 },
      opmInputs: {
        ...convertOPMTableToDBData(opmInputsTableData),
        ...convertOPMTableToDBData(opmInputsTableData2),
        ...ompFixedValues,
      },
      preferred,
      safeConvertible,
      common: commonData,
      option: optionsData,
      warrant: warrantsData,
      remainingOptionsPool: available.value,
    };
    if (changeHasBeenMade && progress !== 'ready for calculation') saveDataToDB(newTableData, progress);
    else if (progress === 'ready for calculation') {
      const preferredErrorCheck = checkForErrorOnPreferredOrSafeTable(preferredTableData);
      const safeErrorCheck = checkForErrorOnPreferredOrSafeTable(safeConvertibleTableData);
      const commonErrorCheck = checkForErrorOnCommonTable(commonTableData);
      const optionsErrorCheck = checkForErrorOnOptionsOrWarrantsTable(optionsTableData, 'options');
      const warrantsErrorCheck = checkForErrorOnOptionsOrWarrantsTable(warrantsTableData, 'warrants');
      const remainingOptionsPoolErrorCheck = remainingOptionsPoolTableData[0].available.value === '';
      if (safeErrorCheck.tableErrors.length) setSafeInputsHaveError(safeErrorCheck.tableErrors);
      if (preferredErrorCheck.tableErrors.length) setPreferredInputsHaveError(preferredErrorCheck.tableErrors);
      setCommonTableHaveError(commonErrorCheck);
      setOptionsTableHaveError(optionsErrorCheck);
      setWarrantsTableHaveError(warrantsErrorCheck);
      setRemainingOptionPoolTableHaveError(remainingOptionsPoolErrorCheck);
      if (
        safeErrorCheck.tableErrors.every((row) => !Object.keys(row).length) &&
        preferredErrorCheck.tableErrors.every((row) => !Object.keys(row).length) &&
        commonErrorCheck.every((row) => Object.keys(row).every((key) => !row[key])) &&
        optionsErrorCheck.every((row) => Object.keys(row).every((key) => !row[key])) &&
        warrantsErrorCheck.every((row) => Object.keys(row).every((key) => !row[key])) &&
        !remainingOptionsPoolErrorCheck &&
        !calcTableHasError
      ) {
        setCalculationIsInProgress(true);
        saveDataToDB(newTableData, progress);
      } else {
        setTabToView(1);
      }
    }
  }

  function approveReport() {
    if (reportApproved || approvingReport) return;

    const reportData = {
      companyId: companyToViewData.accountData.companyId,
      transactionId: companyToViewData.transactionData?.transactionId,
      companyName: companyToViewData.accountData.companyName,
      clientFirstName: companyToViewData.accountData.firstName,
      clientEmail: companyToViewData.accountData.email,
    };

    approveReportRequest({
      url: '/reports/approve-409A-report',
      method: 'post',
      body: reportData,
      onSuccess: () => {
        setReportApproved(true);
        updateAdminProgress(companyToViewData, 'completed', true);
      },
    });
  }

  function saveModelCalcLimits() {
    if (!modelLimitsChanged) return;

    function pick(object, keys) {
      return keys.reduce((acc, key) => {
        acc[key] = object[key];
        return acc;
      }, {});
    }

    const newCalcLimits = pick(modelCalcLimits, ['ntmOptions', 'dlom', 'volatility', 'term']);

    modifyCalcLimitsRequest({
      url: '/calculations/modify-calculation-limits',
      method: 'post',
      body: {
        companyId: companyToViewData.companyData.companyId,
        transactionId: companyToViewData.transactionData.transactionId,
        ...newCalcLimits,
      },
      onFinally: () => setModelLimitsChanged(false),
    });
  }

  const showStatusChip = () => (
    (!companyToViewData.transactionData && companyToViewData.paymentData) ||
    (companyToViewData.transactionData && companyToViewData.transactionData.progress !== 'completed') ||
    companyToViewData.transactionData?.isCalculating ||
    companyToViewData.transactionData?.adminProgress === 'client not started 409a' ||
    companyToViewData.transactionData?.adminProgress === 'client 409a in progress' ||
    companyToViewData.transactionData?.adminProgress === 'work in progress' ||
    companyToViewData.transactionData?.adminProgress === 'calculation failed' ||
    companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
    companyToViewData.transactionData?.adminProgress === 'review sandbox' ||
    companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
    companyToViewData.transactionData?.adminProgress === 'completed'
  );

  const transactionHasSandbox = () => companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
    companyToViewData.transactionData?.adminProgress === 'review sandbox' ||
    companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
    companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'completed';

  const transactionHasReport = () => companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'completed';

  function sendSandboxToClient() {
    if (sandboxEmailHasBeenSent || sandboxEmailIsSending) return;

    const emailData = {
      recipient: companyToViewData.accountData.email,
      templateName: 'sandboxReady',
      templateData: [companyToViewData.accountData.firstName],
      metadata: {},
    };
    sendEmailRequest({
      url: '/emails/send-html-email',
      method: 'post',
      body: emailData,
      onSuccess: () => {
        setSandboxEmailHasBeenSent(true);
        updateAdminProgress(companyToViewData, 'sandbox sent to client');
      },
    });
  }

  function getSandboxDefaults() {
    sandboxDataRequest({
      url: '/sandbox/price-sandbox',
      urlIds: [companyToViewData.transactionData?.companyId, companyToViewData.transactionData?.transactionId],
      onSuccess: (sandboxData) => { if (sandboxData.Message !== 'No Price Sandbox found') setPriceSandboxData(sandboxData); },
    });
  }

  useEffect(() => {
    if (transactionHasSandbox()) getSandboxDefaults();
    // eslint-disable-next-line no-console
    console.log(companyToViewData);
  }, []);

  // changing which PDF page is viewed
  function changePage(offset) {
    if (offset < 0) setPageNumber((prevPageNumber) => prevPageNumber + offset);
    else setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  return (
    <main
      className={`Tabs ${(tabToView === 3 || tabToView === 4) && transactionHasSandbox() ? 'sandbox-width' :
        tabToView === 5 && transactionHasReport() ? 'report-width' : ![0, 5, 6].includes(tabToView) ? 'table-width' : ''}`}
      ref={appWidthRef}
    >
      <div className="tables-pg-top-nav-buttons">
        <button
          className="top-nav-button"
          type="button"
          aria-label="Go back"
          onClick={() => {
            nav('/');
            setTabToView(0);
            setCompanyToViewData(false);
            if (companyToViewData.transactionData?.progress === 'completed' && !viewAccountInfo) {
              convertAllocatedDataToDBData(
                (!companyToViewData.transactionData?.adminProgress ||
                  companyToViewData.transactionData?.adminProgress === 'not started') && changeHasBeenMade ?
                  'in progress' :
                  companyToViewData.transactionData?.adminProgress || 'not started',
              );
            }
          }}
        >
          <ArrowBackIcon />
        </button>
        <button
          className="top-nav-button"
          type="button"
          onClick={() => {
            nav('/');
            setTabToView(0);
            setCompanyToViewData(false);
            if (companyToViewData.transactionData?.progress === 'completed' && !viewAccountInfo) {
              convertAllocatedDataToDBData(
                (!companyToViewData.transactionData?.adminProgress ||
                  companyToViewData.transactionData?.adminProgress === 'not started') && changeHasBeenMade ?
                  'in progress' :
                  companyToViewData.transactionData?.adminProgress,
              );
            }
          }}
        >
          <HomeOutlinedIcon />
          Home
        </button>
        <button
          className={`top-nav-button ${viewAccountInfo ? 'active' : ''}`}
          type="button"
          onClick={() => setViewAccountInfo(true)}
        >
          <StorefrontIcon />
          {companyToViewData.accountData.companyName}
        </button>
        {companyToViewData.transactionData && (
          <button
            className={`top-nav-button ${!viewAccountInfo ? 'active' : ''}`}
            type="button"
            onClick={() => {
              setViewAccountInfo(false);
              if (!tabToView) setTabToView(1);
            }}
          >
            <SubjectIcon />
            {`${companyToViewData.transactionData?.transactionType ? `${companyToViewData.transactionData?.transactionType} valuation` : ''}` +
              `${companyToViewData.transactionData?.transactionDate ?
                ` | ${moment(companyToViewData.transactionData?.transactionDate, 'YYYY-MM-DD').format('MM-DD-YY')}` : ''}`}
          </button>
        )}
        <div className="top-nav-right">
          {companyToViewData?.companyData && companyToViewData.transactionData?.adminProgress !== 'completed' && (
            <Select
              open={priorityDropdownIsOpen}
              className="priority-dropdown"
              onOpen={() => setPriorityDropdownIsOpen(true)}
              onClose={() => setPriorityDropdownIsOpen(false)}
              onChange={(e) => {
                setPriority(e.target.value);
                updatePriority(companyToViewData, e.target.value);
              }}
              value={priority}
              renderValue={() => {
                if (priority === 1) return <NotificationImportantOutlinedIcon />;
                if (priority === 3) return <ArrowDownwardIcon />;
                return 'Priority';
              }}
              IconComponent={ExpandMoreIcon}
              MenuProps={{ disableScrollLock: true, disablePortal: true }}
            >
              <MenuItem className={priority === 1 ? 'active' : ''} value={1}>
                <NotificationImportantOutlinedIcon className="high-icon" />
                High
              </MenuItem>
              <MenuItem className={priority === 2 ? 'active' : ''} value={2}>
                <ArrowDownwardIcon sx={{ visibility: 'hidden' }} />
                Normal
              </MenuItem>
              <MenuItem className={priority === 3 ? 'active' : ''} value={3}>
                <ArrowDownwardIcon className="low-icon" />
                Low
              </MenuItem>
            </Select>
          )}
          {!!showStatusChip() && <StatusChip companyData={companyToViewData} updateAdminProgress={updateAdminProgress} />}
        </div>
      </div>
      {viewAccountInfo ||
        (companyToViewData.paymentData && (!companyToViewData.transactionData || companyToViewData.transactionData?.progress !== 'completed')) ?
        (
          <div className="tables-container">
            <AccountInfo viewAccountInfo={viewAccountInfo} companyToViewData={companyToViewData} />
          </div>
        ) : (
          <>
            <div className="tables-container">
              <div className={`top-tabs-nav${tabToView === 6 ? ' client-inputs' : ''}`}>
                {
                  tabsLabels.map((tabLabel, i) => {
                    return (
                      <button
                        type="button"
                        key={tabLabel}
                        className={`tab-button ${i + 1 === tabToView ? 'active-tab' : ''}`}
                        onClick={() => {
                          setTabToView(i + 1);
                        }}
                      >
                        {tabLabel}
                      </button>
                    );
                  })
                }
              </div>
              <TabsContent
                tabToView={tabToView}
                companyToViewData={companyToViewData.transactionData}
                preferredTableData={preferredTableData}
                setPreferredTableData={setPreferredTableData}
                safeConvertibleTableData={safeConvertibleTableData}
                setSafeConvertibleTableData={setSafeConvertibleTableData}
                commonTableData={commonTableData}
                setCommonTableData={setCommonTableData}
                optionsTableData={optionsTableData}
                setOptionsTableData={setOptionsTableData}
                warrantsTableData={warrantsTableData}
                setWarrantsTableData={setWarrantsTableData}
                remainingOptionsPoolTableData={remainingOptionsPoolTableData}
                setRemainingOptionsPoolTableData={setRemainingOptionsPoolTableData}
                opmInputsTableData={opmInputsTableData}
                setOpmInputsTableData={setOpmInputsTableData}
                opmInputsTableData2={opmInputsTableData2}
                setOpmInputsTableData2={setOpmInputsTableData2}
                sharesTableData={sharesTableData}
                setSharesTableData={setSharesTableData}
                liquidPreferencesTableData={liquidPreferencesTableData}
                setLiquidPreferencesTableData={setLiquidPreferencesTableData}
                setCalcTableHasError={setCalcTableHasError}
                metaData={metaData}
                numberOfPages={numberOfPages}
                setNumberOfPages={setNumberOfPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                recreateReport={recreateReport}
                setRecreateReport={setRecreateReport}
                preferredInputsHaveError={preferredInputsHaveError}
                safeInputsHaveError={safeInputsHaveError}
                commonTableHaveError={commonTableHaveError}
                optionsTableHaveError={optionsTableHaveError}
                warrantsTableHaveError={warrantsTableHaveError}
                remainingOptionPoolTableHaveError={remainingOptionPoolTableHaveError}
                setPreferredInputsHaveError={setPreferredInputsHaveError}
                setSafeInputsHaveError={setSafeInputsHaveError}
                setCommonTableHaveError={setCommonTableHaveError}
                setOptionsTableHaveError={setOptionsTableHaveError}
                setWarrantsTableHaveError={setWarrantsTableHaveError}
                setRemainingOptionPoolTableHaveError={setRemainingOptionPoolTableHaveError}
                setChangeHasBeenMade={setChangeHasBeenMade}
                priceSandboxData={priceSandboxData}
                modelCalcLimits={modelCalcLimits}
                setModelCalcLimits={setModelCalcLimits}
                dataLoading={savingCalcData || savingCalcLimits}
              />
            </div>
            <div className="tables-pg-bottom-nav-buttons">
              <div className="left-buttons">
                {numberOfPages && (
                  <ButtonGroup variant="contained">
                    <Button
                      disableFocusRipple
                      className="download-btn"
                      onClick={() => getReportRequest({
                        requestType: 'get',
                        bucket: process.env.REACT_APP_S3_REPORTS_BUCKET,
                        key: `${metaData.companyId}/${metaData.transactionId}/${metaData.companyName}-409AReport.pdf`,
                        onSuccess: (url) => window.open(url),
                      })}
                    >
                      <DownloadOutlinedIcon />
                    </Button>
                  </ButtonGroup>
                )}
                {numberOfPages && !reportApproved && (
                  <ButtonGroup variant="contained">
                    <Tooltip
                      disableInteractive
                      title="Refresh to update report"
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      arrow
                    >
                      <Button disableFocusRipple onClick={() => setRecreateReport(true)}><RefreshOutlinedIcon /></Button>
                    </Tooltip>
                  </ButtonGroup>
                )}
                {['completed', 'archived'].includes(companyToViewData.transactionData?.adminProgress) && (
                  <ButtonGroup variant="contained">
                    <Tooltip
                      disableInteractive
                      title={`${companyToViewData.transactionData?.adminProgress === 'archived' ? 'Unarchive' : 'Archive'} 409A`}
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      arrow
                    >
                      <Button
                        disableFocusRipple
                        onClick={() => {
                          updateAdminProgress(companyToViewData, companyToViewData.transactionData?.adminProgress === 'archived' ? 'completed' : 'archived');
                        }}
                      >
                        {companyToViewData.transactionData?.adminProgress === 'archived' ? <UnarchiveOutlinedIcon /> : <ArchiveOutlinedIcon />}
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                )}
              </div>
              {numberOfPages && (
                <ButtonGroup variant="contained">
                  <Button
                    disableFocusRipple
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={() => changePage(-1)}
                  >
                    <KeyboardArrowLeftIcon />
                  </Button>
                  <Button
                    disableFocusRipple
                    type="button"
                    disabled={pageNumber >= numberOfPages}
                    onClick={() => changePage(1)}
                  >
                    <KeyboardArrowRightIcon />
                  </Button>
                </ButtonGroup>
              )}
              {numberOfPages && (
                <div className="right-buttons">
                  <Button
                    className="send-btn"
                    onClick={() => setShowSendReportToClientSnackbar(true)}
                    disabled={approvingReport || reportApproved}
                  >
                    {reportApproved ? (
                      <>
                        <CheckCircleIcon />
                        Sent to client
                      </>
                    ) : approvingReport || showSendReportToClientSnackbar ? (
                      <>
                        <LoadingSpinner className="loading-spinner" />
                        Sending to client
                      </>
                    ) : (
                      <>
                        <SendOutlinedIcon />
                        Send to client
                      </>
                    )}
                  </Button>
                </div>
              )}
              {!numberOfPages && (
                <div className="right-buttons">
                  {(tabToView === 1 || tabToView === 2) &&
                    (!companyToViewData.transactionData?.adminProgress ||
                      companyToViewData.transactionData?.adminProgress === 'not started' ||
                      companyToViewData.transactionData?.adminProgress === 'in progress' ||
                      companyToViewData.transactionData?.adminProgress === 'work in progress' ||
                      companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
                      companyToViewData.transactionData?.adminProgress === 'review sandbox') &&
                    (
                      <>
                        <Button
                          onClick={() => {
                            if (companyToViewData.transactionData?.progress === 'completed') {
                              convertAllocatedDataToDBData(
                                (!companyToViewData.transactionData?.adminProgress ||
                                  companyToViewData.transactionData?.adminProgress === 'not started') && changeHasBeenMade ?
                                  'in progress' :
                                  companyToViewData.transactionData?.adminProgress || 'not started',
                              );
                            }
                            saveModelCalcLimits();
                          }}
                          disabled={savingCalcData}
                        >
                          {!savingCalcData ? (
                            <>
                              <SaveOutlinedIcon />
                              Save
                            </>
                          ) : (
                            <>
                              <LoadingSpinner className="loading-spinner" />
                              Saving
                            </>
                          )}
                        </Button>
                        <Tooltip
                          disableInteractive
                          title="Calculate takes up to 45 minutes"
                          PopperProps={{ className: 'bottom-arrow-tooltip' }}
                          placement="top"
                          arrow
                        >
                          <Button
                            className="blue-btn"
                            onClick={(e) => {
                              setCalcBtnMenuIsOpen(true);
                              setCalcBtnMenuAnchorEl(e.currentTarget);
                            }}
                            disabled={savingCalcData}
                          >
                            <CalculateOutlinedIcon />
                            {companyToViewData.transactionData?.hasBeenCalculated ? 'Recalculate' : 'Calculate'}
                          </Button>
                        </Tooltip>
                        <Menu
                          anchorEl={calcBtnMenuAnchorEl}
                          open={calcBtnMenuIsOpen}
                          onClose={() => {
                            setCalcBtnMenuIsOpen(false);
                            setCalcBtnMenuAnchorEl(null);
                          }}
                          className="calculation-button-dropdown"
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          disableScrollLock
                        >
                          <MenuItem
                            className={runWithNoNtm ? 'active' : ''}
                            onClick={() => {
                              setCalcBtnMenuIsOpen(false);
                              setCalcBtnMenuAnchorEl(null);
                              if (companyToViewData.transactionData?.hasBeenCalculated) setShowReCalcConfirmDialog(true);
                              else setShowCalcConfirmDialog(true);
                              setRunWithNoNtm(true);
                            }}
                          >
                            Calculate (for analysis) - no NTM options
                          </MenuItem>
                          <MenuItem
                            className={!runWithNoNtm ? 'active' : ''}
                            onClick={() => {
                              setCalcBtnMenuIsOpen(false);
                              setCalcBtnMenuAnchorEl(null);
                              if (companyToViewData.transactionData?.hasBeenCalculated) setShowReCalcConfirmDialog(true);
                              else setShowCalcConfirmDialog(true);
                              setRunWithNoNtm(false);
                            }}
                          >
                            Calculate - with NTM options
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  {tabToView === 4 && (
                    <Button
                      className="blue-btn"
                      onClick={() => setShowSendSandboxToClientSnackbar(true)}
                      disabled={sandboxEmailIsSending || sandboxEmailHasBeenSent}
                    >
                      {sandboxEmailHasBeenSent ? (
                        <>
                          <CheckCircleIcon />
                          Sent to client
                        </>
                      ) : sandboxEmailIsSending || showSendSandboxToClientSnackbar ? (
                        <>
                          <LoadingSpinner className="loading-spinner" />
                          Sending to client
                        </>
                      ) : (
                        <>
                          <SendOutlinedIcon />
                          Send to client
                        </>
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>
            <Dialog
              open={DBDataIsLoading && !showCalculationSuccess && !showCalculationFailure}
              className="loading-inputs-dialog"
              disableScrollLock
            >
              <div className="loading-wrapper">
                <LoadingSpinner className="custom-loading-spinner" />
              </div>
              Loading Data . . .
            </Dialog>
            <Dialog
              open={showCalcConfirmDialog}
              className="calc-start-dialog"
              disableScrollLock
            >
              <div className="box-header">
                <WarningAmberOutlinedIcon />
                <h4>Please confirm you want to calculate</h4>
              </div>
              <p style={{ fontSize: '18px' }}>A few things to consider before you confirm:</p>
              <p>It can take up to 45 minutes to complete the calculation.</p>
              <p style={{ marginBottom: '0' }}>During calculation:</p>
              <ul>
                <li>the calculation can not be cancelled</li>
                <li>this client&apos;s 409A calculation form will not be editable</li>
                <li>you&apos;ll have the freedom to navigate away and work on other clients</li>
              </ul>
              <div className="box-buttons">
                <Button
                  className="cancel-btn"
                  onClick={() => setShowCalcConfirmDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    convertAllocatedDataToDBData('ready for calculation');
                    setShowCalcConfirmDialog(false);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Dialog>
            <Dialog
              open={showReCalcConfirmDialog}
              className="re-calc-start-dialog"
              disableScrollLock
            >
              <div className="box-header">
                <WarningAmberOutlinedIcon />
                <h4>Recalculate? Please confirm</h4>
              </div>
              <p style={{ fontSize: '18px' }}>Things to consider:</p>
              <ul>
                <li style={{ fontWeight: 600 }}>you will no longer have access to any previous calculations</li>
                <li>it can take up to 45 minutes to complete the recalculation</li>
                <li>once the recalculation starts it can not be cancelled</li>
                <li>this client&apos;s 409A calculation form will not be editable during recalculation</li>
                <li>you&apos;ll have the freedom to navigate away and work on other clients</li>
              </ul>
              <div className="box-buttons">
                <Button
                  className="cancel-btn"
                  onClick={() => setShowReCalcConfirmDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    convertAllocatedDataToDBData('ready for calculation');
                    setShowReCalcConfirmDialog(false);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Dialog>
            <Dialog
              open={calculationIsInProgress}
              className="calc-is-in-progress-dialog"
              disableScrollLock
            >
              <LoadingSpinner className="loading-spinner" />
              <h4>Calculation in progress</h4>
              <div className="dialog-content">
                <p>
                  Calculation started at
                  {' '}
                  {companyToViewData.transactionData?.calculationStartTime ?
                    moment(companyToViewData.transactionData?.calculationStartTime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A') :
                    moment(Date.now(), 'x').format('h:mm A')}
                  {' '}
                  and can take up to 45 minutes to complete.
                </p>
                <p>
                  To keep our data clean and the calculation engine running smoothly the calculation can
                  not be cancelled while it&apos;s in progress.
                </p>
              </div>
              <Button
                className="homepage-btn"
                onClick={() => {
                  nav('/');
                  setTabToView(0);
                  setCompanyToViewData(false);
                }}
              >
                Go to homepage
              </Button>
            </Dialog>
            <Dialog
              open={showCalculationSuccess}
              className="calc-success-dialog"
              disableScrollLock
            >
              <IconButton
                className="close-icon"
                onClick={() => {
                  updateAdminProgress(companyToViewData, 'review sandbox');
                  setShowCalculationSuccess(false);
                  getSandboxDefaults();
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className="box-header calc-success">
                <CheckIcon />
                <h4>Calculation completed successfully</h4>
              </div>
              <p>
                Close this modal to see the updates made to this client&apos;s 409A valuation.
                Updates include late equity in the OPM inputs tab and the Sandbox in the Sandbox tab.
              </p>
            </Dialog>
            <Dialog
              open={showCalculationFailure}
              className="calc-failure-dialog"
              disableScrollLock
            >
              <IconButton
                className="close-icon"
                onClick={() => {
                  updateAdminProgress(companyToViewData, 'in progress');
                  setShowCalculationFailure(false);
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className="box-header calc-failure">
                <WarningAmberOutlinedIcon />
                <h4>Something went wrong</h4>
              </div>
              <p>
                We ran into a problem during calculation. Contact tech support to remedy the problem.
                Once you know what caused the problem, please communicate it to UX to track issues and design solutions.
              </p>
              <p className="calc-error-message">{calcFailureErrorMessage}</p>
            </Dialog>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={showSendSandboxToClientSnackbar}
              onClose={(_, reason) => {
                if (reason === 'timeout') sendSandboxToClient();
                setShowSendSandboxToClientSnackbar(false);
              }}
              TransitionComponent={SlideTransition}
              message={(
                <>
                  <span>Report sent to client</span>
                  <Button onClick={() => setShowSendSandboxToClientSnackbar(false)}>
                    Undo
                  </Button>
                  <Button
                    onClick={() => {
                      sendSandboxToClient();
                      setShowSendSandboxToClientSnackbar(false);
                    }}
                  >
                    Dismiss
                  </Button>
                </>
              )}
              autoHideDuration={10000}
              ClickAwayListenerProps={{ onClickAway: () => null }}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={showSendReportToClientSnackbar}
              onClose={(_, reason) => {
                if (reason === 'timeout') approveReport();
                setShowSendReportToClientSnackbar(false);
              }}
              TransitionComponent={SlideTransition}
              message={(
                <>
                  <span>Report sent to client</span>
                  <Button onClick={() => setShowSendReportToClientSnackbar(false)}>
                    Undo
                  </Button>
                  <Button
                    onClick={() => {
                      approveReport();
                      setShowSendReportToClientSnackbar(false);
                    }}
                  >
                    Dismiss
                  </Button>
                </>
              )}
              autoHideDuration={10000}
              ClickAwayListenerProps={{ onClickAway: () => null }}
            />
          </>
        )}
    </main>
  );
}

Tabs.propTypes = {
  tabToView: PropTypes.number,
  setTabToView: PropTypes.func,
  companyToViewData: PropTypes.object,
  setCompanyToViewData: PropTypes.func,
  updateAdminProgress: PropTypes.func,
  updatePriority: PropTypes.func,
  viewAccountInfo: PropTypes.bool,
  setViewAccountInfo: PropTypes.func,
  currentAdminName: PropTypes.string,
};
