import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import {
  commaEvery3rdChar,
} from '../../../../utils';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';

function formatOptionTitle(val) {
  let optionPrice;
  if (val.includes('Converts')) [optionPrice] = val.split('$')[1].split(' Converts');
  if (val.includes('Exercise')) [optionPrice] = val.split('$')[1].split(' Exercise');
  return val.replace(optionPrice, parseFloat(optionPrice).toString()).replace('@', '\n@');
}

function formatOptionClass(val) {
  const optionPrice = val.split('$')[1];
  return val.replace(optionPrice, parseFloat(optionPrice).toString());
}

function breakLine(val) {
  if (val.includes('Preference')) return 'Preferred\nPreference';
  if (val === 'NTM Options') return 'NTM Options\nExercise';
  if (val.includes('@')) return formatOptionTitle(val);
  if (val.split(' ').length === 2 && val.split(' ')[0].length >= 3 && val.split(' ')[1].length >= 3) return val.replace(' ', '\n');
  return val;
}

function formatBreakpoints(val) {
  return val.replace('to', '-').replace(' Million', 'mil.');
}

export default function LateScenarioOPM({
  lateOPMTableData,
  pagination,
  numberOfPages,
  tableNumberOfPages,
  pageNumber,
  tablePageNumber,
  isLastPage,
  moveTitleToHeader,
  isSingleScenario,
}) {
  function firstColWidth() {
    return '220px';
  }

  function secondColWidth() {
    if (lateOPMTableData.columnData.length <= 4) return '160px';
    return '88px';
  }
  function firstAndSecondColWidth() {
    return `${parseInt(firstColWidth().replace('px', ''), 10) + parseInt(secondColWidth().replace('px', ''), 10)}px`;
  }
  function colWidth() {
    return `${( 1192 -
      (parseInt(firstColWidth().replace('px', ''), 10) + parseInt(secondColWidth().replace('px', ''), 10))) / lateOPMTableData.columnData.length}px`;
  }
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative' }}
    >
      <TablePageHeader
        pageName={`${isSingleScenario ? 'Option Pricing Model (OPM)' : 'Late Scenario OPM'} ${tableNumberOfPages > 1 && numberOfPages > 1 ?
          `(Table ${pageNumber} ${tablePageNumber > 1 ? 'continued...' : ''})` :
          numberOfPages > 1 ? `(page ${pageNumber} of ${numberOfPages})` : ''}`}
        moveTitleToHeader={moveTitleToHeader}
      />
      <View
        style={{
          marginTop: '22px',
          marginLeft: '80px',
          display: 'flex',
          flexDirection: 'column',
          width: '1064px',
          borderBottom: '1px',
          borderColor: '#CCCCCC',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '28px',
            backgroundColor: '#F0F0F3',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '12px',
              color: '#49454F',
              width: '164px',
              paddingLeft: '16px',
              letterSpacing: '0.4px',
            }}
          >
            OPM Inputs:
          </Text>
          {Object.keys(lateOPMTableData.lateOPMData).map((keyName, keyIndex) => {
            return (
              <Text
                key={keyName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '180px',
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {`${keyName}: ${Object.values(lateOPMTableData.lateOPMData)[keyIndex]}`}
              </Text>
            );
          })}
        </View>
      </View>
      <View
        style={{
          marginTop: '22px',
          marginLeft: '16px',
          display: 'flex',
          flexDirection: 'column',
          width: '1192px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '96px',
            backgroundColor: '#F0F0F3',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '12px',
              color: '#49454F',
              width: firstAndSecondColWidth(),
              paddingLeft: '16px',
              paddingTop: '73px',
              letterSpacing: '0.5px',
            }}
          />
          {lateOPMTableData.columnData.map((tableData) => {
            return (
              <View
                key={tableData.dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  letterSpacing: '0.5px',
                  width: colWidth(),
                  paddingLeft: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '12px',
                  paddingBottom: '6px',
                  justifyContent: `${tableData.breakpoint ? 'space-between' : 'flex-end'}`,
                }}
              >
                <Text style={{ lineHeight: '0.55mm' }}>
                  {breakLine(tableData.dataName)}
                </Text>
                {tableData.breakpoint && (
                  <Text>
                    {formatBreakpoints(tableData.breakpoint)}
                  </Text>
                )}
              </View>
            );
          })}
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '38px',
            backgroundColor: '#F0F0F3',
          }}
        >
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              color: '#49454F',
              width: firstAndSecondColWidth(),
              paddingLeft: '16px',
              paddingTop: '10px',
              letterSpacing: '0.4px',
            }}
          >
            Breakpoint value
          </Text>
          {
            lateOPMTableData.columnData.map((tableData) => {
              return (
                <Text
                  key={tableData.dataName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#49454F',
                    width: colWidth(),
                    paddingLeft: '16px',
                    paddingTop: '10px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {`$${commaEvery3rdChar(lateOPMTableData.lateBreakpointValues[tableData.dataName].toFixed(0).toString())}`}
                </Text>
              );
            })
          }
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '28px',
            backgroundColor: '#FAFAFD',
          }}
        >
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              color: '#49454F',
              width: firstAndSecondColWidth(),
              paddingLeft: '16px',
              letterSpacing: '0.4px',
            }}
          >
            Value of call option
          </Text>
          {
            lateOPMTableData.columnData.map((tableData) => {
              return (
                <Text
                  key={tableData.dataName}
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    color: '#49454F',
                    width: colWidth(),
                    paddingLeft: '16px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {`$${commaEvery3rdChar(lateOPMTableData.lateValueOfCallOption[tableData.dataName].toFixed(0).toString())}`}
                </Text>
              );
            })
          }
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '28px',
            backgroundColor: '#F0F0F3',
            borderBottom: '2px',
            borderBottomColor: '#CCCCCC',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '12px',
              color: '#49454F',
              width: firstAndSecondColWidth(),
              paddingLeft: '16px',
              letterSpacing: '0.4px',
            }}
          >
            Incremental option value
          </Text>
          {
            lateOPMTableData.columnData.map((tableData) => {
              return (
                <Text
                  key={tableData.dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '12px',
                    color: '#49454F',
                    width: colWidth(),
                    paddingLeft: '16px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {`$${commaEvery3rdChar(lateOPMTableData.lateIncrementalOptionValue[tableData.dataName].toFixed(0).toString())}`}
                </Text>
              );
            })
          }
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '36px',
            backgroundColor: '#F0F0F3',
          }}
        >
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '12px',
              color: '#49454F',
              width: firstColWidth(),
              paddingTop: '18px',
              paddingLeft: '16px',
              letterSpacing: '0.4px',
            }}
          >
            Allocation
          </Text>
          <Text
            style={{
              fontFamily: 'RobotoMed',
              fontSize: '12px',
              color: '#49454F',
              width: secondColWidth(),
              paddingTop: '18px',
              paddingLeft: '16px',
              letterSpacing: '0.4px',
            }}
          >
            Aggregate
          </Text>
        </View>
        {lateOPMTableData.lateAggregateValues.map((cellData, cellDataIndex) => {
          return (
            <View
              key={cellData.Allocation}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '28px',
                backgroundColor: `${cellDataIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: firstColWidth(),
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {cellData.Allocation.includes('@') ? formatOptionClass(cellData.Allocation) : cellData.Allocation}
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: secondColWidth(),
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {`$${commaEvery3rdChar(cellData.Aggregate.toFixed(0).toString())}`}
              </Text>
              {
                lateOPMTableData.columnData.map((tableData) => {
                  return (
                    <Text
                      key={tableData.dataName}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        color: '#49454F',
                        width: colWidth(),
                        paddingLeft: '16px',
                        letterSpacing: '0.4px',
                      }}
                    >
                      {cellData[tableData.dataName] === 0 ? '—' : `$${commaEvery3rdChar(cellData[tableData.dataName].toFixed(0).toString())}`}
                    </Text>
                  );
                })
              }
            </View>
          );
        })}
        {isLastPage && (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '28px',
              backgroundColor: '#F0F0F3',
              borderTop: '1px',
              borderBottom: '2px',
              borderColor: '#CCCCCC',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: firstColWidth(),
                paddingLeft: '16px',
                letterSpacing: '0.4px',
              }}
            >
              Total
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: secondColWidth(),
                paddingLeft: '16px',
                letterSpacing: '0.4px',
              }}
            >
              {`$${commaEvery3rdChar(lateOPMTableData.lateAggregateTotal.toFixed(0).toString())}`}
            </Text>
          </View>
        )}
      </View>
      <BottomPagination
        pageNumberLeft={pagination[0]}
        pageNumberRight={pagination[1]}
      />
    </Page>
  );
}


LateScenarioOPM.propTypes = {
  lateOPMTableData: PropTypes.object,
  pagination: PropTypes.array,
  numberOfPages: PropTypes.number,
  tableNumberOfPages: PropTypes.number,
  pageNumber: PropTypes.number,
  tablePageNumber: PropTypes.number,
  isLastPage: PropTypes.bool,
  moveTitleToHeader: PropTypes.bool,
  isSingleScenario: PropTypes.bool,
};
