import React, { useContext } from 'react';

import RouterProvider from './Routes';
import NavBar from './components/Navigation/NavBar';
import AlertDisplay from './components/AlertDisplay';

import InactivityTimeout from './components/InactivityTimeout';

import { AuthContext } from './contexts';

import useLogRocket from './hooks/useLogRocket';

import './App.scss';

export default function App() {
  const isAuthenticated = useContext(AuthContext);

  useLogRocket();

  if (isAuthenticated) {
    return (
      <main className="App">
        <AlertDisplay />
        <InactivityTimeout />
        <NavBar />
        <RouterProvider />
      </main>
    );
  }
}
